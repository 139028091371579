import { FormattedMessage } from "react-intl";

import { useQuery } from "util/graphql";
import LoadingIndicator from "common/core/loading_indicator";
import { usePermissions } from "common/core/current_user_role";

import AdminDashboardLink from "./admin_dashboard_link";
import AdminDashboardQuery from "./index_query.graphql";

function AdminDash() {
  const { hasPermissionFor } = usePermissions();

  const { data, loading, networkStatus, error } = useQuery(AdminDashboardQuery);
  if (loading) {
    return <LoadingIndicator />;
  }

  if (!data?.viewer) {
    throw new Error(
      `Keystone dashboard load - viewer is undefined. NetworkStatus: ${networkStatus}, Error: ${error?.toString()}`,
    );
  }
  const { viewer } = data;

  return (
    <ul className="DashboardIndex centered">
      {hasPermissionFor("canViewNotaries") && (
        <AdminDashboardLink
          to="/notary"
          iconName="connect"
          text={
            <FormattedMessage id="1a47065f-2535-4dbf-a7ac-58f9c310d385" defaultMessage="Notaries" />
          }
          subText={
            <FormattedMessage
              id="558990a0-2409-4b34-a300-0c31ec12843d"
              defaultMessage="{number} Online"
              values={{ number: viewer.notaries.totalCount }}
            />
          }
          automationId="notaries-dashboard-container"
        />
      )}

      {hasPermissionFor("canViewCompanies") && (
        <AdminDashboardLink
          to="/companies/page/1"
          iconName="notaries"
          text="Organizations"
          automationId="companies-dashboard-container"
        />
      )}

      {hasPermissionFor("canViewAdmins") && (
        <AdminDashboardLink
          to="/admin"
          iconName="employees-filled"
          text="Admins"
          automationId="admins-dashboard-container"
        />
      )}

      {hasPermissionFor("canViewAnalytics") && (
        <AdminDashboardLink
          to="/analytics"
          iconName="analytics"
          text="Analytics"
          automationId="analytics-dashboard-container"
        />
      )}

      {hasPermissionFor("canViewMeetingQueue") && (
        <AdminDashboardLink
          to="/meeting-requests"
          iconName="witness-line"
          text={
            <FormattedMessage
              id="edc08097-cc96-49b5-bc2f-85cb76be0133"
              defaultMessage="Queue Stats"
            />
          }
        />
      )}

      {hasPermissionFor("tierDetails") && (
        <AdminDashboardLink
          to="/finance"
          iconName="payments"
          text="Finance Tools"
          automationId="finance-tools-dashboard-container"
        />
      )}

      <AdminDashboardLink
        to="/tools"
        iconName="questionnaire"
        text="Tools"
        automationId="tools-dashboard-container"
      />
      {viewer.keystoneTasks.length > 0 && (
        <AdminDashboardLink
          to="/tasks"
          iconName="touch"
          text={
            <FormattedMessage id="1a47065f-2535-4dbf-a7ac-58f9c310d385" defaultMessage="Tasks" />
          }
          automationId="tasks-container"
        />
      )}

      {hasPermissionFor("viewSettingsUpdateForm") && (
        <AdminDashboardLink
          to="/system-settings"
          iconName="settings-filled"
          text="System Settings"
          automationId="system-settings-container"
        />
      )}

      {hasPermissionFor("viewOrgFlagsUpdateForm") && (
        <AdminDashboardLink
          to="/organization-flags"
          iconName="state"
          text="Organization Flags"
          automationId="organization-flags-dashboard-container"
        />
      )}

      {hasPermissionFor("viewProofReasonCodes") && (
        <AdminDashboardLink
          to="/reason-codes"
          iconName="user-shield-fill"
          text="Risk Reason Codes"
          automationId="reason-codes-container"
        />
      )}
    </ul>
  );
}

export default AdminDash;
