import { memo, useState } from "react";
import { FormattedMessage } from "react-intl";

import { useRawMutation } from "util/graphql/mutation";
import ActionButton from "common/core/action_button";
import { Card, CardHeading } from "common/core/card";
import { Badge } from "common/core/badge";
import AlertMessage from "common/core/alert_message";

import CloneToFreshBundleHackMutation from "./clone_to_fresh_bundle_hack_mutation.graphql";
import Styles from "./transaction.module.scss";

type Props = {
  documentBundleId: string;
};

function TransactionHacks({ documentBundleId }: Props) {
  const [cloneToFreshBundleMutateFn, cloneInfo] = useRawMutation(CloneToFreshBundleHackMutation, {
    fetchPolicy: "no-cache",
  });
  const variables = { input: { documentBundleId } };
  const [isAlertVisible, setIsAlertVisible] = useState(true);

  const handleAlertClose = () => {
    setIsAlertVisible(false);
  };

  return (
    <Card
      header={
        <CardHeading>
          <FormattedMessage id="8862cb2b-b5fd-4b7d-80a1-46febe7de3b9" defaultMessage="Hacks" />
          <Badge kind="warning" className={Styles.badge}>
            <FormattedMessage
              id="55bcb06b-8efb-4bff-906a-fb18251bde18"
              defaultMessage="Development only"
            />
          </Badge>
        </CardHeading>
      }
    >
      <div className={Styles.container}>
        <div className={Styles.item}>
          <ActionButton
            disabled={cloneInfo.loading}
            onClick={() => cloneToFreshBundleMutateFn({ variables })}
          >
            <FormattedMessage
              id="a3cb23a5-f02c-45bf-99f5-d497ab2c78ab"
              defaultMessage="Clone a fresh document bundle from this one"
            />
          </ActionButton>
          <div className={Styles.text}>
            <FormattedMessage
              id="ad1f9e3c-0fe8-4d83-af99-9768d365909d"
              defaultMessage="Creates a copy of the document bundle with current signer(s)"
            />
          </div>
          {cloneInfo.called && !cloneInfo.loading && isAlertVisible && (
            <div className={Styles.text}>
              <AlertMessage
                className={Styles.banner}
                kind="success"
                onClose={() => handleAlertClose()}
              >
                <FormattedMessage
                  id="b80ad2d6-eef3-422d-8073-60a839de2aac"
                  defaultMessage="Done! New id is {id}."
                  values={{ id: cloneInfo.data!.cloneToFreshBundle!.documentBundle.id }}
                />
              </AlertMessage>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
}

export default memo(TransactionHacks);
