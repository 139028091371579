import {
  useState,
  useRef,
  forwardRef,
  type ReactNode,
  type Ref,
  type KeyboardEvent,
  type ComponentProps,
} from "react";
import classnames from "classnames";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import { useEscapeKey, useDropdownNavigation, handleButtonKeyDown } from "util/keyboard_navigation";
import { useId } from "util/html";
import { SearchField } from "common/core/search_field";
import ClickOutside from "common/core/click_outside";
import ActionButton from "common/core/action_button";
import Icon from "common/core/icon";
import { MenuContainer, PopoutState } from "common/core/popout_menu/common";
import type { Placement } from "common/core/self_manage_placement";
import SROnly from "common/core/screen_reader";

import Styles from "./index.module.scss";
import { Substyle } from "../typography";
import { Badge } from "../badge";

const MESSAGES = defineMessages({
  clearBtnLabel: { id: "45cded4d-e149-46d2-ac36-7a62a46e71ae", defaultMessage: "Clear selection" },
});

export const useFilterOptions = <
  T extends {
    optionLabel: ReactNode;
    optionDescription?: ReactNode;
    [key: string]: ReactNode | undefined;
  },
>(
  dropdownOptions: T[],
  customSearchFields?: string[],
) => {
  const [searchQuery, setSearchQuery] = useState("");

  const search = (searchQuery: string, optionText: string) => {
    return optionText.toLowerCase().includes(searchQuery);
  };

  const filteredOptions = dropdownOptions.filter((option) => {
    const splitSearchTerms = searchQuery.toLowerCase().split(" ");
    return splitSearchTerms.every((searchTerm) => {
      if (customSearchFields) {
        return customSearchFields.some((field) => {
          const fieldValue = option[field];
          return fieldValue && search(searchTerm, String(fieldValue));
        });
      }
      return (
        search(searchTerm, String(option.optionLabel!)) ||
        (option.optionDescription && search(searchTerm, String(option.optionDescription)))
      );
    });
  });

  return {
    searchQuery,
    setSearchQuery,
    filteredOptions,
  };
};

type ComboboxDropdownMenuOptionProps = {
  /** Whether or not the option is selected */
  selected: boolean;
  onChange: () => void;
  disabled?: boolean;
  "data-automation-id"?: string;
  "data-pendo-id"?: string;
  /** Displays an icon for the option */
  optionIcon?: string;
  /** Displays a description below the label for the option */
  optionDescription?: ReactNode;
  /** The label of the option */
  optionLabel: ReactNode;
  /** Displays badge for the option */
  optionBadge?: {
    kind: ComponentProps<typeof Badge>["kind"];
    text: ReactNode;
  };
};

export function ComboboxDropdownMenuOption({
  selected,
  onChange,
  disabled,
  "data-automation-id": dataAutomationId,
  "data-pendo-id": dataPendoId,
  optionLabel,
  optionIcon,
  optionBadge,
  optionDescription,
}: ComboboxDropdownMenuOptionProps) {
  const cx = classnames(Styles.option, disabled && Styles.disabled, selected && Styles.selected);

  const handleKeydown = (e: KeyboardEvent) => {
    handleButtonKeyDown(
      e,
      () => {
        e.preventDefault();
        onChange();
      },
      false,
    );
  };

  return (
    <li
      tabIndex={-1}
      role="option"
      aria-selected={selected}
      onClick={disabled ? undefined : onChange}
      className={cx}
      data-automation-id={dataAutomationId}
      data-pendo-id={dataPendoId}
      data-keyboard-navigation="true"
      onKeyDown={handleKeydown}
    >
      {optionIcon && <Icon name={optionIcon} className={Styles.optionIcon} />}
      <span>
        <span className={Styles.optionLabel}>
          {optionLabel}
          {optionBadge && <Badge kind={optionBadge.kind}>{optionBadge.text}</Badge>}
        </span>
        {optionDescription && <span className={Styles.optionDescription}>{optionDescription}</span>}
      </span>
    </li>
  );
}

type ComboboxSearchInputProps = {
  /** Determines whether or not to allow for option filtering  */
  withSearch?: {
    searchQuery: string;
    setSearchQuery: (value: string) => void;
    /** The accessible label for the search input */
    searchInputLabel: string;
    /** The placeholder for the search input */
    searchInputPlaceholder?: string;
    /** The length of the filtered results */
    filteredResultLength?: number;
  };
};

type ComboboxDropdownMenuProps = {
  listOptionsId: string;
  placement?: Placement;
  allowMultipleSelection?: boolean;
  children: ReactNode;
  menuClassName?: string;
} & ComboboxSearchInputProps;
function ComboboxDropdownMenu(
  {
    listOptionsId,
    placement,
    allowMultipleSelection,
    withSearch,
    children,
    menuClassName,
  }: ComboboxDropdownMenuProps,
  ref: Ref<HTMLDivElement>,
) {
  return (
    <MenuContainer
      id={listOptionsId}
      placement={placement}
      ref={ref}
      menuClassName={classnames(menuClassName, Styles.optionMenuContainer)}
    >
      {withSearch && (
        <div className={Styles.searchContainer}>
          <SearchField
            onChange={({ value }) => {
              withSearch.setSearchQuery(value);
            }}
            value={withSearch.searchQuery}
            placeholder={withSearch.searchInputPlaceholder}
            aria-label={withSearch.searchInputLabel}
            searchOnClear
            size="large"
            includeInKeyboardNav
          />
        </div>
      )}
      <ul
        className={Styles.optionList}
        role="listbox"
        aria-multiselectable={allowMultipleSelection && true}
        tabIndex={0}
      >
        {children}
      </ul>
      <div aria-live="assertive">
        {withSearch?.filteredResultLength === 0 && (
          <Substyle
            textStyle="subtitleSmall"
            size="defaultSize"
            textAlign="center"
            className={Styles.noResults}
          >
            <FormattedMessage
              id="c4c3b8b0-9e2b-4b2b-9e2c-3c5e7a3e8b6e"
              defaultMessage="No results found"
            />
          </Substyle>
        )}
      </div>
      <SROnly>
        <span aria-live="assertive">
          {withSearch?.filteredResultLength && (
            <FormattedMessage
              id="5b026725-b8ee-4468-853c-54c4d959aa20"
              defaultMessage="{number} {number, plural, one {result} other {results}}"
              values={{
                number: withSearch.filteredResultLength,
              }}
            />
          )}
        </span>
      </SROnly>
    </MenuContainer>
  );
}

const ComboboxDropdownMenuWithRef = forwardRef(ComboboxDropdownMenu);

type ComboboxDropdownProps = {
  /** className for the component container */
  className?: string;
  /** The label displayed in the "dropdown" */
  label: ReactNode;
  /** Show a different label when an option has been selected */
  selectedLabel?: ReactNode;
  /** What happens when you clear the dropdown selection */
  onClearSelection?: () => void;
  hasSelection: boolean;
  children: (controls: { closeMenu: () => void }) => ReactNode;
  /** Changes the visual style of the options to be checkboxes */
  allowMultipleSelection?: boolean;
  /** Places the menu */
  placement?: Placement;
  menuClassName?: string;
  noBorder?: boolean;
  noBackground?: boolean;
  "data-automation-id"?: string;
} & ComboboxSearchInputProps;

/**
 * The Combobox Dropdown component is our custom "dropdown" most commonly used for
 * table filters. It consists of a button that when clicked, reveals a popover "menu" that
 * contains a list of options. Note: semantics-wise, this is not a true menu so we are not
 * using `role="menu"` or `role="menuitem"`.
 *
 * It is intended to be used for action-oriented use cases such as table filtering, and
 * should not be used for forms or table row actions (use `PopoutMenu` for that).
 */
export function ComboboxDropdown({
  className,
  label,
  onClearSelection,
  selectedLabel,
  hasSelection,
  allowMultipleSelection = false,
  placement = "bottomLeft",
  children,
  withSearch,
  menuClassName,
  noBorder = false,
  noBackground = false,
  "data-automation-id": dataAutomationId,
}: ComboboxDropdownProps) {
  const intl = useIntl();
  const listOptionsId = useId();
  const listOptionsRef = useRef<HTMLDivElement | null>(null);
  const triggerButtonRef = useRef<HTMLButtonElement | null>(null);
  const [popoutState, setPopoutState] = useState<PopoutState>(PopoutState.CLOSED);
  const showListOptions =
    popoutState === PopoutState.OPEN || popoutState === PopoutState.OPENED_WITH_KEYBOARD;

  const closeMenu = () => {
    setPopoutState(PopoutState.CLOSED);
  };
  useEscapeKey(closeMenu, showListOptions);

  const toggleMenu = () => {
    showListOptions ? setPopoutState(PopoutState.CLOSED) : setPopoutState(PopoutState.OPEN);
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    handleButtonKeyDown(
      e,
      () => {
        // default behavior is these keys call onClick, prevents calling twice
        e.preventDefault();
        setPopoutState(PopoutState.OPENED_WITH_KEYBOARD);
        if (showListOptions) {
          closeMenu();
        }
      },
      false,
    );
    if (showListOptions) {
      if (e.key === "ArrowDown" || e.key === "Tab") {
        listOptionsRef.current?.focus();
      }
    }
  };

  useDropdownNavigation({
    popoutState,
    ref: listOptionsRef,
    itemSelector: "[data-keyboard-navigation='true']",
  });

  return (
    <ClickOutside
      onClickOutside={() => {
        closeMenu();
      }}
    >
      <div
        className={classnames(Styles.container, className)}
        data-automation-id={dataAutomationId}
      >
        <div className={Styles.triggerContainer}>
          <ActionButton
            ref={triggerButtonRef}
            className={classnames(
              Styles.trigger,
              noBorder && Styles.triggerNoBorder,
              noBackground && Styles.triggerNoBackground,
            )}
            color={selectedLabel === undefined || !selectedLabel ? "subtle" : "dark"}
            aria-expanded={showListOptions}
            aria-haspopup="listbox" // note: in VO, this overrides the aria-expanded (seems like a bug in VO)
            aria-controls={listOptionsId}
            onKeyDown={handleKeyDown}
            onClick={toggleMenu}
          >
            {hasSelection ? selectedLabel || label : label}
          </ActionButton>
          {hasSelection && onClearSelection && (
            <ActionButton
              aria-label={intl.formatMessage(MESSAGES.clearBtnLabel)}
              color="subtle"
              className={Styles.clearBtn}
              onClick={onClearSelection}
            >
              <Icon name="x-filled" />
            </ActionButton>
          )}
        </div>
        {showListOptions && (
          <ComboboxDropdownMenuWithRef
            allowMultipleSelection={allowMultipleSelection}
            listOptionsId={listOptionsId}
            placement={placement}
            withSearch={withSearch}
            ref={listOptionsRef}
            menuClassName={menuClassName}
          >
            {children({ closeMenu })}
          </ComboboxDropdownMenuWithRef>
        )}
      </div>
    </ClickOutside>
  );
}
