import { useCallback, useMemo } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import {
  DashboardActionBar,
  DashboardBody,
  DashboardContent,
  DashboardFilters,
  DashboardHeader,
  DashboardTitle,
} from "common/dashboard";
import Table from "common/core/table";
import TablePagination, { usePagination } from "common/core/table/pagination";
import { maxPageNumber } from "util/pagination";
import TableSearch from "common/core/table/search";
import { useDebouncedQuery, useFilter } from "common/dashboard/filter";
import { useToggleSetWithCallback } from "common/dashboard/filter_dropdown/common";
import { useQuery } from "util/graphql";
import { useProofDefend } from "util/feature_detection";

import IdentityCRMDashboardQuery, {
  type IdentityCRMDashboard_organization_Organization as Organization,
  type IdentityCRMDashboard_organization_Organization_organizationCustomers_edges_node as OrganizationCustomer,
} from "./index.query.graphql";
import {
  identityCRMDashboardDeserializer,
  identityCRMDashboardSerializer,
  RiskLevelsFilter,
} from "./filter";
import {
  EmailColumn,
  LastActiveColumn,
  RecipientColumn,
  RiskColumn,
  TotalTransactionsColumn,
} from "./columns";

const PAGE_SIZE = 50;

const MESSAGES = defineMessages({
  searchPlacholder: {
    id: "91e27d8e-91ba-492a-a162-bf274e05d65c",
    defaultMessage: "Search by name or email",
  },
  searchLabel: {
    id: "623b259d-f416-41f2-8fd3-076f196f3bc5",
    defaultMessage: "Search for an identity",
  },
});

export function IdentityCRMDashboard({ organizationId }: { organizationId: string }) {
  const intl = useIntl();
  const navigate = useNavigate();
  const { handleChange, deserializedArgs } = useFilter(
    identityCRMDashboardDeserializer,
    identityCRMDashboardSerializer,
  );
  const { page: pageIndex, query, riskLevels } = deserializedArgs;
  const { textFilterValue, handleTextFilterChange } = useDebouncedQuery(handleChange, query, true);

  const setPageIndex = useCallback(
    (page: number) => {
      handleChange({ page });
    },
    [handleChange],
  );

  const handleRowClick = useCallback(
    (organizationCustomer: OrganizationCustomer) => {
      navigate(organizationCustomer.userId);
    },
    [navigate],
  );

  const rowInteraction = useMemo(
    () => ({
      onClick: handleRowClick,
    }),
    [handleRowClick],
  );

  const { clearSelection: clearRiskLevelsSelection, toggleSelection: toggleRiskLevelsSelection } =
    useToggleSetWithCallback(riskLevels, handleChange, "riskLevels");

  const variables = {
    first: PAGE_SIZE,
    offset: PAGE_SIZE * pageIndex,
    query,
    riskLevels: riskLevels.size ? Array.from(riskLevels.values()) : null,
    organizationId,
  };

  const { data, loading, previousData } = useQuery(IdentityCRMDashboardQuery, {
    variables,
  });

  // Obtain the organization from the query data. Previous data is used to prevent the table from
  // being wiped clean during loading new data
  const organization = (
    data ? data.organization! : loading && previousData ? previousData.organization! : null
  ) as Organization | null;

  const items = useMemo(
    () => organization?.organizationCustomers?.edges.map(({ node }) => node) ?? [],
    [organization],
  );

  const totalCount = organization?.organizationCustomers?.totalCount ?? 0;
  const pageCount = maxPageNumber(totalCount, PAGE_SIZE);

  const pagination = usePagination({
    disabled: loading,
    pageIndex,
    pageCount,
    pageSize: PAGE_SIZE,
    items,
    onPageChange: setPageIndex,
  });
  const proofDefend = useProofDefend(organization);

  const columns = useMemo(
    () => [
      RecipientColumn,
      EmailColumn,
      LastActiveColumn,
      ...(proofDefend ? [RiskColumn] : []),
      TotalTransactionsColumn,
    ],
    [proofDefend],
  );

  return (
    <DashboardContent>
      <DashboardTitle
        title={
          <FormattedMessage id="70f36c43-6bef-46ad-add3-17f5dc37fd3a" defaultMessage="Identities" />
        }
        description={
          <FormattedMessage
            id="e42347d1-6741-4fc4-bc3d-ab215d881b1a"
            defaultMessage="View all user identities created via your transactions. Monitor risk score."
          />
        }
      />

      <DashboardHeader>
        <DashboardFilters>
          <TableSearch
            value={textFilterValue}
            placeholder={intl.formatMessage(MESSAGES.searchPlacholder)}
            aria-label={intl.formatMessage(MESSAGES.searchLabel)}
            onChange={handleTextFilterChange}
          />
          <RiskLevelsFilter
            onClearSelection={clearRiskLevelsSelection}
            selectedRiskLevels={riskLevels}
            toggleSelection={toggleRiskLevelsSelection}
            disabled={loading}
          />
        </DashboardFilters>
      </DashboardHeader>

      <DashboardActionBar
        pagination={<TablePagination {...pagination} totalCount={totalCount} />}
        hideExportButton
      />

      <DashboardBody>
        <Table
          columns={columns}
          data={items}
          totalItems={totalCount}
          loading={loading}
          rowInteraction={rowInteraction}
        />
      </DashboardBody>
    </DashboardContent>
  );
}
