import "./index.scss";

import { Component } from "react";
import { defineMessages, injectIntl } from "react-intl";
import PropTypes from "prop-types";
// eslint-disable-next-line no-restricted-imports
import get from "lodash/get";

import { deprecatedSubForm } from "common/form/enhancers/sub_form";
import FormGroup from "common/form/group";
import { DeprecatedFormRow } from "common/form/elements/row";
import { DeprecatedTextField } from "common/form/fields/text";
import { DeprecatedStateField } from "common/form/fields/state";
import { DeprecatedCountryField } from "common/form/fields/country";
import FormGroupErrors from "common/form/group_errors";
import { DeprecatedMultipartRow } from "common/form/inputs/multipart/row";
import { composeValidators } from "util/form";
import { validatePostalCodeRelativeToCountry, validatePresence, validateIf } from "validators/form";
import { zipMaxLength } from "util/address";
import { COUNTRIES_WITHOUT_POSTCODES } from "constants/form";
import GoogleLookupWrapper from "common/core/form/address/google_lookup_wrapper";

import { DeprecatedAddressLookup } from "./address_lookup";

const messages = defineMessages({
  zipCode: {
    id: "6da9d446-668b-4310-a5ec-772b72b7c341",
    defaultMessage: "ZIP Code",
  },
  zipOrPostalCode: {
    id: "808b237e-21b0-4e8c-999e-ccf82ebd60ae",
    defaultMessage: "ZIP or postal code",
  },
  city: {
    id: "3f37f4a8-1608-4ca8-893c-5d470a6f6684",
    defaultMessage: "City",
  },
  state: {
    id: "d9906037-25a7-4c71-9a4a-e9f18b2495e0",
    defaultMessage: "State",
  },
  addressLine1: {
    id: "99ebf0f7-76a9-40ca-a988-818a353e6494",
    defaultMessage: "Street address",
  },
  unitOrAptNumber: {
    id: "8fdcfc6e-fd04-448a-9e63-32c1d05e02b6",
    defaultMessage: "Unit/Apt #",
  },
  ariaZipCode: {
    id: "5c723986-4c86-4006-bdf9-73af0ee321e6",
    defaultMessage: "ZIP Code",
  },
  ariaZipOrPostalCode: {
    id: "51ce285d-1e0d-4dd1-926c-f46102d97eef",
    defaultMessage: "ZIP or postal code",
  },
  ariaCity: {
    id: "f48be7a7-d79a-4eb3-8338-8d26b8d192bd",
    defaultMessage: "City",
  },
  ariaState: {
    id: "c622c920-9435-4675-b824-30fcac4be330",
    defaultMessage: "State",
  },
  ariaCountry: {
    id: "4cfb05f3-3ec0-47b1-b548-51bc152c6e89",
    defaultMessage: "Country",
  },
  ariaAddressLine1: {
    id: "cb60f76b-a8bd-4a94-836a-f0b3f598eda6",
    defaultMessage: "Street address line 1",
  },
  ariaUnitOrAptNumber: {
    id: "89c801aa-a80a-4c17-b2c6-d6bda9df4dea",
    defaultMessage: "Unit or apartment number",
  },
});

/**
 * An enumeration of possible fields that we could display in the
 * address sub form. We can pass these to `hiddenFields` through props
 * in order to hide certain fields.
 */
const ADDRESS_FIELDS = {
  LINE_1: "line1",
  LINE_2: "line2",
  CITY: "city",
  STATE: "state",
  COUNTRY: "country",
  POSTAL: "postal",
  LOOKUP: "lookup",
};

function isStateRequired(country) {
  return /US|CA/.test(country);
}

function isPostalRequired(country) {
  return country && !COUNTRIES_WITHOUT_POSTCODES.includes(country);
}

export const validationRules = (values, _props, prefix = "") =>
  composeValidators(
    validatePresence({ field: `${prefix}line1`, label: "Street address" }),
    validatePresence({ field: `${prefix}city`, label: "City" }),
    validatePresence({ field: `${prefix}country`, label: "Country" }),

    validatePostalCodeRelativeToCountry({
      field: `${prefix}postal`,
      missingLabel: get(values, `${prefix}country`) === "US" ? "ZIP" : undefined,
      prefix,
    }),

    validateIf({
      field: `${prefix}country`,
      condition: (val) => isStateRequired(val),
      validation: validatePresence({
        field: `${prefix}state`,
        label: get(values, `${prefix}country`) === "US" ? "State" : "State/Province",
      }),
    }),
  );

class AddressSubForm extends Component {
  componentDidMount() {
    const { requiredCountryCode, initialize } = this.props;
    if (requiredCountryCode) {
      initialize({ country: requiredCountryCode });
    }
  }

  handleAddressSelect = (place) => {
    this.setFields(place);
    this.props.onAddressSelect(place);
  };

  setFields = (place) => {
    const { line1, line2, city, state, country, postal } = place;
    const { change, touch, fieldNamePrefix } = this.props;
    const formattedFieldPrefix = fieldNamePrefix ? `${fieldNamePrefix}.` : "";
    change(`${formattedFieldPrefix}line1`, line1 || "");
    change(`${formattedFieldPrefix}line2`, line2 || ""); // Clear line2 when updating address
    change(`${formattedFieldPrefix}city`, city || "");
    change(`${formattedFieldPrefix}state`, state || "");
    change(`${formattedFieldPrefix}country`, country);
    change(`${formattedFieldPrefix}postal`, postal || "");
    // There is a possibility that google comes back with missing fields, so we want to tell the user they need
    // to input that manually
    touch(`${formattedFieldPrefix}line1`);
    touch(`${formattedFieldPrefix}line2`);
    touch(`${formattedFieldPrefix}city`);
    touch(`${formattedFieldPrefix}state`);
    touch(`${formattedFieldPrefix}country`);
    touch(`${formattedFieldPrefix}postal`);
  };

  render() {
    const {
      intl,
      label,
      readOnly,
      disabled,
      fieldNamePrefix,
      change,
      untouch,
      requiredCountryCode,
      useStyledInputs,
      displayRequiredAsterisk,
      hiddenFields,
      disabledFields,
      showAddressLookup,
      autoComplete,
      line1Placeholder,
      line2Placeholder,
    } = this.props;

    const cx = useStyledInputs ? "AddressSubForm__newStyles" : "AddressSubForm";

    const formattedFieldPrefix = fieldNamePrefix ? `${fieldNamePrefix}.` : "";

    const lookup = get(this.props.formValues, `${formattedFieldPrefix}lookup`);

    const line2 = get(this.props.formValues, `${formattedFieldPrefix}line2`);
    const country = get(this.props.formValues, `${formattedFieldPrefix}country`);

    let fields = ["line1", "city", "country"];
    if (line2 || !readOnly) {
      fields.push("line2");
    }
    const stateRequired = isStateRequired(country);
    const postalRequired = isPostalRequired(country);
    if (stateRequired && postalRequired) {
      fields.push("state");
      fields.push("postal");
    } else if (!stateRequired && postalRequired) {
      fields.push("postal");
    }
    fields = fields.map((s) => `${formattedFieldPrefix}${s}`);

    const postalFieldAriaLabel =
      country === "US"
        ? intl.formatMessage(messages.ariaZipCode)
        : intl.formatMessage(messages.ariaZipOrPostalCode);

    const postalFieldPlaceholder =
      country === "US"
        ? intl.formatMessage(messages.zipCode)
        : intl.formatMessage(messages.zipOrPostalCode);

    const postalFieldProps = {
      name: `${formattedFieldPrefix}postal`,
      className: "AddressSubForm--postal",
      "data-automation-id": "postal-field",
      autoComplete: "postal-code",
      placeholder: postalFieldPlaceholder,
      "aria-label": postalFieldAriaLabel,
      maxLength: zipMaxLength(country),
      readOnly,
      placeholderAsLabel: useStyledInputs,
      useStyledInput: useStyledInputs,
      displayRequiredAsterisk,
      disabled: disabledFields.includes(ADDRESS_FIELDS.POSTAL) || disabled,
      normalize: normalizeZipCode,
    };

    // When this is converted to a non-redux form, can use the hook in
    // common/core/form/address/index.tsx which does the same thing.
    function normalizeZipCode(zipCode) {
      // if user types US zip longer than 5 digits with no separator, add "-".
      if (
        country === "US" &&
        !!zipCode &&
        zipCode.length > 5 &&
        zipCode[5] !== "-" &&
        zipCode[5] !== " "
      ) {
        return `${zipCode.substring(0, 5)}-${zipCode.substring(5)}`;
      }
      return zipCode;
    }

    return (
      <FormGroup
        fields={fields}
        className={cx}
        errorClassName="AddressSubForm__validationFailed"
        disableFormRowStyle={useStyledInputs}
      >
        {!useStyledInputs && <label>{label}</label>}
        {showAddressLookup && (
          <DeprecatedFormRow noMargin>
            <DeprecatedAddressLookup
              onAddressSelect={this.handleAddressSelect}
              useStyledInput={useStyledInputs}
              name={`${formattedFieldPrefix}lookup`}
              disabled={disabledFields.includes(ADDRESS_FIELDS.LOOKUP) || disabled}
              initialValue={lookup}
              label={label}
              autoComplete={autoComplete}
            />
          </DeprecatedFormRow>
        )}

        {!hiddenFields.includes(ADDRESS_FIELDS.LINE_1) && (
          <DeprecatedFormRow noMargin>
            {!showAddressLookup ? (
              <GoogleLookupWrapper onAddressSelect={this.setFields} allowChangeInput={false}>
                {(initializeLookup) => (
                  <DeprecatedTextField
                    aria-label={intl.formatMessage(messages.ariaAddressLine1)}
                    name={`${formattedFieldPrefix}line1`}
                    data-automation-id="line1-field"
                    className="AddressSubForm--line1"
                    placeholder={line1Placeholder || intl.formatMessage(messages.addressLine1)}
                    autoComplete="address-line1"
                    readOnly={readOnly}
                    useStyledInput={useStyledInputs}
                    placeholderAsLabel={useStyledInputs}
                    displayRequiredAsterisk={displayRequiredAsterisk}
                    disabled={disabledFields.includes(ADDRESS_FIELDS.LINE_1) || disabled}
                    onInputRefSet={initializeLookup}
                  />
                )}
              </GoogleLookupWrapper>
            ) : (
              <DeprecatedTextField
                aria-label={intl.formatMessage(messages.ariaAddressLine1)}
                name={`${formattedFieldPrefix}line1`}
                data-automation-id="line1-field"
                className="AddressSubForm--line1"
                placeholder={line1Placeholder || intl.formatMessage(messages.addressLine1)}
                autoComplete="address-line1"
                readOnly={readOnly}
                useStyledInput={useStyledInputs}
                placeholderAsLabel={useStyledInputs}
                displayRequiredAsterisk={displayRequiredAsterisk}
                disabled={disabledFields.includes(ADDRESS_FIELDS.LINE_1) || disabled}
              />
            )}
          </DeprecatedFormRow>
        )}

        {!(
          hiddenFields.includes(ADDRESS_FIELDS.LINE_2) && hiddenFields.includes(ADDRESS_FIELDS.CITY)
        ) && (
          <DeprecatedFormRow noMargin>
            <DeprecatedMultipartRow>
              {fields.includes(`${formattedFieldPrefix}line2`) &&
                !hiddenFields.includes(ADDRESS_FIELDS.LINE_2) && (
                  <DeprecatedTextField
                    aria-label={intl.formatMessage(messages.ariaUnitOrAptNumber)}
                    name={`${formattedFieldPrefix}line2`}
                    data-automation-id="line2-field"
                    className="AddressSubForm--line2"
                    placeholder={line2Placeholder || intl.formatMessage(messages.unitOrAptNumber)}
                    autoComplete="address-line2"
                    readOnly={readOnly}
                    useStyledInput={useStyledInputs}
                    placeholderAsLabel={useStyledInputs}
                    disabled={disabledFields.includes(ADDRESS_FIELDS.LINE_2) || disabled}
                  />
                )}

              {!hiddenFields.includes(ADDRESS_FIELDS.CITY) && (
                <DeprecatedTextField
                  aria-label={intl.formatMessage(messages.ariaCity)}
                  name={`${formattedFieldPrefix}city`}
                  data-automation-id="city-field"
                  className="AddressSubForm--city"
                  placeholder={intl.formatMessage(messages.city)}
                  autoComplete="address-level2"
                  readOnly={readOnly}
                  useStyledInput={useStyledInputs}
                  placeholderAsLabel={useStyledInputs}
                  displayRequiredAsterisk={displayRequiredAsterisk}
                  disabled={disabledFields.includes(ADDRESS_FIELDS.CITY) || disabled}
                />
              )}
            </DeprecatedMultipartRow>
          </DeprecatedFormRow>
        )}

        {stateRequired && postalRequired && !hiddenFields.includes(ADDRESS_FIELDS.STATE) && (
          <DeprecatedFormRow noMargin className="AddressSubForm--state-and-postal">
            <DeprecatedMultipartRow>
              <DeprecatedStateField
                name={`${formattedFieldPrefix}state`}
                className="AddressSubForm--state"
                data-automation-id="state-field"
                autoComplete="address-level1"
                readOnly={readOnly}
                country={country}
                useStyledInput={useStyledInputs}
                placeholderAsLabel={useStyledInputs}
                displayRequiredAsterisk={displayRequiredAsterisk}
                disabled={disabledFields.includes(ADDRESS_FIELDS.STATE) || disabled}
              />
              <DeprecatedTextField {...postalFieldProps} />
            </DeprecatedMultipartRow>
          </DeprecatedFormRow>
        )}

        {!requiredCountryCode && !hiddenFields.includes(ADDRESS_FIELDS.COUNTRY) && (
          <DeprecatedFormRow noMargin>
            <DeprecatedCountryField
              name={`${formattedFieldPrefix}country`}
              automationId="country-field"
              className="AddressSubForm--country"
              autoComplete="country"
              onChange={() => {
                change(`${formattedFieldPrefix}state`, null);
                change(`${formattedFieldPrefix}postal`, null);
                untouch(`${formattedFieldPrefix}state`);
                untouch(`${formattedFieldPrefix}postal`);
              }}
              readOnly={readOnly}
              clearable
              useStyledInput={useStyledInputs}
              placeholderAsLabel={useStyledInputs}
              displayRequiredAsterisk={displayRequiredAsterisk}
              disabled={disabledFields.includes(ADDRESS_FIELDS.COUNTRY) || disabled}
            />
          </DeprecatedFormRow>
        )}

        {!stateRequired && postalRequired && !hiddenFields.includes(ADDRESS_FIELDS.POSTAL) && (
          <DeprecatedFormRow noMargin className="AddressSubForm--postal">
            <DeprecatedTextField {...postalFieldProps} />
          </DeprecatedFormRow>
        )}

        <div>
          <FormGroupErrors
            // line2 will never have errors, but FormGroupErrors will
            // treat it as "not part of" the form group otherwise
            fields={fields}
            groupClassName="AddressSubForm"
            errorClassName="AddressSubForm--ValidationMessage"
          />
        </div>
      </FormGroup>
    );
  }
}

AddressSubForm.propTypes = {
  label: PropTypes.string,
  readOnly: PropTypes.bool,
  requiredCountryCode: PropTypes.string,
  fieldNamePrefix: PropTypes.string,
  displayRequiredAsterisk: PropTypes.bool,
  formName: PropTypes.string.isRequired,
  // showing lookup is done using showAddressLookup
  hiddenFields: PropTypes.arrayOf(
    PropTypes.oneOf(Object.values(ADDRESS_FIELDS).filter((v) => v !== "LOOKUP")),
  ),
  showAddressLookup: PropTypes.bool,
  disabledFields: PropTypes.arrayOf(PropTypes.oneOf(Object.values(ADDRESS_FIELDS))),
  // temp prop to make the subform use non global styles
  // until we just outright refactor the subform to use non global everywhere
  useStyledInputs: PropTypes.bool,
  // from subForm/ReduxForm wrapper
  initialize: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  formValues: PropTypes.shape({
    line2: PropTypes.string,
    country: PropTypes.string,
  }).isRequired,
  onAddressSelect: PropTypes.func,
  autoComplete: PropTypes.bool,
};

AddressSubForm.defaultProps = {
  autoComplete: true,
  label: "Address",
  readOnly: false,
  disabled: false,
  requiredCountryCode: null,
  hiddenFields: [],
  showAddressLookup: false,
  disabledFields: [],
  onAddressSelect: () => {},
};

/** @deprecated - please use components in common/core/form */
export const DeprecatedAddressSubForm = deprecatedSubForm({
  getValuesFor: ["line2", "country", "lookup"],
})(injectIntl(AddressSubForm));
