import { type ChangeEvent, useState } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import { useMutation } from "util/graphql";
import Button from "common/core/button";
import { DeprecatedTextInput } from "common/form/inputs/text";
import { BinaryToggle } from "common/core/form/binary_toggle";
import WorkflowModal from "common/modals/workflow_modal";
import Table from "common/core/table";
import { useId } from "util/html";
import { IconButton } from "common/core/button/icon_button";

import AdminToolSection from "../admin_tool_section";
import AddRecordingLocationsMutation, {
  type AddRecordingLocations,
  type AddRecordingLocationsVariables,
  type AddRecordingLocations_addRecordingLocations_results as LocationResult,
} from "./add_recording_locations_mutation.graphql";
import Styles from "./index.module.scss";
import CSVUploader from "./csv_uploader";
import { batcher } from "./util";

const MESSAGES = defineMessages({
  deleteRow: {
    id: "238ba4dd-df65-4705-b22e-ef068764541c",
    defaultMessage: "Delete row",
  },
});

export type FormRow = {
  county: string;
  state: string;
  rjid: string;
  jurisdiction: string;
  erecordingSupported: boolean;
  addressComponent: string | null;
};

const EMPTY_FORM_ROW: FormRow = {
  county: "",
  state: "",
  rjid: "",
  jurisdiction: "",
  erecordingSupported: true,
  addressComponent: null,
};

type InnerProps = {
  addRecordingLocations: ReturnType<
    typeof useMutation<AddRecordingLocations, AddRecordingLocationsVariables>
  >;
};

function RecordingLocationTool(props: InnerProps) {
  const intl = useIntl();
  const [status, setStatus] = useState<"none" | "error" | "success">("none");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [data, setData] = useState<FormRow[]>([EMPTY_FORM_ROW]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const erecordingSupportedToggle = useId();

  const addRow = () => {
    setData([...data, EMPTY_FORM_ROW]);
  };

  const deleteRow = (indexToRemove: number) => {
    setData(data.filter((_, index) => index !== indexToRemove));
  };

  const deleteRowsByRJID = (rjids: string[]) => {
    setData(data.filter(({ rjid }) => !rjids.includes(rjid)));
  };

  const updateRow = (index: number, newRow: FormRow) => {
    const newData = [...data];
    newData[index] = newRow;
    setData(newData);
  };

  const updateERecordingSupported = (index: number, value: boolean) => {
    const newRow = { ...data[index] };
    newRow.erecordingSupported = value;
    updateRow(index, newRow);
  };

  const updateComponentPreference = (index: number, value: string | null) => {
    const newRow = { ...data[index] };
    newRow.addressComponent = value;
    updateRow(index, newRow);
  };

  const updateJurisdiction = (index: number, value: string) => {
    const newRow = { ...data[index] };
    newRow.jurisdiction = value;
    updateRow(index, newRow);
  };

  const updateCounty = (index: number, value: string) => {
    const newRow = { ...data[index] };
    newRow.county = value;
    updateRow(index, newRow);
  };

  const updateState = (index: number, value: string) => {
    const newRow = { ...data[index] };
    newRow.state = value;
    updateRow(index, newRow);
  };

  const updateRJID = (index: number, value: string) => {
    const newRow = { ...data[index] };
    newRow.rjid = value;
    updateRow(index, newRow);
  };

  const save = async () => {
    setIsLoading(true);
    const batchedData = batcher(data);
    try {
      const batchedWork = batchedData.map((batch) => {
        return props.addRecordingLocations({
          variables: {
            input: {
              locations: batch.map((row) => ({
                county: row.county,
                stateAbbreviation: row.state,
                rjid: row.rjid,
                jurisdiction: row.jurisdiction,
                erecordingSupported: row.erecordingSupported,
                addressComponent: row.addressComponent,
              })),
            },
          },
        });
      });
      const dataResults = await Promise.all(batchedWork);

      setIsLoading(false);
      const results = dataResults.reduce<LocationResult[]>((agg, { data: dataResult }) => {
        agg.push(...dataResult!.addRecordingLocations!.results);
        return agg;
      }, []);

      const successfulRJIDS = results.filter(({ success }) => success).map(({ rjid }) => rjid);
      deleteRowsByRJID(successfulRJIDS);
      const failureCount = results.filter(({ success }) => !success).length;
      if (failureCount > 0) {
        setErrorMessage(
          `Successfully added ${successfulRJIDS.length} locations and failed to add ${failureCount}. Please correct failures and try again.`,
        );
        setStatus("error");
        return;
      }
      setStatus("success");
    } catch (e) {
      setIsLoading(false);
      setStatus("error");
      if (e instanceof SyntaxError) {
        setErrorMessage("Failed to add some or all recording locations");
      } else {
        setErrorMessage((e as Error).message);
      }
    }
  };

  const columns = [
    {
      Header: (
        <FormattedMessage
          id="505be3b8-9db6-496d-b291-702261606c2e"
          defaultMessage="Recording Jurisdiction"
        />
      ),
      render: (_: FormRow, i: number) => (
        <DeprecatedTextInput
          placeholder="Recording Jurisdiction"
          value={data[i].jurisdiction}
          onChange={(ev: ChangeEvent<HTMLInputElement>) => {
            updateJurisdiction(i, ev.target.value);
          }}
        />
      ),
    },
    {
      Header: (
        <FormattedMessage id="c66a09c4-04b0-4a16-950f-345b884b87b4" defaultMessage="County" />
      ),
      render: (_: FormRow, i: number) => (
        <DeprecatedTextInput
          placeholder="County"
          value={data[i].county}
          onChange={(ev: ChangeEvent<HTMLInputElement>) => {
            updateCounty(i, ev.target.value);
          }}
        />
      ),
    },
    {
      Header: <FormattedMessage id="82599a2e-2ece-4afe-8f5d-31a851208453" defaultMessage="State" />,
      render: (_: FormRow, i: number) => (
        <DeprecatedTextInput
          placeholder="State"
          className={Styles.stateInput}
          value={data[i].state}
          onChange={(ev: ChangeEvent<HTMLInputElement>) => {
            updateState(i, ev.target.value);
          }}
        />
      ),
    },
    {
      Header: (
        <FormattedMessage id="a131a0c7-c107-41bb-a189-407ea2dd0049" defaultMessage="PRIA RJID" />
      ),
      render: (_: FormRow, i: number) => (
        <DeprecatedTextInput
          placeholder="PRIA RJID"
          value={data[i].rjid}
          onChange={(ev: ChangeEvent<HTMLInputElement>) => {
            updateRJID(i, ev.target.value);
          }}
        />
      ),
    },
    {
      Header: (
        <span id={erecordingSupportedToggle}>
          <FormattedMessage
            id="f5ffe4b0-d5d6-43a9-81b2-4e396be2034a"
            defaultMessage="eRecording supported"
          />
        </span>
      ),
      render: (_: FormRow, i: number) => (
        <BinaryToggle
          aria-labelledby={erecordingSupportedToggle}
          value={data[i].erecordingSupported}
          onChange={(value) => {
            updateERecordingSupported(i, value);
          }}
        />
      ),
    },
    {
      Header: (
        <span id={erecordingSupportedToggle}>
          <FormattedMessage
            id="e8dac0b5-58c3-42d2-9ea5-865a12e86528"
            defaultMessage="Address component priority"
          />
        </span>
      ),
      render: (_: FormRow, i: number) => (
        <select onChange={(e) => updateComponentPreference(i, e.target.value || null)}>
          <option value="" selected={data[i].addressComponent === null}>
            No preference
          </option>
          <option
            value="administrative_area_level_2"
            selected={data[i].addressComponent === "administrative_area_level_2"}
          >
            County
          </option>
          <option value="locality" selected={data[i].addressComponent === "locality"}>
            Locality
          </option>
        </select>
      ),
    },
  ];

  return (
    <>
      <AdminToolSection title="Add Recording Locations">
        <CSVUploader
          onDataUploaded={setData}
          onError={(errorMessage) => {
            setStatus("error");
            setErrorMessage(errorMessage);
          }}
        />
        <div>
          <Table
            columns={columns}
            data={data}
            totalItems={data.length}
            rowActionRender={(_: FormRow, i: number) => (
              <IconButton
                buttonColor="danger"
                buttonSize="condensed"
                variant="tertiary"
                onClick={() => {
                  deleteRow(i);
                }}
                name="x"
                label={intl.formatMessage(MESSAGES.deleteRow)}
              />
            )}
          />
          <Button
            buttonColor="action"
            variant="tertiary"
            onClick={addRow}
            className={Styles.addRow}
            withIcon={{ name: "add", placement: "left" }}
          >
            <FormattedMessage id="b870c287-5af1-4b43-b429-09eb0601284c" defaultMessage="Add row" />
          </Button>
          <Button buttonColor="action" variant="primary" isLoading={isLoading} onClick={save}>
            <FormattedMessage id="8180dac1-9893-407a-abbe-e631c91779df" defaultMessage="Save" />
          </Button>
        </div>
      </AdminToolSection>

      {status === "error" && (
        <WorkflowModal
          title={
            <FormattedMessage
              id="19651c9b-e56e-44ba-8d6a-7e8ca76d6c78"
              defaultMessage="Failed to add some locations"
            />
          }
          buttons={[
            <Button
              key="dismiss"
              onClick={() => setStatus("none")}
              buttonColor="action"
              variant="primary"
            >
              <FormattedMessage id="b40f4a15-2dce-4851-a991-b94c88f7e6e7" defaultMessage="Close" />
            </Button>,
          ]}
          footerSeparator={false}
        >
          <p>{errorMessage || "Something went wrong"}</p>
        </WorkflowModal>
      )}

      {status === "success" && (
        <WorkflowModal
          title={
            <FormattedMessage id="d2dfd818-6a60-440a-82ca-a99a32b17a39" defaultMessage="Success!" />
          }
          buttons={[
            <Button
              key="dismiss"
              onClick={() => setStatus("none")}
              buttonColor="action"
              variant="primary"
            >
              <FormattedMessage id="bc224c99-6ad9-4775-bcba-8b193a520212" defaultMessage="Close" />
            </Button>,
          ]}
          footerSeparator={false}
        >
          <FormattedMessage
            id="bb6fccf8-4a56-4bdd-9a34-01d7d1057123"
            defaultMessage="Successfully added new locations"
          />
        </WorkflowModal>
      )}
    </>
  );
}

export default function RecordingLocationToolContainer() {
  const addRecordingLocationsMutation = useMutation(AddRecordingLocationsMutation);
  return <RecordingLocationTool addRecordingLocations={addRecordingLocationsMutation} />;
}
