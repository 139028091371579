import { FormattedMessage } from "react-intl";
import { useNavigate, Navigate } from "react-router-dom";
import { type InjectedFormProps, reduxForm } from "redux-form";

import compose from "util/compose";
import { getFormValues } from "util/form";
import { DeprecatedSelectField } from "common/form/fields/select";
import AppFrameInnerContainer from "common/app_frame/inner_container";
import FormGroup from "common/form/group";
import { useQuery } from "util/graphql";
import LoadingIndicator from "common/core/loading_indicator";
import { usePermissions } from "common/core/current_user_role";
import { ButtonStyledLink } from "common/core/button/button_styled_link";

import TierListQuery from "./tier_list_query.graphql";

type FormValues = {
  tier: string | undefined;
};
type FormProps = InjectedFormProps<FormValues>;
type GetFormValueProps = { formValues: FormValues };

type InnerProps = FormProps & GetFormValueProps;

function FinanceTools() {
  const { hasPermissionFor } = usePermissions();
  const navigate = useNavigate();
  const { data, loading } = useQuery(TierListQuery);

  if (loading) {
    return <LoadingIndicator />;
  }

  const dropdownItems = data?.viewer.tiers.edges.map((x) => ({
    value: x.node.id,
    label: x.node.name,
  }));

  if (!hasPermissionFor("tierDetails")) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <AppFrameInnerContainer title="Tiers" closePath="/">
      {hasPermissionFor("createTier") && (
        <ButtonStyledLink
          to="/finance/new"
          className="AppFrameInnerContainer--Action"
          automationId="create-new-tier-button"
          buttonColor="action"
          variant="primary"
          buttonSize="condensed"
          withIcon={{ name: "add", placement: "left" }}
        >
          <FormattedMessage id="2d9e7909-f8a9-4964-936b-40c2a519c6b7" defaultMessage="Create new" />
        </ButtonStyledLink>
      )}
      <form name="financeToolsForm">
        <FormGroup fields={["tier"]} disableFormRowStyle>
          <DeprecatedSelectField
            name="tier"
            useStyledInput
            automationId="tier-list"
            items={dropdownItems}
            placeholder={
              <FormattedMessage
                id="cd289b1e-70fb-4a42-ada6-8e1dd0f11d69"
                defaultMessage="Choose a tier..."
              />
            }
            disabled={!hasPermissionFor("tierDetails")}
            onChange={(value: string) => {
              if (value) {
                navigate(`/finance/details/${value}`);
              } else {
                navigate("/finance");
              }
            }}
            clearable
          />
        </FormGroup>
      </form>
    </AppFrameInnerContainer>
  );
}

export default compose(
  reduxForm<FormValues>({ form: "financeToolsForm" }),
  getFormValues<InnerProps>("financeToolsForm"),
)(FinanceTools);
