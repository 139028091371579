import { useState, useCallback } from "react";
import { useIntl, defineMessages } from "react-intl";
import classnames from "classnames";

import { useDrag } from "common/core/hooks/use-drag";
import { SENSITIVE_CLASS } from "common/core/sensitive_label";
import Icon from "common/core/icon";
import { PDFViewerWithControls } from "common/pdf/pspdfkit/viewer";
// We use the pdf control button to share styles and get a consistent experience between
// pdf and picture viewing.
import { PDFControlButton as ControlButton } from "common/pdf/pspdfkit/util";

import Styles from "./scrutinizer.module.scss";

type Props = {
  src: { type: "image" | "pdf"; url: string };
  onPopout?: () => void;
};
type DragEvent = { type: "move"; x: number; y: number } | { type: "bookend-start" | "bookend-end" };
type ImageState = Readonly<{
  rotation: number;
  scale: number;
  x: number;
  y: number;
  draggingDiff: null | { x: number; y: number };
}>;

const MESSAGES = defineMessages({
  rotateClock: {
    id: "6a5de745-3d4a-4e73-9150-33ed398d5ae3",
    defaultMessage: "Rotate clockwise",
  },
  rotateCounter: {
    id: "8d640b3c-e7b7-4438-94da-fc458e0121fd",
    defaultMessage: "Rotate counter-clockwise",
  },
  zoomIn: {
    id: "04aa2331-6942-44a0-ba88-37e79ba98edd",
    defaultMessage: "Zoom in",
  },
  zoomOut: {
    id: "8d5194dc-e678-4863-bc13-27996dc57dbf",
    defaultMessage: "Zoom out",
  },
  reset: {
    id: "cc9b00da-2e7b-46fe-955d-f542823bb7d0",
    defaultMessage: "Reset position",
  },
  openPopout: {
    id: "647b4e51-5676-4b7b-a194-35d60f66e259",
    defaultMessage: "Open popout viewer",
  },
});
const SCALE_INCREMENT = 0.2;
const INIT_STATE: ImageState = {
  rotation: 0,
  scale: 1,
  x: 0,
  y: 0,
  draggingDiff: null,
};

function useImageState() {
  const [imageState, setImageState] = useState<ImageState>(INIT_STATE);
  const onMouseDown = useDrag(
    useCallback((event: DragEvent) => {
      switch (event.type) {
        case "bookend-start":
          return setImageState((old) => ({ ...old, draggingDiff: { x: 0, y: 0 } }));
        case "bookend-end":
          return setImageState((old) => ({
            ...old,
            x: old.x + (old.draggingDiff?.x || 0),
            y: old.y + (old.draggingDiff?.y || 0),
            draggingDiff: null,
          }));
        case "move":
          return setImageState((old) => ({ ...old, draggingDiff: { x: event.x, y: event.y } }));
      }
    }, []),
  );
  return {
    imageState,
    imageActions: {
      reset: () => {
        setImageState(INIT_STATE);
      },
      beginDrag: onMouseDown,
      zoomIn: () => {
        setImageState({ ...imageState, scale: Math.min(3, imageState.scale + SCALE_INCREMENT) });
      },
      zoomOut: () => {
        setImageState({
          ...imageState,
          scale: Math.max(SCALE_INCREMENT, imageState.scale - SCALE_INCREMENT),
        });
      },
      rotateClockwise: () => {
        setImageState({ ...imageState, rotation: imageState.rotation + 90 });
      },
      rotateCounterClockwise: () => {
        setImageState({ ...imageState, rotation: imageState.rotation - 90 });
      },
    },
  };
}

function getImageTransform({ rotation, scale, x, y, draggingDiff }: ImageState): string {
  const translateX = x + (draggingDiff?.x || 0);
  const translateY = y + (draggingDiff?.y || 0);
  return `translate(${translateX}px, ${translateY}px) rotate(${rotation}deg) scale(${scale})`;
}

function DocumentScrutinizer({ src, onPopout }: Props) {
  const intl = useIntl();
  const { imageState, imageActions } = useImageState();

  return src.type === "pdf" ? (
    <div className={Styles.mainForPdf}>
      <PDFViewerWithControls url={src.url} className={Styles.pdf} />
    </div>
  ) : (
    <div className={classnames(Styles.main, imageState.draggingDiff && Styles.grabbed)}>
      <img
        key={src.url}
        src={src.url}
        alt=""
        onMouseDown={imageActions.beginDrag}
        className={SENSITIVE_CLASS}
        style={{ transform: getImageTransform(imageState) }}
        data-automation-id="document-scrutinizer-img"
      />

      <div className={Styles.actions}>
        <ControlButton
          aria-label={intl.formatMessage(MESSAGES.rotateCounter)}
          onClick={imageActions.rotateCounterClockwise}
        >
          <Icon name="rotate" />
        </ControlButton>
        <ControlButton
          aria-label={intl.formatMessage(MESSAGES.rotateClock)}
          onClick={imageActions.rotateClockwise}
        >
          <Icon name="rotate-clockwise" />
        </ControlButton>
        <ControlButton
          aria-label={intl.formatMessage(MESSAGES.zoomIn)}
          onClick={imageActions.zoomIn}
        >
          <Icon name="add" />
        </ControlButton>
        <ControlButton
          aria-label={intl.formatMessage(MESSAGES.zoomOut)}
          onClick={imageActions.zoomOut}
        >
          <Icon name="remove" />
        </ControlButton>
        <ControlButton aria-label={intl.formatMessage(MESSAGES.reset)} onClick={imageActions.reset}>
          <Icon name="fullscreen" />
        </ControlButton>
        {onPopout && (
          <ControlButton aria-label={intl.formatMessage(MESSAGES.openPopout)} onClick={onPopout}>
            <Icon name="picture-in-picture" />
          </ControlButton>
        )}
      </div>
    </div>
  );
}

export default DocumentScrutinizer;
