import { useCallback, useEffect, useState, type ReactNode } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import classnames from "classnames";

import {
  Ial2CheckboxLabel,
  Ial2CheckboxSublabel,
  SmsAuthCheckboxLabel,
  SmsAuthCheckboxSublabel,
  KbaCheckboxLabel,
  KbaCheckboxSublabel,
  ProofRequirementFormValues,
  type ProofRequirementField,
  CaSelfieCheckboxLabel,
  CaSelfieCheckboxSublabel,
} from "common/proof_requirements/common";
import Icon from "common/core/icon";
import { EmailTextInput, TextInput } from "common/core/form/text";
import { useFieldArray, type UseFormReturn } from "common/core/form";
import {
  RadioGroup,
  RadioLabel,
  RadioInput,
  CheckboxGroup,
  CheckboxLabel,
  Checkbox,
  type PresentNode,
} from "common/core/form/option";
import { DeprecatedFormRow } from "common/form/elements/row";
import { AuthTypes, OrganizationApiKeyPayer, type ProofRequirements } from "graphql_globals";
import { AutomaticFormRow, Label } from "common/core/form/layout";
import RequiredAsterisk from "common/core/form/required-asterisk";
import Link from "common/core/link";
import { BinaryToggle } from "common/core/form/binary_toggle";
import {
  defaultRequiredMessage,
  emailPatternValidation,
  FieldErrorMessage,
} from "common/core/form/error";
import { useId } from "util/html";
import { SUPPORT_HOST } from "constants/support";
import { Paragraph } from "common/core/typography";
import { Select } from "common/core/form/select";
import AlertMessage from "common/core/alert_message";
import {
  ONLY_EXTERNAL_PAYMENT_NOTICE,
  notaryMustCollectExternalPayment,
} from "common/notary/payment";
import {
  RadioGroupAddPaymentMethodWarning,
  FormCard,
  AddPaymentMethodWarning,
} from "common/dashboard/common";
import { PROOF_TRANSACTIONS } from "constants/feature_gates";
import { useFeatureFlag } from "common/feature_gating";
import { isNotaryNST } from "common/notary/capacity";
import { MAX_CC_RECIPIENTS } from "constants/transaction";
import Button from "common/core/button";
import { LinkStyledButton } from "common/core/link/link_styled_button";

import Styles from "./common.module.scss";
import type { PayerOrganization } from "./payer_organization.graphql";

export const COMMON_MESSAGES = defineMessages({
  NAME_NOT_PROVIDED: {
    id: "6ccb5d6b-edfe-4c29-bafa-dbf145dfe76a",
    defaultMessage: "Name not provided",
  },
  ERROR_MESSAGE: {
    id: "01b9cf7f-016f-4137-bdbb-1cf2c7f7fe23",
    defaultMessage: "Sorry, something went wrong. Please try again.",
  },
  NAME_LABEL: {
    id: "8e9fe8bb-4492-48dd-9550-4d2c9be073df",
    defaultMessage: "EasyLink name",
  },
});

const MESSAGES = defineMessages({
  NAME_REQUIRED_MESSAGE: {
    id: "d59653ce-6a85-4de5-a464-9a8cb78fe39c",
    defaultMessage: "An EasyLink name is required.",
  },
  NAME_PLACEHOLDER: {
    id: "f1087e57-9de7-470d-a5b0-cf7dd177ea8f",
    defaultMessage: "ex. Power of Attorney",
  },
  PROOF_REQUIREMENT_ERROR: {
    id: "59e7459b-3047-43e2-9a84-d9c03b5e7d97",
    defaultMessage: "Please select at least one identity verification option.",
  },
  EMAIL: {
    id: "e40ca233-5549-422a-8ee1-d3fe55914fe8",
    defaultMessage: "Email",
  },
  DUPLICATE_EMAIL_ERROR: {
    id: "bcd22092-ad51-4074-b316-b787ddac157c",
    defaultMessage: "Email has already been used",
  },
  EMAIL_REQUIRED_ERROR: {
    id: "cdee3eb6-1505-46d4-90a5-f2cef9ed0e74",
    defaultMessage: "Email required",
  },
  EMAIL_ARIA_LABEL: {
    id: "ca039f4f-a240-4005-b95c-b5f45166edb1",
    defaultMessage: "CC contact email {number} (required)",
  },
  REMOVE_CC_RECIPIENT: {
    id: "dc7a5e4b-f177-4b44-a267-ec26a0532431",
    defaultMessage: "Remove CC contact {number}",
  },
});

type NameField = {
  name: string;
};
export function NameInput<FormFields extends NameField>({
  form: untypedFormReturn,
}: {
  form: UseFormReturn<FormFields>;
}) {
  // https://github.com/react-hook-form/react-hook-form/issues/6726
  const form = untypedFormReturn as unknown as UseFormReturn<NameField>;

  const intl = useIntl();

  return (
    <FormCard
      cardTitle={
        <>
          <FormattedMessage
            id="19bac3a4-b9d4-4aff-af10-bac014cc9668"
            defaultMessage="Enter a name for your EasyLink"
          />
          <RequiredAsterisk />
        </>
      }
      cardDescription={
        <FormattedMessage
          id="d9641c21-b890-49b7-b4b6-32537f8fdd4d"
          defaultMessage="This will be visible to your recipients."
        />
      }
    >
      <AutomaticFormRow
        form={form}
        name="name"
        aria-label={intl.formatMessage(COMMON_MESSAGES.NAME_LABEL)}
        placeholder={intl.formatMessage(MESSAGES.NAME_PLACEHOLDER)}
        registerOptions={{ required: intl.formatMessage(MESSAGES.NAME_REQUIRED_MESSAGE) }}
        dynamicHeightError
        highlightLabelOnError
        as={TextInput}
      />
    </FormCard>
  );
}

type PayerField = { payer: OrganizationApiKeyPayer };
export function PayerRadioGroup<FormFields extends PayerField>(props: {
  user: { notaryProfile: Parameters<typeof notaryMustCollectExternalPayment>[0] };
  form: UseFormReturn<FormFields>;
  organization: PayerOrganization;
}) {
  const { organization } = props;
  // https://github.com/react-hook-form/react-hook-form/issues/6726
  const { watch, register, setValue } = props.form as unknown as UseFormReturn<PayerField>;
  const payer = watch("payer");

  const orgMustBePayer = notaryMustCollectExternalPayment(props.user.notaryProfile);
  const orgCannotBePayer = !organization.chargeablePaymentMethod;
  useEffect(() => {
    if (orgMustBePayer) {
      setValue("payer", OrganizationApiKeyPayer.ORGANIZATION);
    }
  }, [orgMustBePayer]);

  const radioGroupIdLabel = useId();
  const radioGroupIdDescribedBy = useId();

  return (
    <FormCard
      cardTitle={
        <span id={radioGroupIdLabel}>
          <FormattedMessage
            id="0803975c-ad09-4403-bda2-23bea69f78e5"
            defaultMessage="Who will be paying for the transaction?"
          />
        </span>
      }
      cardDescription={
        <span id={radioGroupIdDescribedBy}>
          <FormattedMessage
            id="c8dd4782-5285-4c8a-83c2-b6d83de60910"
            defaultMessage="This will override your default account payment settings for any transactions using this EasyLink."
          />
        </span>
      }
    >
      {orgMustBePayer ? (
        <Paragraph>{ONLY_EXTERNAL_PAYMENT_NOTICE}</Paragraph>
      ) : (
        <DeprecatedFormRow>
          <RadioGroup
            aria-labelledby={radioGroupIdLabel}
            aria-describedby={radioGroupIdDescribedBy}
          >
            <RadioLabel
              label={
                <FormattedMessage
                  id="53179f76-c329-4516-a68a-10635554fe1f"
                  defaultMessage="The signer"
                />
              }
              radio={<RadioInput value={OrganizationApiKeyPayer.CUSTOMER} {...register("payer")} />}
            />
            <RadioLabel
              label={
                <FormattedMessage
                  id="6ef5323c-11af-4d02-8806-05ca45a3729c"
                  defaultMessage="My organization"
                />
              }
              radio={
                <RadioInput
                  disabled={orgCannotBePayer}
                  value={OrganizationApiKeyPayer.ORGANIZATION}
                  {...register("payer")}
                />
              }
            />
            {orgCannotBePayer && <RadioGroupAddPaymentMethodWarning />}
            {payer === OrganizationApiKeyPayer.ORGANIZATION && (
              <div className={Styles.organizationPayerWarning}>
                <Icon className={Styles.organizationPayerWarningIcon} name="warning" />
                <FormattedMessage
                  id="64cc17fc-7542-41c5-b3e2-85efd8ae4b74"
                  defaultMessage="Signers can potentially share EasyLinks outside of your intended audience."
                />
              </div>
            )}
          </RadioGroup>
        </DeprecatedFormRow>
      )}
    </FormCard>
  );
}

export type ProofField = { proofRequirement?: ProofRequirementField };
const PROOF_REQUIREMENT_FIELD_NAME = "proofRequirement";
export function ProofRequirement<FormFields extends ProofField>(
  props: {
    form: UseFormReturn<FormFields>;
    defaultAuthenticationRequirement: AuthTypes;
    proofSectionSubcopy: PresentNode;
    showTipwell?: boolean;
    required?: boolean;
    availableEasylinkProofRequirements?: ProofRequirementFormValues[];
    availableProofRequirements?: ProofRequirements[];
  } & (
    | {
        disabled?: boolean;
        disabledMessage: PresentNode;
      }
    | {
        disabled?: never;
        disabledMessage?: never;
      }
  ),
) {
  const {
    required,
    disabled,
    form: untypedFormReturn,
    defaultAuthenticationRequirement,
    disabledMessage,
    proofSectionSubcopy,
    availableProofRequirements = [],
    availableEasylinkProofRequirements = [
      ProofRequirementFormValues.IAL2,
      ProofRequirementFormValues.SMS,
      ProofRequirementFormValues.KBA,
    ],
  } = props;
  const intl = useIntl();
  const proofToggleId = useId();

  // https://github.com/react-hook-form/react-hook-form/issues/6726
  const form = untypedFormReturn as unknown as UseFormReturn<ProofField>;
  const { register, formState, unregister, resetField, setValue } = form;
  const error = formState.errors[PROOF_REQUIREMENT_FIELD_NAME];
  const proofRequirement = form.watch(PROOF_REQUIREMENT_FIELD_NAME);
  const defaultAuthRequirement: ProofRequirementField =
    defaultAuthenticationRequirement === AuthTypes.SMS ? [ProofRequirementFormValues.SMS] : [];
  const showTipwell = props.showTipwell ?? true;

  //TODO Remove ProofRequirementFormValues in BIZ-7244
  const availableOptions = availableProofRequirements
    .map((proofRequirement) => ProofRequirementFormValues[proofRequirement])
    .filter((proofRequirement) => availableEasylinkProofRequirements.includes(proofRequirement));

  function registerIal2() {
    const registerProps = register(PROOF_REQUIREMENT_FIELD_NAME, {
      required: intl.formatMessage(MESSAGES.PROOF_REQUIREMENT_ERROR),
    });
    registerProps.onChange = () => {
      const linkedFields = [
        ProofRequirementFormValues.IAL2,
        ProofRequirementFormValues.CA_SELFIE,
        ProofRequirementFormValues.SMS,
      ];
      const options = { shouldValidate: true, shouldTouch: true, shouldDirty: true };
      if (proofRequirement?.includes(ProofRequirementFormValues.IAL2)) {
        setValue(
          PROOF_REQUIREMENT_FIELD_NAME,
          proofRequirement.filter((pr) => !linkedFields.includes(pr)),
          options,
        );
      } else {
        setValue(
          PROOF_REQUIREMENT_FIELD_NAME,
          [...new Set([...(proofRequirement ?? []), ...linkedFields])],
          options,
        );
      }

      return Promise.resolve();
    };
    return registerProps;
  }

  useEffect(() => {
    if (!formState.defaultValues?.proofRequirement) {
      resetField(PROOF_REQUIREMENT_FIELD_NAME, { defaultValue: defaultAuthRequirement });
    }
  }, []);

  useEffect(() => {
    if (disabled) {
      unregister(PROOF_REQUIREMENT_FIELD_NAME);
    }
  }, [disabled]);

  function handleShowProofOptions() {
    // proofRequirement can be [] with form errors
    // setValue to trigger the isDirty on edit from
    // https://github.com/react-hook-form/react-hook-form/issues/406
    if (proofRequirement === undefined) {
      register(PROOF_REQUIREMENT_FIELD_NAME);
      resetField(PROOF_REQUIREMENT_FIELD_NAME);
      setValue(PROOF_REQUIREMENT_FIELD_NAME, defaultAuthRequirement, { shouldDirty: true });
    } else {
      setValue(PROOF_REQUIREMENT_FIELD_NAME, [], { shouldDirty: true });
      unregister(PROOF_REQUIREMENT_FIELD_NAME);
    }
  }
  const formGroupLabelId = useId();
  if (availableOptions.length === 0) {
    return null;
  }
  return (
    <FormCard
      disabled={disabled}
      cardTitle={
        <div className={classnames(Styles.proofRequirementTitle, disabled && Styles.disabled)}>
          <span id={proofToggleId}>
            <FormattedMessage
              id="33712359-b04a-4fe3-86dc-549f7d839173"
              defaultMessage="Add identity verification with Proof"
            />
          </span>
          {!disabled && <RequiredAsterisk />}
          {!required && (
            <BinaryToggle
              className={Styles.proofRequirementToggle}
              aria-labelledby={proofToggleId}
              onChange={handleShowProofOptions}
              value={!disabled && Boolean(proofRequirement)}
              disabled={disabled}
            />
          )}
        </div>
      }
      cardDescription={disabled && disabledMessage}
      tipWellHeader={
        showTipwell && (
          <FormattedMessage
            id="7c7bd8a7-818c-4153-90a0-3ee6f29eb169"
            defaultMessage="What is Proof?"
          />
        )
      }
      tipWellContent={
        showTipwell && (
          <FormattedMessage
            id="923b4978-f8e7-48d0-952e-6cb96726975c"
            defaultMessage="Proof provides a heightened level of assurance to validate a signer's identity, prior to them electronically signing a document. <link>Learn more</link>"
            values={{
              link: (text) => (
                <Link href={`${SUPPORT_HOST}/hc/en-us/articles/8706936036887`}>{text}</Link>
              ),
            }}
          />
        )
      }
    >
      <>
        {proofRequirement !== undefined && (
          <>
            <Paragraph id={formGroupLabelId}>{proofSectionSubcopy}</Paragraph>
            <DeprecatedFormRow>
              <CheckboxGroup
                groupError={
                  error && (
                    <FieldErrorMessage
                      inputName={PROOF_REQUIREMENT_FIELD_NAME}
                      message={error.message}
                    />
                  )
                }
                aria-labelledby={formGroupLabelId}
              >
                {availableOptions.includes(ProofRequirementFormValues.IAL2) && (
                  <CheckboxLabel
                    label={<Ial2CheckboxLabel />}
                    subLabel={<Ial2CheckboxSublabel />}
                    checkbox={
                      <Checkbox
                        aria-invalid={Boolean(error)}
                        value={ProofRequirementFormValues.IAL2}
                        data-automation-id="ial2-proof-checkbox"
                        {...registerIal2()}
                      />
                    }
                  />
                )}
                {availableOptions.includes(ProofRequirementFormValues.SMS) && (
                  <CheckboxLabel
                    label={<SmsAuthCheckboxLabel />}
                    subLabel={<SmsAuthCheckboxSublabel />}
                    checkbox={
                      <Checkbox
                        aria-invalid={Boolean(error)}
                        value={ProofRequirementFormValues.SMS}
                        disabled={proofRequirement.includes(ProofRequirementFormValues.IAL2)}
                        data-automation-id="sms-proof-checkbox"
                        {...register(PROOF_REQUIREMENT_FIELD_NAME, {
                          required: intl.formatMessage(MESSAGES.PROOF_REQUIREMENT_ERROR),
                        })}
                      />
                    }
                  />
                )}
                {availableOptions.includes(ProofRequirementFormValues.CA_SELFIE) && (
                  <CheckboxLabel
                    label={<CaSelfieCheckboxLabel />}
                    subLabel={<CaSelfieCheckboxSublabel />}
                    checkbox={
                      <Checkbox
                        aria-invalid={Boolean(error)}
                        value={ProofRequirementFormValues.CA_SELFIE}
                        disabled={proofRequirement.includes(ProofRequirementFormValues.IAL2)}
                        data-automation-id="ca-selfie-proof-checkbox"
                        {...register(PROOF_REQUIREMENT_FIELD_NAME, {
                          required: intl.formatMessage(MESSAGES.PROOF_REQUIREMENT_ERROR),
                        })}
                      />
                    }
                  />
                )}
                {availableOptions.includes(ProofRequirementFormValues.KBA) && (
                  <CheckboxLabel
                    label={<KbaCheckboxLabel />}
                    subLabel={<KbaCheckboxSublabel />}
                    checkbox={
                      <Checkbox
                        aria-invalid={Boolean(error)}
                        value={ProofRequirementFormValues.KBA}
                        data-automation-id="kba-proof-checkbox"
                        {...register(PROOF_REQUIREMENT_FIELD_NAME, {
                          required: intl.formatMessage(MESSAGES.PROOF_REQUIREMENT_ERROR),
                        })}
                      />
                    }
                  />
                )}
              </CheckboxGroup>
            </DeprecatedFormRow>
          </>
        )}
      </>
    </FormCard>
  );
}

type SignerField = { maxSigners: string; minSigners: string };
export function MultiSignerSelection<FormFields extends SignerField>({
  form: untypedFormReturn,
  signerFields,
  templateId,
  isEditing,
}: {
  form: UseFormReturn<FormFields>;
  signerFields?: { canShowWarning: boolean; count: number };
  templateId?: string;
  isEditing?: boolean;
}) {
  // https://github.com/react-hook-form/react-hook-form/issues/6726
  const form = untypedFormReturn as unknown as UseFormReturn<SignerField>;
  const { register, setValue } = form;
  const minSigners = form.watch("minSigners");
  const maxSigners = form.watch("maxSigners");

  const intMinValue = parseInt(minSigners, 10);
  const intMaxValue = parseInt(maxSigners, 10);

  const minSelectId = useId();
  const maxSelectId = useId();

  const [currentTemplateId, setCurrentTemplateId] = useState(templateId);

  useEffect(() => {
    if (intMinValue > intMaxValue) {
      setValue("maxSigners", minSigners);
    }
  }, [minSigners]);

  useEffect(() => {
    if (currentTemplateId !== templateId) {
      setCurrentTemplateId(templateId);
    }
  }, [templateId]);

  const handleMinMaxSignerValues = useCallback(() => {
    if (signerFields) {
      const minMax = signerFields.count <= 1 ? "1" : signerFields.count.toString();
      setValue("maxSigners", minMax);
      setValue("minSigners", minMax);
    }
  }, [signerFields?.count]);

  useEffect(() => {
    if (isEditing) {
      if (currentTemplateId && currentTemplateId !== templateId) {
        handleMinMaxSignerValues();
      }
    } else {
      handleMinMaxSignerValues();
    }
  }, [signerFields?.count]);

  function getSelectItems(minValue: number = 1) {
    const items = [];
    // 10 is max signer limit
    for (let i = minValue; i <= 10; i++) {
      items.push({ label: i.toString(), value: i.toString() });
    }
    return items;
  }

  const showDesignationWarning = signerFields?.canShowWarning && intMaxValue > signerFields.count;

  return (
    <FormCard
      cardTitle={
        <FormattedMessage
          id="5dbf7352-ac44-4f45-a7a0-42ab88ccf6ce"
          defaultMessage="How many signers?"
        />
      }
      cardDescription={
        signerFields && (
          <FormattedMessage
            id="bd3d224b-e325-47e4-b8c1-340c8144078a"
            defaultMessage="The selected template contains fields for {count} {count, plural, one{signer} other{signers}}."
            values={{ count: signerFields.count }}
          />
        )
      }
      cardDescriptionTextStyle={signerFields && "subtle"}
    >
      <div className={Styles.selectContent}>
        <div className={Styles.selectGroup}>
          <Label className={Styles.selectLabel} htmlFor={minSelectId}>
            <FormattedMessage id="25ee57ac-cdc5-4937-9309-e7db869786e8" defaultMessage="Minimum" />
          </Label>
          <Select
            id={minSelectId}
            items={getSelectItems()}
            aria-invalid="false"
            {...register("minSigners")}
          />
        </div>
        <div className={Styles.selectGroup}>
          <Label className={Styles.selectLabel} htmlFor={maxSelectId}>
            <FormattedMessage id="79fc1294-9dae-4773-812a-d379ebbf8a6f" defaultMessage="Maximum" />
          </Label>
          <Select
            id={maxSelectId}
            items={getSelectItems(intMinValue)}
            aria-invalid="false"
            disabled={intMinValue === 10}
            {...register("maxSigners")}
          />
        </div>
      </div>
      {showDesignationWarning && (
        <AlertMessage kind="warning" className={Styles.designationWarning}>
          <FormattedMessage
            id="05fa3f96-df6e-49f4-ad81-97c845bd0016"
            defaultMessage="The minimum and/or maximum number of signers is greater than the amount of signer fields available on the template."
          />
        </AlertMessage>
      )}
    </FormCard>
  );
}

type CCRecipientField = { ccRecipients: { email: string }[] };
export function CCRecipients<FormFields extends CCRecipientField>({
  form: untypedFormReturn,
}: {
  form: UseFormReturn<FormFields>;
}) {
  // https://github.com/react-hook-form/react-hook-form/issues/6726
  const form = untypedFormReturn as unknown as UseFormReturn<CCRecipientField>;

  const intl = useIntl();

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "ccRecipients",
  });

  const maxCCRecipientsReached = fields.length === MAX_CC_RECIPIENTS;

  const addCCRecipient = () => {
    append({ email: "" });
  };

  return (
    <FormCard
      cardTitle={
        <FormattedMessage id="1246f6d9-3eb9-45e8-ad2c-ccd2cbe7ea77" defaultMessage="CC contacts" />
      }
      cardDescription={
        <FormattedMessage
          id="e7b5ae3e-f38c-4168-8264-41a550591fd8"
          defaultMessage="CC contacts will have access to the completed documents."
        />
      }
    >
      {!fields.length && (
        <Button
          onClick={addCCRecipient}
          data-pendo-id="add-cc-recipient-easylink"
          disabled={maxCCRecipientsReached}
          buttonColor="action"
          variant="secondary"
          withIcon={{ placement: "left", name: "add" }}
        >
          <FormattedMessage
            id="6b7dcc39-2b6b-4ee2-adb3-8ae2a6f31f91"
            defaultMessage="Add CC contact"
          />
        </Button>
      )}
      {fields.map((ccRecipient, i) => {
        return (
          <div className={Styles.emailFieldWrapper} key={ccRecipient.id}>
            <AutomaticFormRow
              required
              label={intl.formatMessage(MESSAGES.EMAIL)}
              aria-label={intl.formatMessage(MESSAGES.EMAIL_ARIA_LABEL, {
                number: (i + 1).toString(),
              })}
              fullWidth
              form={form}
              name={`ccRecipients.${i}.email`}
              registerOptions={{
                required: defaultRequiredMessage(intl),
                pattern: emailPatternValidation(intl),
                validate: {
                  validateDuplicateEmails: (value, formValues) => {
                    if (!value) {
                      return true;
                    }

                    const emailValue = value as string;

                    const hasDuplicates = formValues.ccRecipients.some((recip, index) => {
                      return (
                        recip.email.trim().toLowerCase() === emailValue.trim().toLowerCase() &&
                        index !== i
                      );
                    });

                    if (hasDuplicates) {
                      return intl.formatMessage(MESSAGES.DUPLICATE_EMAIL_ERROR);
                    }
                    return true;
                  },
                },
              }}
              as={EmailTextInput}
            />

            <Button
              className={Styles.removeEmailButton}
              data-pendo-id="remove-cc-recipient-easylink"
              buttonSize="condensed"
              buttonColor="danger"
              variant="tertiary"
              withIcon={{ name: "delete", placement: "left" }}
              onClick={() => remove(i)}
              aria-label={intl.formatMessage(MESSAGES.REMOVE_CC_RECIPIENT, { number: i + 1 })}
            >
              <FormattedMessage id="c531a382-89dd-4566-9e54-14e44a57ac3d" defaultMessage="Remove" />
            </Button>
          </div>
        );
      })}

      {Boolean(fields.length) && (
        <Button
          disabled={maxCCRecipientsReached}
          disabledHint={
            <FormattedMessage
              id="07288bbe-2843-473c-834e-6615e010e3c2"
              defaultMessage="You have reached the maximum number of CC contacts allowed."
            />
          }
          buttonSize="condensed"
          buttonColor="action"
          variant="tertiary"
          onClick={addCCRecipient}
          data-pendo-id="add-additional-cc-recipient-easylink"
          withIcon={{ name: "add", placement: "left" }}
        >
          <FormattedMessage
            id="0f7364dd-17f7-4748-8a21-7b4f77099380"
            defaultMessage="Add additional CC contact"
          />
        </Button>
      )}
    </FormCard>
  );
}

export function useIdentifyEasylinksEnabled(
  notaryProfile: undefined | Parameters<typeof isNotaryNST>[0],
) {
  const flagEnabled = useFeatureFlag("identify-easylinks");
  const proofFlagEnabled = useFeatureFlag(PROOF_TRANSACTIONS);
  if (notaryProfile === undefined) {
    // return false while data not loaded
    return false;
  }
  return flagEnabled && proofFlagEnabled && !isNotaryNST(notaryProfile);
}

export type OrganizationPayerField = { payer: OrganizationApiKeyPayer | undefined };
export function OrganizationPayer<FormFields extends OrganizationPayerField>(props: {
  organization: PayerOrganization;
  form: UseFormReturn<FormFields>;
  cardDescription: ReactNode;
}) {
  // https://github.com/react-hook-form/react-hook-form/issues/6726
  const form = props.form as unknown as UseFormReturn<OrganizationPayerField>;
  form.register("payer", { required: true });
  const payer = form.watch("payer");

  const canPay = props.organization.chargeablePaymentMethod;

  // by default, payer is undefined
  // if payment is set up it will change payer to org
  // if not set up, it will remain as undefined which will trigger form error on submit
  useEffect(() => {
    if (!payer && canPay) {
      form.setValue("payer", OrganizationApiKeyPayer.ORGANIZATION);
    }
  }, [canPay]);

  return (
    <FormCard
      cardTitle={
        <FormattedMessage id="3043db85-4626-4d2e-b3cb-8afcf8d6b0b0" defaultMessage="Payment" />
      }
      cardDescription={props.cardDescription}
      errorMessage={
        !canPay &&
        form.formState.errors.payer && (
          <FormattedMessage
            id="f7a929a2-5f95-424f-b2c7-b78f40cba212"
            defaultMessage="Add a payment method to generate this EasyLink."
          />
        )
      }
    >
      {!canPay && (
        <AlertMessage kind="warning">
          <AddPaymentMethodWarning
            render={({ addPayment }) => (
              <FormattedMessage
                id="b23405f2-da6e-494c-b0e9-1b872b54cc60"
                defaultMessage="Your account does not have a payment method configured.{br}<addPaymentButton>Add a payment method</addPaymentButton> to generate this EasyLink."
                values={{
                  addPaymentButton: (text) => (
                    <LinkStyledButton onClick={addPayment} black>
                      {text}
                    </LinkStyledButton>
                  ),
                  br: <br />,
                }}
              />
            )}
          />
        </AlertMessage>
      )}
    </FormCard>
  );
}
