import "./meeting_notification.scss";

import { useState, useEffect, useCallback, type ReactNode } from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { EMPTY, timer, mergeWith, first, type Observable } from "rxjs";
import { FormattedMessage } from "react-intl";

import store from "redux/store";
import { removeNotification } from "redux/actions/notifications"; // eslint-disable-line no-restricted-imports
import { TOAST_TIME } from "constants/notifications";

import { IconButton } from "../button/icon_button";

type Props = {
  uid: string;
  title?: ReactNode;
  message: ReactNode;
  submessage?: ReactNode;
  duration?: number;
  link?: string;
  handleSubmessageEvent?: () => void;
  removeSignal$?: Observable<void>;
};

export default function MeetingNotification({
  uid,
  title,
  message,
  submessage,
  duration = 5000,
  link,
  handleSubmessageEvent,
  removeSignal$ = EMPTY,
}: Props) {
  const [toastingDown, setToastingDown] = useState(false);

  const remove = useCallback(() => setToastingDown(true), []);

  useEffect(() => {
    const sub = removeSignal$
      .pipe(mergeWith(duration > 0 ? timer(duration) : EMPTY), first())
      .subscribe(remove);

    return () => sub.unsubscribe();
  }, [removeSignal$]);

  useEffect(() => {
    toastingDown && setTimeout(() => store.dispatch(removeNotification(uid)), TOAST_TIME);
  }, [toastingDown]);

  return (
    <li
      className={classnames("MeetingNotification", {
        toastDown: toastingDown,
        toastUp: !toastingDown,
      })}
    >
      <div className="MeetingNotification--body">
        {title && (
          <span className="MeetingNotification--title">
            {link ? <Link to={link}>{title}</Link> : title}
          </span>
        )}
        <span className="MeetingNotification--message">{message}</span>
        {submessage && (
          <Link
            className="MeetingNotification--submessage"
            onClick={(evt) => {
              evt.preventDefault();
              handleSubmessageEvent?.();
            }}
            to="/meeting/navigation-submessage"
          >
            {submessage}
          </Link>
        )}
      </div>
      <div className="MeetingNotification--icon MeetingNotification--icon--close">
        <IconButton
          className="icon"
          variant="tertiary"
          buttonColor="light"
          buttonSize="condensed"
          automationId="meeting-notification-close-btn"
          label={
            <FormattedMessage
              id="8fb6ed67-8f58-4f35-bd2f-a9cc9566185c"
              defaultMessage="Close notification"
            />
          }
          name="x"
          onClick={remove}
        />
      </div>
    </li>
  );
}
