import { FormattedMessage } from "react-intl";

import type { DocumentBundleMembershipRole } from "graphql_globals";
import {
  ANNOTATION_SUBTYPES,
  ANNOTATION_TYPES,
  PLACEMENT_TYPE,
  DESIGNATION_ROLES,
  UNASSIGNED_SIGNER_INDEX,
} from "constants/annotations";
import { userFullName } from "util/user";
import { getDayMonthYear } from "common/pdf/util";

type Options = { shortcutKey?: string; primaryDesignationId?: string };
type OptionsWithUserId = Options & { userId: string };
type SignerRole = { index: string; role: DocumentBundleMembershipRole };

export type AnnotationToolData =
  | ReturnType<typeof createSignatureAnnotationToolData>
  | ReturnType<typeof createInitialsAnnotationToolData>
  | ReturnType<typeof createDateAnnotationToolData>
  | ReturnType<typeof createNameAnnotationToolData>
  | ReturnType<typeof createFirstNameAnnotationToolData>
  | ReturnType<typeof createLastNameAnnotationToolData>
  | ReturnType<typeof createTextAnnotationToolData>
  | ReturnType<typeof createRepresentativeOfNameAnnotationToolData>
  | ReturnType<typeof createRepresentativeCapacityTypeAnnotationToolData>
  | ReturnType<typeof createCheckmarkAnnotationToolData>
  | ReturnType<typeof createWhiteoutAnnotationToolData>
  | ReturnType<typeof createNaAnnotationToolData>
  | ReturnType<typeof createEmailAnnotationToolData>;

export type AssetAnnotationToolData =
  | ReturnType<typeof createSignatureAnnotationToolData>
  | ReturnType<typeof createInitialsAnnotationToolData>;

export function createSignatureAnnotationToolData({ userId, shortcutKey }: OptionsWithUserId) {
  return {
    label: (
      <FormattedMessage id="7dc15d5f-96e9-4ba3-abd8-7ed85592f9fe" defaultMessage="Signature" />
    ),
    hideIconOnMobile: false,
    hideLabelOnMobile: false,
    iconName: "sign",
    automationId: "tool-annotation-signature",
    shortcutKey,
    data: {
      id: "tool-annotation-signature",
      type: ANNOTATION_TYPES.VECTOR_GRAPHIC,
      subtype: ANNOTATION_SUBTYPES.SIGNATURE,
      placementType: PLACEMENT_TYPE.ANNOTATION,
      userId,
    },
  };
}

export function createInitialsAnnotationToolData({ userId, shortcutKey }: OptionsWithUserId) {
  return {
    label: <FormattedMessage id="f11d28f6-15d6-406c-b83c-a4f444daddf3" defaultMessage="Initials" />,
    iconName: "initials",
    hideIconOnMobile: false,
    hideLabelOnMobile: false,
    automationId: "tool-annotation-initials",
    shortcutKey,
    data: {
      id: "tool-annotation-initials",
      type: ANNOTATION_TYPES.VECTOR_GRAPHIC,
      subtype: ANNOTATION_SUBTYPES.INITIALS,
      placementType: PLACEMENT_TYPE.ANNOTATION,
      userId,
    },
  };
}

export function createDateAnnotationToolData(opts?: Options) {
  return {
    label: new Date().toLocaleDateString(undefined, {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    }),
    hideIconOnMobile: false,
    hideLabelOnMobile: false,
    iconName: "calendar-filled",
    automationId: "tool-annotation-date",
    shortcutKey: opts?.shortcutKey,
    data: {
      id: "tool-annotation-date",
      type: ANNOTATION_TYPES.TEXT,
      subtype: ANNOTATION_SUBTYPES.DATE,
      placementType: PLACEMENT_TYPE.ANNOTATION,
    },
  };
}

export function createNameAnnotationToolData({
  firstName,
  middleName,
  lastName,
  signerRole,
  shortcutKey,
}: Options & {
  firstName: string | null;
  lastName: string | null;
  middleName: string | null;
  signerRole: SignerRole;
}) {
  return {
    label: userFullName({ firstName, middleName, lastName }),
    iconName: "name",
    hideIconOnMobile: true,
    hideLabelOnMobile: false,
    automationId: "tool-annotation-name",
    shortcutKey,
    data: {
      id: "tool-annotation-name",
      type: ANNOTATION_TYPES.TEXT,
      subtype: ANNOTATION_SUBTYPES.NAME,
      placementType: PLACEMENT_TYPE.ANNOTATION,
      contactInformation: { firstName, middleName, lastName },
      signerRole,
    },
  };
}

export function createNameDesignationToolData({
  signerRole,
  shortcutKey,
}: Options & {
  signerRole: SignerRole;
  shortcutKey: string;
}) {
  return {
    label: (
      <FormattedMessage id="77d858b3-4b07-4c54-bea7-2de14cc03850" defaultMessage="Full name" />
    ),
    iconName: "name",
    hideIconOnMobile: true,
    hideLabelOnMobile: false,
    automationId: "tool-designation-name",
    shortcutKey,
    data: {
      type: ANNOTATION_TYPES.TEXT,
      subtype: ANNOTATION_SUBTYPES.NAME,
      placementType: PLACEMENT_TYPE.DESIGNATION,
      signerRole,
    },
  };
}

export function createFirstNameAnnotationToolData({
  firstName,
  shortcutKey,
}: Options & { firstName: string | null }) {
  return {
    label: firstName,
    iconName: "name-first",
    hideIconOnMobile: true,
    hideLabelOnMobile: false,
    automationId: "tool-annotation-first-name",
    shortcutKey,
    data: {
      id: "tool-annotation-first-name",
      type: ANNOTATION_TYPES.TEXT,
      subtype: ANNOTATION_SUBTYPES.FIRST_NAME,
      placementType: PLACEMENT_TYPE.ANNOTATION,
      contactInformation: { firstName },
    },
  };
}

export function createLastNameAnnotationToolData({
  lastName,
  shortcutKey,
}: Options & { lastName: string | null }) {
  return {
    label: lastName,
    iconName: "name-last",
    hideIconOnMobile: true,
    hideLabelOnMobile: false,
    automationId: "tool-annotation-last-name",
    shortcutKey,
    data: {
      id: "tool-annotation-last-name",
      type: ANNOTATION_TYPES.TEXT,
      subtype: ANNOTATION_SUBTYPES.LAST_NAME,
      placementType: PLACEMENT_TYPE.ANNOTATION,
      contactInformation: { lastName },
    },
  };
}

export function createTextAnnotationToolData(opts?: Options) {
  return {
    label: <FormattedMessage id="77d858b3-4b07-4c54-bea7-2de14cc03850" defaultMessage="Text box" />,
    iconName: "text-box",
    hideIconOnMobile: false,
    hideLabelOnMobile: false,
    automationId: "tool-annotation-text",
    shortcutKey: opts?.shortcutKey,
    data: {
      id: "tool-annotation-text",
      type: ANNOTATION_TYPES.TEXT,
      subtype: ANNOTATION_SUBTYPES.FREE_TEXT,
      placementType: PLACEMENT_TYPE.ANNOTATION,
    },
  };
}

export function createRepresentativeOfNameAnnotationToolData({
  representativeOf,
  sublabel,
}: Options & { representativeOf: string | null; sublabel: string | null }) {
  return {
    label: representativeOf,
    sublabel,
    iconName: "business",
    hideIconOnMobile: false,
    hideLabelOnMobile: false,
    automationId: "tool-annotation-representative-of-name",
    data: {
      id: "tool-annotation-representative-of-name",
      type: ANNOTATION_TYPES.TEXT,
      subtype: ANNOTATION_SUBTYPES.NAME,
      placementType: PLACEMENT_TYPE.ANNOTATION,
      contactInformation: { firstName: representativeOf },
    },
  };
}

export function createRepresentativeCapacityTypeAnnotationToolData({
  capacity,
  sublabel,
}: Options & { capacity: string | null; sublabel: string | null }) {
  return {
    label: capacity,
    sublabel,
    iconName: "briefcase",
    hideIconOnMobile: false,
    hideLabelOnMobile: false,
    automationId: "tool-annotation-representative-capacity-type",
    data: {
      id: "tool-annotation-representative-capacity-type",
      type: ANNOTATION_TYPES.TEXT,
      subtype: ANNOTATION_SUBTYPES.REPRESENTATIVE_CAPACITY_TYPE,
      placementType: PLACEMENT_TYPE.ANNOTATION,
      contactInformation: { capacity },
    },
  };
}

export function createCheckmarkAnnotationToolData(opts?: Options) {
  return {
    label: (
      <FormattedMessage id="62dd3681-1a28-487a-954f-4d7baa1dc270" defaultMessage="Checkmark" />
    ),
    hideIconOnMobile: false,
    hideLabelOnMobile: true,
    iconName: "tick",
    automationId: "tool-annotation-checkmark",
    shortcutKey: opts?.shortcutKey,
    data: {
      id: "tool-annotation-checkmark",
      type: ANNOTATION_TYPES.CHECKMARK,
      placementType: PLACEMENT_TYPE.ANNOTATION,
    },
  };
}

export function createWhiteoutAnnotationToolData(opts?: Options) {
  return {
    label: <FormattedMessage id="c0fef1ff-c87d-4e0b-965a-36e62fbfa627" defaultMessage="Whiteout" />,
    iconName: "white-out",
    hideIconOnMobile: false,
    hideLabelOnMobile: false,
    automationId: "tool-annotation-whiteout",
    shortcutKey: opts?.shortcutKey,
    data: {
      id: "tool-annotation-whiteout",
      type: ANNOTATION_TYPES.WHITEBOX,
      placementType: PLACEMENT_TYPE.ANNOTATION,
    },
  };
}

export function createNaAnnotationToolData(opts?: Options) {
  return {
    label: <FormattedMessage id="c723c25e-e4e9-4927-b9a2-3070e4b5a1af" defaultMessage="N/A" />,
    iconName: "text-box",
    hideIconOnMobile: true,
    hideLabelOnMobile: false,
    automationId: "tool-annotation-na",
    shortcutKey: opts?.shortcutKey,
    data: {
      id: "tool-annotation-na",
      type: ANNOTATION_TYPES.TEXT,
      subtype: ANNOTATION_SUBTYPES.NA,
      placementType: PLACEMENT_TYPE.ANNOTATION,
    },
  };
}

export function createEmailAnnotationToolData({
  email,
  shortcutKey,
}: Options & { email: string | null }) {
  return {
    label: email,
    iconName: "email",
    hideIconOnMobile: true,
    hideLabelOnMobile: false,
    automationId: "tool-annotation-email",
    shortcutKey,
    data: {
      id: "tool-annotation-email",
      type: ANNOTATION_TYPES.TEXT,
      subtype: ANNOTATION_SUBTYPES.EMAIL,
      placementType: PLACEMENT_TYPE.ANNOTATION,
      contactInformation: { email },
    },
  };
}

export function createSignatureDesignationToolData({
  signerRole,
  shortcutKey,
  primaryDesignationId,
}: Options & { signerRole: SignerRole }) {
  return {
    label: (
      <FormattedMessage id="b5648370-4d1d-41b3-9b29-33685d06b276" defaultMessage="Sign here" />
    ),
    iconName: "sign",
    automationId: "tool-designation-signature",
    shortcutKey,
    data: {
      type: ANNOTATION_TYPES.VECTOR_GRAPHIC,
      subtype: ANNOTATION_SUBTYPES.SIGNATURE,
      placementType: PLACEMENT_TYPE.DESIGNATION,
      signerRole,
      primaryDesignationId,
    },
  };
}

export function createInitialsDesignationToolData({
  signerRole,
  shortcutKey,
  primaryDesignationId,
}: Options & { signerRole: SignerRole }) {
  return {
    label: (
      <FormattedMessage id="88976191-3075-4439-b5ac-cd5047afbd43" defaultMessage="Initial here" />
    ),
    iconName: "initials",
    automationId: "tool-designation-initials",
    shortcutKey,
    data: {
      type: ANNOTATION_TYPES.VECTOR_GRAPHIC,
      subtype: ANNOTATION_SUBTYPES.INITIALS,
      placementType: PLACEMENT_TYPE.DESIGNATION,
      signerRole,
      primaryDesignationId,
    },
  };
}

export function createMultisignerInitialsDesignationToolData({
  signerRole,
  shortcutKey,
}: Options & { signerRole: SignerRole }) {
  return {
    label: (
      <FormattedMessage
        id="e4df96c1-6e8a-47a3-9636-2e55cc721bc0"
        defaultMessage="Multisigner Initial Here"
      />
    ),
    iconName: "initials",
    automationId: "tool-designation-multisigner-initials",
    shortcutKey,
    data: {
      type: ANNOTATION_TYPES.VECTOR_GRAPHIC,
      subtype: ANNOTATION_SUBTYPES.MULTISIGNER_INITIALS,
      placementType: PLACEMENT_TYPE.DESIGNATION,
      signerRole,
    },
  };
}

export function createDateDesignationToolData({
  signerRole,
  shortcutKey,
  primaryDesignationId,
}: Options & { signerRole: SignerRole }) {
  return {
    label: (
      <FormattedMessage id="6b776f85-6c28-4154-91ab-c2443ce17916" defaultMessage="Date signed" />
    ),
    iconName: "calendar-filled",
    automationId: "tool-designation-date",
    shortcutKey,
    data: {
      type: ANNOTATION_TYPES.TEXT,
      subtype: ANNOTATION_SUBTYPES.DATE_SIGNED,
      placementType: PLACEMENT_TYPE.DESIGNATION,
      signerRole,
      primaryDesignationId,
    },
  };
}

export function createSignatureDateDesignationToolData({
  signerRole,
  shortcutKey,
  primaryDesignationId,
}: Options & { signerRole: SignerRole }) {
  return {
    label: (
      <FormattedMessage
        id="97d0cc53-23f0-4e1f-be7b-7516f9fb92a2"
        defaultMessage="Signature & date"
      />
    ),
    iconName: "combo",
    automationId: "tool-designation-signature-date",
    shortcutKey,
    data: {
      type: ANNOTATION_TYPES.COMPOUND,
      subtype: ANNOTATION_SUBTYPES.SIGNATURE_DATE,
      placementType: PLACEMENT_TYPE.DESIGNATION,
      signerRole,
      primaryDesignationId,
    },
  };
}

export function createDayMonthYearDesignationToolData({
  signerRole,
  shortcutKey,
  primaryDesignationId,
}: Options & { signerRole: SignerRole }) {
  return {
    label: (
      <FormattedMessage id="193accf9-73a2-489d-852f-7784af77d662" defaultMessage="Day Month Year" />
    ),
    iconName: "calendar-filled",
    automationId: "tool-designation-day-month-year",
    shortcutKey,
    data: {
      type: ANNOTATION_TYPES.COMPOUND,
      subtype: ANNOTATION_SUBTYPES.DAY_MONTH_YEAR,
      placementType: PLACEMENT_TYPE.DESIGNATION,
      signerRole,
      primaryDesignationId,
    },
  };
}

export function createDayMonthYearAnnotationToolData(opts?: Options) {
  return {
    label: getDayMonthYear().join(" "),
    hideIconOnMobile: false,
    hideLabelOnMobile: false,
    iconName: "calendar-filled",
    automationId: "tool-annotation-day-month-year",
    shortcutKey: opts?.shortcutKey,
    data: {
      type: ANNOTATION_TYPES.TEXT,
      subtype: ANNOTATION_SUBTYPES.DAY_MONTH_YEAR,
      placementType: PLACEMENT_TYPE.ANNOTATION,
    },
  };
}

export function createTextDesignationToolData({
  signerRole,
  shortcutKey,
  primaryDesignationId,
}: Options & { signerRole: SignerRole }) {
  return {
    label: (
      <FormattedMessage id="ae58542b-24f9-42fd-823e-1627214f102c" defaultMessage="Fill in here" />
    ),
    iconName: "text-box",
    automationId: "tool-designation-text",
    shortcutKey,
    data: {
      type: ANNOTATION_TYPES.TEXT,
      subtype: ANNOTATION_SUBTYPES.FREE_TEXT,
      placementType: PLACEMENT_TYPE.DESIGNATION,
      signerRole,
      primaryDesignationId,
    },
  };
}

export function createCheckmarkDesignationToolData({
  signerRole,
  shortcutKey,
  primaryDesignationId,
}: Options & { signerRole: SignerRole }) {
  return {
    label: <FormattedMessage id="25e5d472-c26c-4383-83eb-073fe216cc0b" defaultMessage="Select" />,
    iconName: "select",
    automationId: "tool-designation-checkmark",
    shortcutKey,
    data: {
      type: ANNOTATION_TYPES.CHECKMARK,
      subtype: ANNOTATION_SUBTYPES.CHECKMARK,
      placementType: PLACEMENT_TYPE.DESIGNATION,
      signerRole,
      primaryDesignationId,
    },
  };
}

export function createRadioDesignationToolData({
  signerRole,
  shortcutKey,
  primaryDesignationId,
}: Options & { signerRole: SignerRole }) {
  return {
    label: (
      <FormattedMessage id="41900974-4ab9-4f03-8581-8f54ee0e7164" defaultMessage="Radio group" />
    ),
    iconName: "radio-circle",
    automationId: "tool-designation-radio",
    shortcutKey,
    data: {
      type: ANNOTATION_TYPES.CHECKMARK,
      subtype: ANNOTATION_SUBTYPES.RADIO_CHECKMARK,
      placementType: PLACEMENT_TYPE.DESIGNATION,
      signerRole,
      primaryDesignationId,
    },
  };
}

export function createNotarySealDesignationToolData(opts?: Options) {
  return {
    label: (
      <FormattedMessage id="aa5b1998-da3e-40d3-84e3-b835ee5a74b5" defaultMessage="Seal here" />
    ),
    iconName: "notarize-line",
    automationId: "tool-designation-notary-seal",
    shortcutKey: opts?.shortcutKey,
    data: {
      type: ANNOTATION_TYPES.IMAGE,
      subtype: ANNOTATION_SUBTYPES.SEAL,
      placementType: PLACEMENT_TYPE.DESIGNATION,
      signerRole: {
        role: DESIGNATION_ROLES.NOTARY,
        index: UNASSIGNED_SIGNER_INDEX.NOTARY,
      },
    },
  };
}

export function createNotaryBulkDesignationToolData(opts?: Options) {
  return {
    label: <FormattedMessage id="6a94c8d7-a7a0-4834-8d8f-403f57708a8f" defaultMessage="Bulk " />,
    iconName: "notaries",
    automationId: "tool-designation-notary-bulk",
    shortcutKey: opts?.shortcutKey,
    data: {
      type: ANNOTATION_TYPES.COMPOUND,
      subtype: ANNOTATION_SUBTYPES.NOTARY_BULK,
      placementType: PLACEMENT_TYPE.DESIGNATION,
      signerRole: {
        role: DESIGNATION_ROLES.NOTARY,
        index: UNASSIGNED_SIGNER_INDEX.NOTARY,
      },
    },
  };
}

export function createNotarySignatureDesignationToolData(opts?: Options) {
  return {
    label: (
      <FormattedMessage id="140cc495-8311-41fe-b075-a322da55762e" defaultMessage="Sign here" />
    ),
    iconName: "sign",
    automationId: "tool-designation-notary-signature",
    shortcutKey: opts?.shortcutKey,
    data: {
      type: ANNOTATION_TYPES.VECTOR_GRAPHIC,
      subtype: ANNOTATION_SUBTYPES.NOTARY_SIGNATURE,
      placementType: PLACEMENT_TYPE.DESIGNATION,
      signerRole: {
        role: DESIGNATION_ROLES.NOTARY,
        index: UNASSIGNED_SIGNER_INDEX.NOTARY,
      },
    },
  };
}

export function createNotaryStateDesignationToolData(opts?: Options) {
  return {
    label: <FormattedMessage id="f49f36f4-bd91-491c-bc60-3f7a3bb0b37a" defaultMessage="State" />,
    iconName: "state",
    automationId: "tool-designation-notary-state",
    shortcutKey: opts?.shortcutKey,
    data: {
      type: ANNOTATION_TYPES.TEXT,
      subtype: ANNOTATION_SUBTYPES.STATE,
      placementType: PLACEMENT_TYPE.DESIGNATION,
      signerRole: {
        role: DESIGNATION_ROLES.NOTARY,
        index: UNASSIGNED_SIGNER_INDEX.NOTARY,
      },
    },
  };
}

export function createNotaryCountyDesignationToolData(opts?: Options) {
  return {
    label: <FormattedMessage id="a9eb4250-6d62-4113-aafc-3ee7607b4508" defaultMessage="County" />,
    iconName: "county",
    automationId: "tool-designation-notary-county",
    shortcutKey: opts?.shortcutKey,
    data: {
      type: ANNOTATION_TYPES.TEXT,
      subtype: ANNOTATION_SUBTYPES.COUNTY,
      placementType: PLACEMENT_TYPE.DESIGNATION,
      signerRole: {
        role: DESIGNATION_ROLES.NOTARY,
        index: UNASSIGNED_SIGNER_INDEX.NOTARY,
      },
    },
  };
}

export function createNotaryNameDesignationToolData(opts?: Options) {
  return {
    label: <FormattedMessage id="a766632d-bba5-45e1-94e5-4cebfb2444f8" defaultMessage="Name" />,
    iconName: "name",
    automationId: "tool-designation-notary-name",
    shortcutKey: opts?.shortcutKey,
    data: {
      type: ANNOTATION_TYPES.TEXT,
      subtype: ANNOTATION_SUBTYPES.NOTARY_NAME,
      placementType: PLACEMENT_TYPE.DESIGNATION,
      signerRole: {
        role: DESIGNATION_ROLES.NOTARY,
        index: UNASSIGNED_SIGNER_INDEX.NOTARY,
      },
    },
  };
}

export function createNotaryDayMonthYearDesignationToolData(opts?: Options) {
  return {
    label: (
      <FormattedMessage id="193accf9-73a2-489d-852f-7784af77d662" defaultMessage="Day Month Year" />
    ),
    iconName: "calendar-filled",
    automationId: "tool-designation-day-month-year",
    shortcutKey: opts?.shortcutKey,
    data: {
      type: ANNOTATION_TYPES.COMPOUND,
      subtype: ANNOTATION_SUBTYPES.DAY_MONTH_YEAR,
      placementType: PLACEMENT_TYPE.DESIGNATION,
      signerRole: { role: DESIGNATION_ROLES.NOTARY, index: UNASSIGNED_SIGNER_INDEX.NOTARY },
    },
  };
}

export function createNotaryExpiryDesignationToolData(opts?: Options) {
  return {
    label: (
      <FormattedMessage
        id="6ff76a2f-26b9-4fd3-a00b-c4197b2c6a8d"
        defaultMessage="Commission expiry"
      />
    ),
    iconName: "clock",
    automationId: "tool-designation-notary-expiry",
    shortcutKey: opts?.shortcutKey,
    data: {
      type: ANNOTATION_TYPES.TEXT,
      subtype: ANNOTATION_SUBTYPES.COMMISSION_EXPIRY,
      placementType: PLACEMENT_TYPE.DESIGNATION,
      signerRole: {
        role: DESIGNATION_ROLES.NOTARY,
        index: UNASSIGNED_SIGNER_INDEX.NOTARY,
      },
    },
  };
}

export function createNotaryIdDesignationToolData(opts?: Options) {
  return {
    label: (
      <FormattedMessage id="047b464b-f5d2-4d3d-9ed7-acd8abdaeb2d" defaultMessage="ID number" />
    ),
    iconName: "id",
    automationId: "tool-designation-notary-id",
    shortcutKey: opts?.shortcutKey,
    data: {
      type: ANNOTATION_TYPES.TEXT,
      subtype: ANNOTATION_SUBTYPES.NOTARY_ID,
      placementType: PLACEMENT_TYPE.DESIGNATION,
      signerRole: {
        role: DESIGNATION_ROLES.NOTARY,
        index: UNASSIGNED_SIGNER_INDEX.NOTARY,
      },
    },
  };
}

export function createNotaryDateDesignationToolData(opts?: Options) {
  return {
    label: (
      <FormattedMessage id="f5fec199-35b0-4b71-ab08-8afb7a382224" defaultMessage="Date signed" />
    ),
    iconName: "calendar-filled",
    automationId: "tool-designation-notary-date",
    shortcutKey: opts?.shortcutKey,
    data: {
      type: ANNOTATION_TYPES.TEXT,
      subtype: ANNOTATION_SUBTYPES.DATE_SIGNED,
      placementType: PLACEMENT_TYPE.DESIGNATION,
      signerRole: {
        role: DESIGNATION_ROLES.NOTARY,
        index: UNASSIGNED_SIGNER_INDEX.NOTARY,
      },
    },
  };
}

export function createNotaryTextDesignationToolData(opts?: Options) {
  return {
    label: (
      <FormattedMessage id="f23ae3fa-6c84-4657-b6fc-b1e19b137700" defaultMessage="Fill in here" />
    ),
    iconName: "text-box",
    automationId: "tool-designation-notary-text",
    shortcutKey: opts?.shortcutKey,
    data: {
      type: ANNOTATION_TYPES.TEXT,
      subtype: ANNOTATION_SUBTYPES.FREE_TEXT,
      placementType: PLACEMENT_TYPE.DESIGNATION,
      signerRole: {
        role: DESIGNATION_ROLES.NOTARY,
        index: UNASSIGNED_SIGNER_INDEX.NOTARY,
      },
    },
  };
}

export function createNotaryCheckmarkDesignationToolData(opts?: Options) {
  return {
    label: <FormattedMessage id="379f3170-0da8-42ac-bb8f-1441fa4bca02" defaultMessage="Select" />,
    iconName: "select",
    automationId: "tool-designation-checkmark",
    shortcutKey: opts?.shortcutKey,
    data: {
      type: ANNOTATION_TYPES.CHECKMARK,
      subtype: ANNOTATION_SUBTYPES.CHECKMARK,
      placementType: PLACEMENT_TYPE.DESIGNATION,
      signerRole: {
        role: DESIGNATION_ROLES.NOTARY,
        index: UNASSIGNED_SIGNER_INDEX.NOTARY,
      },
    },
  };
}

export function createNotaryDisclosureDesignationToolData(opts?: Options) {
  return {
    label: (
      <FormattedMessage id="df3395af-3a4c-4370-8f90-bed34505a96f" defaultMessage="Disclosure" />
    ),
    iconName: "text-box",
    automationId: "tool-designation-notary-disclosure",
    shortcutKey: opts?.shortcutKey,
    data: {
      type: ANNOTATION_TYPES.TEXT,
      subtype: ANNOTATION_SUBTYPES.DISCLOSURE,
      placementType: PLACEMENT_TYPE.DESIGNATION,
      signerRole: {
        role: DESIGNATION_ROLES.NOTARY,
        index: UNASSIGNED_SIGNER_INDEX.NOTARY,
      },
    },
  };
}
