import { memo } from "react";
import { FormattedMessage } from "react-intl";

import { AnnotationSubtype } from "graphql_globals";

import Tool from "./tool";
import Styles from "./tool.module.scss";

type ToolProps = {
  disabled?: boolean;
};

const TEXT_TOOL = {
  tool: AnnotationSubtype.FREE_TEXT,
  shortcutKey: "t",
  label: <FormattedMessage id="4709d112-8f62-4184-942c-ba90ebf56692" defaultMessage="Text" />,
  iconName: "text-box",
  automationId: "signer-toolbar-text",
  hideIconOnMobile: false,
  hideLabelOnMobile: false,
};

const CHECKMARK_TOOL = {
  tool: AnnotationSubtype.CHECKMARK,
  shortcutKey: "c",
  label: <FormattedMessage id="4709d112-8f62-4184-942c-ba90ebf56692" defaultMessage="Checkmark" />,
  iconName: "tick",
  automationId: "signer-toolbar-checkmark",
  hideIconOnMobile: false,
  hideLabelOnMobile: true,
};

const SIGNATURE_TOOL = {
  tool: AnnotationSubtype.SIGNATURE,
  shortcutKey: "s",
  label: <FormattedMessage id="4709d112-8f62-4184-942c-ba90ebf56692" defaultMessage="Signature" />,
  iconName: "pen-tip",
  automationId: "signer-toolbar-signature",
  hideIconOnMobile: false,
  hideLabelOnMobile: false,
};

const INITIALS_TOOL = {
  tool: AnnotationSubtype.INITIALS,
  shortcutKey: "i",
  label: <FormattedMessage id="4709d112-8f62-4184-942c-ba90ebf56692" defaultMessage="Initials" />,
  iconName: "initials",
  automationId: "signer-toolbar-initials",
  hideIconOnMobile: false,
  hideLabelOnMobile: false,
};

const TODAYS_DATE_TOOL = {
  tool: AnnotationSubtype.DATE_SIGNED,
  shortcutKey: "d",
  label: (
    <FormattedMessage id="4709d112-8f62-4184-942c-ba90ebf56692" defaultMessage="Today's date" />
  ),
  iconName: "toolbar-date",
  automationId: "signer-toolbar-date",
  hideIconOnMobile: false,
  hideLabelOnMobile: false,
};

const NA_TOOL = {
  tool: AnnotationSubtype.N_A,
  shortcutKey: "z",
  label: <FormattedMessage id="4709d112-8f62-4184-942c-ba90ebf56692" defaultMessage="N/A" />,
  iconName: "toolbar-na",
  automationId: "signer-toolbar-na",
  hideIconOnMobile: true,
  hideLabelOnMobile: false,
};

export const SIGNER_TOOLS_CONFIG = [
  SIGNATURE_TOOL,
  INITIALS_TOOL,
  TEXT_TOOL,
  CHECKMARK_TOOL,
  TODAYS_DATE_TOOL,
  NA_TOOL,
];

export const WITNESS_TOOLS_CONFIG = [SIGNATURE_TOOL, TEXT_TOOL];

function SignerTools(props: ToolProps) {
  return (
    <ul className={Styles.toolList}>
      {SIGNER_TOOLS_CONFIG.map((tool) => {
        return <Tool key={tool.tool} {...tool} disabled={props.disabled}></Tool>;
      })}
    </ul>
  );
}

const MemoizedSignerTools = memo(SignerTools);
export { MemoizedSignerTools as SignerTools };

function WitnessTools(props: ToolProps) {
  return (
    <ul className={Styles.toolList}>
      {WITNESS_TOOLS_CONFIG.map((tool) => {
        return <Tool key={tool.tool} {...tool} disabled={props.disabled}></Tool>;
      })}
    </ul>
  );
}

const MemoizedWitnessTools = memo(WitnessTools);
export { MemoizedWitnessTools as WitnessTools };
