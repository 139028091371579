import { useEffect, useState } from "react";
import { defineMessages, useIntl, FormattedMessage } from "react-intl";

import { captureException } from "util/exception";
import { TextInput } from "common/core/form/text";
import { isAriaInvalid } from "common/core/form/error";
import Button from "common/core/button";
import { PillsHeader, Pill } from "common/core/pill_tabs";
import { SettingsTitle } from "common/settingsv2/common";
import {
  AutomaticInvoiceType,
  AutomaticInvoiceType as PaymentFrequencyType,
  InvoiceProviderTypes,
  Payer,
} from "graphql_globals";
import { useMutation } from "util/graphql";
import { pushNotification } from "common/core/notification_center/actions";
import { Card, CardHeading, CardSection } from "common/core/card";
import { NOTIFICATION_SUBTYPES, NOTIFICATION_TYPES } from "constants/notifications";
import { useFeatureFlag } from "common/feature_gating";
import { RadioLabel, RadioGroup, RadioInput } from "common/core/form/option";
import { useForm } from "common/core/form";
import { EasylinksPaidBy } from "common/settingsv2/sidebar_settings/billing/payment_settings/easylinks_paid_by";
import { CollabFeeSetting } from "common/settingsv2/lender_admin/collab_fee_setting";

import Styles from "./index.module.scss";
import type { FullAdminOrganizationDetails_viewer as Viewer } from "../details_query.graphql";
import type { AdminCompanyDetailsOrganization_organization_Organization as Organization } from "../details/pricing_and_billing/organization_query.graphql";
import UpdateOrganizationPayerMutation from "./update_organization_payer_mutation.graphql";
import { NetsuiteTermsForm } from "./netsuite_terms";

type Props = {
  organization: Pick<
    Organization,
    | "id"
    | "billingEmail"
    | "defaultPayer"
    | "defaultInvoiceProvider"
    | "automaticInvoiceType"
    | "defaultPaymentSource"
    | "batchPayment"
  >;
  viewer: Pick<Viewer, "systemDefaultInvoiceProvider">;
  formDisabled: boolean;
};

enum InvoiceType {
  NONE = "NONE",
  OFF_PLATFORM = "OFF_PLATFORM",
  STRIPE = "STRIPE",
  NETSUITE = "NETSUITE",
}

type FormValues = {
  defaultPayer: Payer;
  defaultTransactionPayer: Payer;
  paymentFrequency: AutomaticInvoiceType;
  invoiceType: InvoiceType;
  billingEmail: undefined | null | string;
  defaultInvoiceProvider: undefined | null | InvoiceProviderTypes;
  automaticInvoiceType: AutomaticInvoiceType;
  batchPayment: boolean;
};

type BillingValues = {
  defaultTransactionPayer: Payer;
  usesAutomaticPayments: boolean;
  invoiceType: InvoiceType;
  paymentFrequency: PaymentFrequencyType;
};

type UpdatedBillingValues = {
  defaultPayer: Payer;
  defaultInvoiceProvider: InvoiceProviderTypes;
  automaticInvoiceType: AutomaticInvoiceType;
  batchPayment: boolean;
};

const messages = defineMessages({
  billingTitle: {
    id: "531b7e94-5447-4f1e-8617-bc61a658ddd5",
    defaultMessage: "Billing",
  },
  chargeOrgLabel: {
    id: "02d872b2-19b8-4f4c-a3eb-bc23fd5f7f75",
    defaultMessage: "Charge this organization",
  },
  chargeSignerLabel: {
    id: "ae0dfed8-7bfc-4f4e-903d-6775c12447a4",
    defaultMessage: "Charge signer",
  },
  chargeSignerSubtitle: {
    id: "b6129b5b-59d1-4dc8-88e8-daa0aa0bc297",
    defaultMessage:
      "If “Charge signer” is selected, signers are prompted for payment information when they complete each transaction.",
  },
  defaultPayer: {
    id: "72093f31-5e15-4621-9920-eedb9c45d54b",
    defaultMessage: "Default Payer",
  },
  defaultTransactionPayer: {
    id: "a8c93760-b89f-465d-a5cf-77e6cd8c2b5c",
    defaultMessage: "Default transaction payer",
  },
  billingEmail: {
    id: "f03a54ad-6c53-415e-bb36-b5f6a24bf661",
    defaultMessage: "Billing email",
  },
  defaultInvoiceProvider: {
    id: "a37dcffb-1cc0-4f75-b0e2-69d81082fb5b",
    defaultMessage: "Default Invoice Provider",
  },
  defaultInvoiceProviderPlaceholder: {
    id: "40e2762e-e87d-4566-9a3e-23cfaec0295d",
    defaultMessage: "{systemDefaultInvoiceProvider} (System Default)",
  },
  automaticInvoiceType: {
    id: "0349f65b-c1f5-4e49-b479-84cb8bf7dda3",
    defaultMessage: "Automatic invoice type",
  },
  creditCard: {
    id: "a8a5d52b-097b-4642-8652-83d3a15602ea",
    defaultMessage: "Credit card",
  },
  howOrganizationPays: {
    id: "ee5311ff-adeb-492f-a0fc-875c88030e81",
    defaultMessage: "How organization pays",
  },
  howOrganizationPaysHelperText: {
    id: "74679ae4-0fd7-46bc-927b-7abb32018708",
    defaultMessage:
      "If the default payer is signer, organization billing method will still be used for potential platform fees, minimums, and/or if a transaction charge needs to be paid by the organization.",
  },
  automaticPayments: {
    id: "59beb987-645c-43cf-9248-0be9322a8020",
    defaultMessage: "Automatically via credit card or ACH",
  },
  noAutomaticPayments: {
    id: "aca53649-f7cc-42c8-a017-9581d9899976",
    defaultMessage: "No automatic payments",
  },
  noInvoicesLabel: {
    id: "5a2a0bc4-72b8-43ab-ac95-1253f677deac",
    defaultMessage: "No automatic invoices",
  },
  stripeInvoicesLabel: {
    id: "66703b18-4347-4609-afc4-2ca5a5bc528a",
    defaultMessage: "Send platform-generated Stripe invoices",
  },
  netsuiteInvoicesLabel: {
    id: "717a64d4-ca5a-4976-aa20-4591fbca256d",
    defaultMessage: "Send platform-generated Netsuite invoices",
  },
  offPlatformInvoicesLabel: {
    id: "78135643-ea10-487d-8e9a-af845dfeb952",
    defaultMessage: "Manual by finance team",
  },
  save: {
    id: "5421d745-43e0-403d-bf2b-f1876d4b2f3e",
    defaultMessage: "Save",
  },
  saveChanges: {
    id: "c5e6881a-4834-4edb-953e-4a55843cabc6",
    defaultMessage: "Save changes",
  },
  saveSuccess: {
    id: "72f571f1-482a-4e43-8a74-4ac18f68a2ff",
    defaultMessage: "Billing setup saved successfully.",
  },
  saveFailure: {
    id: "efac0921-63ee-49a4-8b8a-55f4595389fc",
    defaultMessage: "Error saving billing setup.",
  },
  paymentFrequency: {
    id: "ed75c40d-cb2e-462d-8d31-4521628bdc9d",
    defaultMessage: "How frequently organization pays and/or receives invoices",
  },
  individualChargesLabel: {
    id: "5848b8f3-3eb2-45c0-b8ac-1779795b82d7",
    defaultMessage: "As individual charges occur",
  },
  batchesLabel: {
    id: "927e17d4-fb0e-4760-b163-112546903893",
    defaultMessage: "In batches",
  },
});

function BillingSetup(props: Props) {
  const {
    organization: {
      id,
      billingEmail,
      defaultPayer,
      defaultInvoiceProvider,
      automaticInvoiceType,
      defaultPaymentSource,
      batchPayment,
    },
    formDisabled,
  } = props;

  const intl = useIntl();
  const updateOrganizationPayerMutateFn = useMutation(UpdateOrganizationPayerMutation);
  const last4 = defaultPaymentSource?.__typename === "Card" && defaultPaymentSource.last4;
  const chargeablePaymentSource = Boolean(defaultPaymentSource?.last4); // Card or ACH

  function determineInitialBillingValues() {
    const billingValues = {} as BillingValues;

    // Default transaction payer:
    if (defaultPayer === Payer.CUSTOMER) {
      billingValues.defaultTransactionPayer = Payer.CUSTOMER;
    } else {
      billingValues.defaultTransactionPayer = Payer.ORGANIZATION;
    }

    // How organization pays:
    billingValues.usesAutomaticPayments = !(
      defaultPayer === Payer.NOTARIZE ||
      defaultInvoiceProvider === InvoiceProviderTypes.NETSUITE ||
      (defaultPayer === Payer.CUSTOMER && !chargeablePaymentSource)
    );

    // How organization pays - invoicing:
    if (billingValues.usesAutomaticPayments) {
      if (
        defaultInvoiceProvider === InvoiceProviderTypes.STRIPE &&
        automaticInvoiceType !== AutomaticInvoiceType.NONE
      ) {
        billingValues.invoiceType = InvoiceType.STRIPE;
      } else {
        billingValues.invoiceType = InvoiceType.NONE;
      }
    } else if (
      defaultInvoiceProvider === InvoiceProviderTypes.NOTARIZE ||
      automaticInvoiceType === AutomaticInvoiceType.NONE
    ) {
      billingValues.invoiceType = InvoiceType.OFF_PLATFORM;
    } else if (defaultInvoiceProvider === InvoiceProviderTypes.STRIPE) {
      billingValues.invoiceType = InvoiceType.STRIPE;
    } else {
      billingValues.invoiceType = InvoiceType.NETSUITE;
    }

    // How frequently organization pays and/or receives invoices:
    if (billingValues.invoiceType === InvoiceType.OFF_PLATFORM) {
      billingValues.paymentFrequency = PaymentFrequencyType.NONE;
    } else if (batchPayment) {
      billingValues.paymentFrequency = PaymentFrequencyType.BATCHED;
    } else {
      billingValues.paymentFrequency = PaymentFrequencyType.TRANSACTIONAL;
    }

    return billingValues;
  }

  const billingValues = determineInitialBillingValues();

  const form = useForm<FormValues>({
    defaultValues: {
      defaultPayer,
      defaultTransactionPayer: billingValues.defaultTransactionPayer,
      invoiceType: billingValues.invoiceType,
      paymentFrequency: billingValues.paymentFrequency,
      billingEmail,
      defaultInvoiceProvider,
      automaticInvoiceType,
      batchPayment,
    },
  });
  const { formState } = form;

  const selectedDefaultPayer = form.watch("defaultTransactionPayer");
  const [selectedUsesAutomaticPayments, setUsesAutomaticPayments] = useState(
    billingValues.usesAutomaticPayments,
  );
  const selectedInvoiceType = form.watch("invoiceType");
  const selectedPaymentFrequency = form.watch("paymentFrequency");

  useEffect(() => {
    if (
      selectedDefaultPayer === Payer.CUSTOMER &&
      !chargeablePaymentSource &&
      selectedUsesAutomaticPayments
    ) {
      setUsesAutomaticPayments(false); // Fallback charges will use invoicing
    }
    if (
      selectedUsesAutomaticPayments &&
      [InvoiceType.NETSUITE, InvoiceType.OFF_PLATFORM].includes(selectedInvoiceType)
    ) {
      form.setValue("invoiceType", InvoiceType.NONE);
    } else if (
      !selectedUsesAutomaticPayments &&
      selectedDefaultPayer === Payer.CUSTOMER &&
      chargeablePaymentSource &&
      selectedInvoiceType === InvoiceType.STRIPE
    ) {
      form.setValue("invoiceType", InvoiceType.NETSUITE); // Otherwise Stripe will auto charge
    } else if (!selectedUsesAutomaticPayments && selectedInvoiceType === InvoiceType.NONE) {
      form.setValue("invoiceType", InvoiceType.OFF_PLATFORM);
    } else {
      form.setValue("invoiceType", selectedInvoiceType); // Ensures correct selection
    }
    if (selectedInvoiceType === InvoiceType.OFF_PLATFORM) {
      form.setValue("paymentFrequency", AutomaticInvoiceType.NONE);
    } else if (selectedPaymentFrequency === AutomaticInvoiceType.NONE) {
      form.setValue("paymentFrequency", AutomaticInvoiceType.TRANSACTIONAL);
    }
  }, [
    selectedDefaultPayer,
    selectedUsesAutomaticPayments,
    selectedInvoiceType,
    selectedPaymentFrequency,
  ]);

  function determineUpdatedBillingValues() {
    const billingValues = {} as UpdatedBillingValues;

    billingValues.defaultPayer =
      selectedDefaultPayer === Payer.CUSTOMER
        ? Payer.CUSTOMER
        : selectedUsesAutomaticPayments
          ? Payer.ORGANIZATION
          : Payer.NOTARIZE;
    billingValues.defaultInvoiceProvider =
      selectedInvoiceType === InvoiceType.STRIPE
        ? InvoiceProviderTypes.STRIPE
        : selectedInvoiceType === InvoiceType.NETSUITE
          ? InvoiceProviderTypes.NETSUITE
          : InvoiceProviderTypes.NOTARIZE;
    billingValues.automaticInvoiceType = selectedPaymentFrequency;
    billingValues.batchPayment = selectedPaymentFrequency === AutomaticInvoiceType.BATCHED;
    return billingValues;
  }

  const handleSubmit = form.handleSubmit((data: FormValues) => {
    const { billingEmail } = data;
    const updatedBillingValues = determineUpdatedBillingValues();

    return updateOrganizationPayerMutateFn({
      variables: {
        input: {
          id,
          defaultPayer: updatedBillingValues.defaultPayer,
          billingEmail,
          defaultInvoiceProvider: updatedBillingValues.defaultInvoiceProvider,
          automaticInvoiceType: updatedBillingValues.automaticInvoiceType,
          batchPayment: updatedBillingValues.batchPayment,
        },
      },
    })
      .then(() => {
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          message: intl.formatMessage(messages.saveSuccess),
        });
      })
      .catch((err) => {
        captureException(err);
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          message: intl.formatMessage(messages.saveFailure),
          subtype: NOTIFICATION_SUBTYPES.ERROR,
        });
      });
  });

  const { paymentFrequency, invoiceType } = form.getValues();

  const showNetsuiteTermsOption =
    useFeatureFlag("netsuite-net-45-rollout") &&
    paymentFrequency === PaymentFrequencyType.BATCHED &&
    invoiceType === InvoiceType.NETSUITE;

  return (
    <>
      <SettingsTitle>
        <FormattedMessage id="ab4a7fad-af54-47c0-a8d4-c6f63098e353" defaultMessage="Billing" />
      </SettingsTitle>
      <div className={Styles.billingSetupCards}>
        <div className={Styles.billingCardRow}>
          <Card
            footer={
              <Button
                type="submit"
                automationId="billing-setup-form-submit"
                isLoading={formState.isSubmitting}
                disabled={formDisabled}
                buttonColor="action"
                variant="primary"
                onClick={handleSubmit}
              >
                {intl.formatMessage(messages.save)}
              </Button>
            }
          >
            <>
              <CardSection>
                <RadioGroup
                  label={intl.formatMessage(messages.defaultTransactionPayer)}
                  helperText={intl.formatMessage(messages.chargeSignerSubtitle)}
                  aria-invalid={isAriaInvalid(form.formState.errors.defaultTransactionPayer)}
                >
                  <RadioLabel
                    label={intl.formatMessage(messages.chargeSignerLabel)}
                    radio={
                      <RadioInput
                        value={Payer.CUSTOMER}
                        {...form.register("defaultTransactionPayer")}
                      />
                    }
                  />
                  <RadioLabel
                    label={intl.formatMessage(messages.chargeOrgLabel)}
                    radio={
                      <RadioInput
                        value={Payer.ORGANIZATION}
                        {...form.register("defaultTransactionPayer")}
                      />
                    }
                  />
                </RadioGroup>
              </CardSection>
              <CardSection>
                <RadioGroup
                  label={intl.formatMessage(messages.howOrganizationPays)}
                  helperText={intl.formatMessage(messages.howOrganizationPaysHelperText)}
                  aria-invalid={isAriaInvalid(form.formState.errors.invoiceType)}
                >
                  <PillsHeader>
                    <Pill
                      className={Styles.billingSetupPills}
                      data-automation-id="billing-setup-automatic-payments"
                      selected={selectedUsesAutomaticPayments}
                      disabled={selectedDefaultPayer === Payer.CUSTOMER && !chargeablePaymentSource}
                      onClick={() => {
                        setUsesAutomaticPayments(true);
                      }}
                    >
                      {intl.formatMessage(messages.automaticPayments)}
                    </Pill>
                    <Pill
                      className={Styles.billingSetupPills}
                      data-automation-id="billing-setup-no-automatic-payments"
                      selected={!selectedUsesAutomaticPayments}
                      onClick={() => {
                        setUsesAutomaticPayments(false);
                      }}
                    >
                      {intl.formatMessage(messages.noAutomaticPayments)}
                    </Pill>
                  </PillsHeader>
                  {selectedUsesAutomaticPayments ? (
                    <>
                      <RadioLabel
                        label={intl.formatMessage(messages.noInvoicesLabel)}
                        radio={
                          <RadioInput value={InvoiceType.NONE} {...form.register("invoiceType")} />
                        }
                      />
                      <RadioLabel
                        label={intl.formatMessage(messages.stripeInvoicesLabel)}
                        radio={
                          <RadioInput
                            value={InvoiceType.STRIPE}
                            {...form.register("invoiceType")}
                          />
                        }
                      />
                    </>
                  ) : (
                    <>
                      <RadioLabel
                        label={intl.formatMessage(messages.stripeInvoicesLabel)}
                        radio={
                          <RadioInput
                            value={InvoiceType.STRIPE}
                            {...form.register("invoiceType")}
                            disabled={
                              selectedDefaultPayer === Payer.CUSTOMER && chargeablePaymentSource
                            }
                          />
                        }
                      />
                      <RadioLabel
                        label={intl.formatMessage(messages.netsuiteInvoicesLabel)}
                        radio={
                          <RadioInput
                            value={InvoiceType.NETSUITE}
                            {...form.register("invoiceType")}
                          />
                        }
                      />
                      <RadioLabel
                        label={intl.formatMessage(messages.offPlatformInvoicesLabel)}
                        radio={
                          <RadioInput
                            value={InvoiceType.OFF_PLATFORM}
                            {...form.register("invoiceType")}
                          />
                        }
                      />
                    </>
                  )}
                </RadioGroup>
              </CardSection>
              <CardSection>
                <RadioGroup label={intl.formatMessage(messages.paymentFrequency)}>
                  {selectedInvoiceType !== InvoiceType.OFF_PLATFORM ? (
                    <>
                      <RadioLabel
                        label={intl.formatMessage(messages.individualChargesLabel)}
                        radio={
                          <RadioInput
                            value={AutomaticInvoiceType.TRANSACTIONAL}
                            {...form.register("paymentFrequency")}
                          />
                        }
                      />
                      <RadioLabel
                        label={intl.formatMessage(messages.batchesLabel)}
                        radio={
                          <RadioInput
                            value={AutomaticInvoiceType.BATCHED}
                            {...form.register("paymentFrequency")}
                          />
                        }
                      />
                    </>
                  ) : (
                    <>
                      <FormattedMessage
                        id="827e8b31-35f0-4f9c-a193-378603d712ec"
                        defaultMessage="N/A because you selected manual by finance team"
                      />
                      <RadioInput
                        value={AutomaticInvoiceType.NONE}
                        {...form.register("paymentFrequency")}
                        hidden
                      />
                    </>
                  )}
                </RadioGroup>
              </CardSection>
            </>
            <CardSection>
              <CardHeading>{intl.formatMessage(messages.billingEmail)}</CardHeading>
              <TextInput
                {...form.register("billingEmail", { required: true })}
                id="billingEmail"
                name="billingEmail"
                disabled={formDisabled}
                data-automation-id="billing-setup-form-billing-email"
                aria-invalid={isAriaInvalid(form.formState.errors.billingEmail)}
              />
            </CardSection>
            <CardSection>
              <CardHeading>{intl.formatMessage(messages.creditCard)}</CardHeading>
              {last4 ? (
                `•••• •••• •••• ${last4}`
              ) : (
                <FormattedMessage
                  id="9fb01e20-cc7f-46e0-b418-bc1d51e1d376"
                  defaultMessage="No card on file"
                />
              )}
            </CardSection>
          </Card>

          {showNetsuiteTermsOption && <NetsuiteTermsForm organizationId={id} />}
        </div>
        <div className={Styles.billingCardRow}>
          <CollabFeeSetting />
          <EasylinksPaidBy />
        </div>
      </div>
    </>
  );
}

export default BillingSetup;
