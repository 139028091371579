import "common/form/form.scss";

import { PureComponent } from "react";
import PropTypes from "prop-types";
import { defineMessages, injectIntl } from "react-intl";

import Icon from "common/core/icon";
import FormGroup from "common/form/group";
import { DeprecatedSocialSecurityNumberField } from "common/form/fields/social_security_number";
import FormGroupErrors from "common/form/group_errors";

const messages = defineMessages({
  socialSecurityNumber: {
    id: "09111c98-3ac0-4c0c-9996-52cd8a4992a2",
    description: "socialSecurityNumber",
    defaultMessage: "Social security number",
  },
});

class SignerSsnField extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showFormField: !props.hasSsn,
    };
  }

  clearSsnValue = () => {
    const { change, fieldName } = this.props;

    this.setState({ showFormField: true }, () => {
      change(fieldName, null);
    });
  };

  render() {
    const { fieldName, fieldLabel, className, intl } = this.props;
    const { showFormField } = this.state;

    const formGroup = showFormField ? (
      <FormGroup fields={[fieldName]} className={className}>
        <DeprecatedSocialSecurityNumberField
          name={fieldName}
          placeholder={intl.formatMessage(messages.socialSecurityNumber)}
          autoComplete="off"
          normalize={(val) => (val === "" ? null : val)}
          data-automation-id={fieldName}
        />

        <FormGroupErrors fields={[fieldName]} />
      </FormGroup>
    ) : (
      <div className="Form-row">
        <div className="ssn-value-field">
          <input type="text" className="Form-input--readonly" value="••• - •• - ••••" disabled />
          <Icon name="x" className="remove" deprecatedOnClick={this.clearSsnValue} />
        </div>
      </div>
    );

    return (
      <div className="SignerSsnFormRow">
        <div className="Form-section-header">
          <div>{fieldLabel}</div>
        </div>

        {formGroup}
      </div>
    );
  }
}

SignerSsnField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fieldLabel: PropTypes.string.isRequired,
  className: PropTypes.string,
  hasSsn: PropTypes.bool.isRequired,
  change: PropTypes.func.isRequired,

  // react-intl
  intl: PropTypes.object.isRequired,
};

export default injectIntl(SignerSsnField);
