import { useIntl, defineMessages } from "react-intl";
import { type ComponentProps } from "react";
import { getMinutes, getHours, isSameDay } from "date-fns";

import { format } from "common/core/format/date";
import { EXPIRATION_TIME, ACTIVATION_TIME } from "constants/transaction";
import { parse } from "common/core/parse/date";
import {
  SectionHeader,
  showField,
  requiredField,
  downgradeDisplay,
  readonlyField,
} from "common/transaction_creation/v3/common";
import {
  type SectionComponentProps,
  type SectionContract,
} from "common/transaction_creation/v3/form";
import { isNotaryNST } from "common/notary/capacity";
import { Feature, SigningScheduleTypes } from "graphql_globals";
import { denormalizeDatetime, normalizeDatetime } from "util/transaction";
import type { ConfiguredField } from "common/transaction_creation/v3/config";
import { UNASSIGNED as UNASSIGNED_CLOSER } from "common/transactions/closer_assignment";

import type { SigningDetailsUser } from "./user_fragment.graphql";
import type { SigningDetails as SigningDetailsQuery } from "./transaction_fragment.graphql";
import type { SigningDetailsOrg } from "./organization_fragment.graphql";
import { NotaryMeetingCard } from "./notary_meeting";
import { NotaryNotesCard } from "./notary_notes";
import { SignerMessageCard } from "./signer_message";
import { CredibleWitnessCard } from "./credible_witness";
import Styles from "./index.module.scss";

const MESSAGES = defineMessages({
  signingDetails: {
    id: "01afe0bc-9bbc-4cad-8e18-b3e62cdfbad8",
    defaultMessage: "Signing details",
  },
});

export const CONFIGS = {
  signerMessage: "signerMessage",
  activationDate: "activationDate",
  expirationDate: "expirationDate",
  notaryMeetingDate: "notaryMeetingDate",
  notaryMeetingTime: "notaryMeetingTime",
  notarizeCloserOverride: "notarizeCloserOverride",
  closerAssigneeId: "closerAssigneeId",
  credibleWitnessFirstName: "credibleWitnessFirstName",
  credibleWitnessMiddleName: "credibleWitnessMiddleName",
  credibleWitnessLastName: "credibleWitnessLastName",
  credibleWitnessEmail: "credibleWitnessEmail",
  credibleWitnessPhoneNumber: "credibleWitnessPhoneNumber",
  notaryNotes: "notaryNotes",
  defaultDocRequirement: "defaultDocRequirement",
  personallyKnownToNotary: "personallyKnownToNotary",
  isCollaboratorUser: "isCollaboratorUser",
} as const;
export const FORM_FIELDS = {
  signerMessage: "signerMessage",
  signingScheduleType: "signingScheduleType",
  activationDate: "activationDate",
  expirationDate: "expirationDate",
  expirationTimezone: "expirationTimezone",
  notaryMeetingTimezone: "notaryMeetingTimezone",
  notaryMeetingDate: "notaryMeetingDate",
  notaryMeetingTime: "notaryMeetingTime",
  notarizeCloserOverride: "notarizeCloserOverride",
  closerAssigneeId: "closerAssigneeId",
  credibleWitnesses: "credibleWitnesses",
  personallyKnownToNotary: "personallyKnownToNotary",
} as const;
type SigningDetailsSectionProps = ComponentProps<typeof SIGNING_DETAILS_SECTION.Component>;

export function showComponent(
  config: SigningDetailsSectionProps["config"],
  fields: ConfiguredField[],
) {
  return fields.some((field) => {
    return showField(config, field);
  });
}

export function requiredComponent(
  config: SigningDetailsSectionProps["config"],
  fields: ConfiguredField[],
) {
  return fields.some((field) => {
    return requiredField(config, field);
  });
}

export function readonlyComponent(
  config: SigningDetailsSectionProps["config"],
  fields: ConfiguredField[],
) {
  return fields.some((field) => {
    return readonlyField(config, field);
  });
}

function SigningDetailsSection({
  config,
  form,
  transaction,
  organization,
  user,
}: SectionComponentProps<SigningDetailsQuery, SigningDetailsOrg, SigningDetailsUser>) {
  const intl = useIntl();

  return (
    <>
      <SectionHeader iconName="calendar-filled">
        {intl.formatMessage(MESSAGES.signingDetails)}
      </SectionHeader>

      <div className={Styles.cards}>
        <NotaryMeetingCard
          config={config}
          form={form}
          organization={organization}
          transaction={transaction}
          user={user}
        />
        <NotaryNotesCard config={config} transaction={transaction} organization={organization} />
        <CredibleWitnessCard config={config} form={form} transaction={transaction} />
        <SignerMessageCard config={config} form={form} />
      </div>
    </>
  );
}

type SigningScheduleDateTimeProps = {
  date: Date | null;
  timezone: SigningDetailsUser["timezone"];
  hour?: number;
  minutes?: number;
};

function normalizeSigningScheduleDateTime({
  date,
  timezone,
  hour = 0,
  minutes = 0,
}: SigningScheduleDateTimeProps) {
  if (date && timezone) {
    return normalizeDatetime({
      hour,
      minutes,
      date,
      timezone,
    });
  }
  return null;
}

export type SigningScheduleTypeSelection = SigningScheduleTypes | "NO_RESTRICTIONS" | null;

export type SigningDetailsFormValues = {
  [FORM_FIELDS.signerMessage]: string | null;
  [FORM_FIELDS.signingScheduleType]: SigningScheduleTypeSelection;
  [FORM_FIELDS.activationDate]: Date | null;
  [FORM_FIELDS.expirationDate]: Date | null;
  [FORM_FIELDS.notaryMeetingTime]: string | null;
  [FORM_FIELDS.notaryMeetingDate]: Date | null;
  [FORM_FIELDS.notaryMeetingTimezone]: string | null;
  [FORM_FIELDS.expirationTimezone]: string | null;
  [FORM_FIELDS.notarizeCloserOverride]: boolean;
  [FORM_FIELDS.closerAssigneeId]: string | null;
  [FORM_FIELDS.credibleWitnesses]: {
    id: string | null;
    firstName: string;
    middleName: string | null;
    lastName: string;
    email: string;
    phoneNumber: string | null;
  }[];
  [FORM_FIELDS.personallyKnownToNotary]: boolean;
};

type SigningDetailsSubmitData = {
  signerMessage: string | null;
  signingScheduleType: string | null;
  activationDate: string | null;
  expirationDate: string | null;
  expirationTimezone: string | null;
  activationTimezone: string | null;
  notaryMeetingTime: string | null;
  notaryMeetingTimezone: string | null;
  personallyKnownToNotary: boolean | null;
  closerAssigneeId: string | null;
  notarizeCloserOverride: boolean;
  credibleWitnesses: {
    id: string | null;
    firstName: string;
    middleName: string | null;
    lastName: string;
    email: string;
    phoneNumber: string | null;
  }[];
};

export const SIGNING_DETAILS_SECTION = {
  Component: SigningDetailsSection,
  configs: CONFIGS,
  modifyConfig({ sectionConfig, organization, permissions, transaction, user }) {
    const modifiedConfig = { ...sectionConfig };
    const businessTransaction = !transaction.isMortgage;

    if (!transaction.organization.featureList.includes(Feature.CUSTOM_EMAILS)) {
      downgradeDisplay(modifiedConfig, CONFIGS.signerMessage, "hidden");
    }

    if (!transaction.organization.featureList.includes(Feature.NOTARY_NOTES)) {
      downgradeDisplay(modifiedConfig, CONFIGS.notaryNotes, "hidden");
    }

    if (!transaction.organization.featureList.includes(Feature.SIGNING_SCHEDULE)) {
      downgradeDisplay(modifiedConfig, CONFIGS.activationDate, "hidden");
      downgradeDisplay(modifiedConfig, CONFIGS.expirationDate, "hidden");
    }

    if (!transaction.organization.featureList.includes(Feature.CREDIBLE_WITNESS)) {
      downgradeDisplay(modifiedConfig, CONFIGS.credibleWitnessEmail, "hidden");
      downgradeDisplay(modifiedConfig, CONFIGS.credibleWitnessFirstName, "hidden");
      downgradeDisplay(modifiedConfig, CONFIGS.credibleWitnessLastName, "hidden");
      downgradeDisplay(modifiedConfig, CONFIGS.credibleWitnessMiddleName, "hidden");
      downgradeDisplay(modifiedConfig, CONFIGS.credibleWitnessPhoneNumber, "hidden");
    }

    const usersOrgCreatedTransaction =
      organization.id === transaction.organization.id ||
      Boolean(
        organization.subsidiaryOrganizations.find((o) => o.id === transaction.organization.id),
      );

    if (!usersOrgCreatedTransaction) {
      if (
        !permissions.hasPermissionFor("manageOpenOrders") &&
        !permissions.hasPermissionFor("editUnownedTransaction")
      ) {
        downgradeDisplay(modifiedConfig, CONFIGS.credibleWitnessEmail, "readonly");
        downgradeDisplay(modifiedConfig, CONFIGS.credibleWitnessFirstName, "readonly");
        downgradeDisplay(modifiedConfig, CONFIGS.credibleWitnessLastName, "readonly");
        downgradeDisplay(modifiedConfig, CONFIGS.credibleWitnessMiddleName, "readonly");
        downgradeDisplay(modifiedConfig, CONFIGS.credibleWitnessPhoneNumber, "readonly");
      }
    }

    const collabOrgFeatureList = sectionConfig.isCollaboratorUser
      ? organization.featureList
      : transaction.organization.featureList;

    if (!collabOrgFeatureList.includes(Feature.ORGANIZATION_NOTARIES)) {
      downgradeDisplay(modifiedConfig, CONFIGS.notarizeCloserOverride, "hidden");
      downgradeDisplay(modifiedConfig, CONFIGS.closerAssigneeId, "hidden");
    }

    // intentional that a REAL ORG fulfilling business TXN could hide notary meeting time
    // always show notary meeting time for REAL TXNs
    if (
      businessTransaction &&
      !isNotaryNST(user.notaryProfile) &&
      !transaction.organization.featureList.includes(Feature.ORGANIZATION_NOTARIES)
    ) {
      downgradeDisplay(modifiedConfig, CONFIGS.notaryMeetingDate, "hidden");
      downgradeDisplay(modifiedConfig, CONFIGS.notaryMeetingTime, "hidden");
    }

    if (!permissions.hasPermissionFor("editOrganizationTransactions")) {
      downgradeDisplay(modifiedConfig, CONFIGS.activationDate, "readonly");
      downgradeDisplay(modifiedConfig, CONFIGS.expirationDate, "readonly");
      downgradeDisplay(modifiedConfig, CONFIGS.notaryMeetingDate, "readonly");
      downgradeDisplay(modifiedConfig, CONFIGS.notaryMeetingTime, "readonly");
      downgradeDisplay(modifiedConfig, CONFIGS.personallyKnownToNotary, "readonly");
      downgradeDisplay(modifiedConfig, CONFIGS.notaryNotes, "readonly");
      downgradeDisplay(modifiedConfig, CONFIGS.notarizeCloserOverride, "readonly");
      downgradeDisplay(modifiedConfig, CONFIGS.closerAssigneeId, "readonly");
    }

    if (!transaction.organization.featureList.includes(Feature.PERSONALLY_KNOWN_SIGNER_ID)) {
      downgradeDisplay(modifiedConfig, CONFIGS.personallyKnownToNotary, "hidden");
    }

    return modifiedConfig;
  },
  getDefaultFormValues(transaction) {
    // API saves nil as window so we need to override it when form is initialized
    // to be fixed in BIZ-5581
    let signingScheduleType: SigningScheduleTypeSelection = "NO_RESTRICTIONS";
    let notaryMeetingDate;
    let notaryMeetingTime;

    const activationDate = transaction.activationTime
      ? denormalizeDatetime(transaction.activationTime, transaction.expiryTimezone!)
      : null;
    const expirationDate = transaction.expiry
      ? denormalizeDatetime(transaction.expiry, transaction.expiryTimezone!)
      : null;

    // transaction notary meeting time determines both date + time
    if (transaction.notaryMeetingTime) {
      notaryMeetingDate = denormalizeDatetime(
        transaction.notaryMeetingTime,
        transaction.notaryMeetingTimezone!,
      );
      notaryMeetingTime = format({ value: notaryMeetingDate, formatStyle: "h:mm a" });
      signingScheduleType = SigningScheduleTypes.DATE;
    } else {
      notaryMeetingDate = null;
      notaryMeetingTime = null;
    }

    if (activationDate && expirationDate && isSameDay(activationDate, expirationDate)) {
      // the notary meeting date is needed to initialize the date value in the ui
      // when activation and expiry day are the same, the schedule type is considered date
      signingScheduleType = SigningScheduleTypes.DATE;
      notaryMeetingDate = expirationDate;
    } else if (activationDate || expirationDate) {
      signingScheduleType = SigningScheduleTypes.WINDOW;
    }

    const credibleWitnesses = transaction.organizationTransactionWitnesses.map(
      ({ id, firstName, middleName, lastName, email, e164PhoneNumber }) => ({
        id,
        firstName,
        middleName,
        lastName,
        email,
        phoneNumber: e164PhoneNumber,
      }),
    );

    return {
      signerMessage: transaction.message,
      signingScheduleType,
      activationDate,
      expirationDate,
      notaryMeetingTime,
      notaryMeetingDate,
      notaryMeetingTimezone: transaction.notaryMeetingTimezone,
      expirationTimezone: transaction.expiryTimezone,
      closerAssigneeId: transaction.closer?.id || null,
      notarizeCloserOverride: transaction.notarizeCloserOverride,
      credibleWitnesses,
      personallyKnownToNotary: transaction.customerSigners.some(
        (customer) => customer.personallyKnownToNotary,
      ),
    };
  },
  getSubmitData({ sectionFormValues }) {
    const signingScheduleType =
      sectionFormValues.signingScheduleType === "NO_RESTRICTIONS"
        ? null
        : sectionFormValues.signingScheduleType;

    const parsedNotaryMeetingTime = sectionFormValues.notaryMeetingTime
      ? parse(sectionFormValues.notaryMeetingTime, "h:mm a")
      : null;

    const closerAssigneeId =
      sectionFormValues.notarizeCloserOverride ||
      !sectionFormValues.closerAssigneeId ||
      sectionFormValues.closerAssigneeId === UNASSIGNED_CLOSER
        ? null
        : sectionFormValues.closerAssigneeId;

    // If closer override is on, personally known to notary must be off
    const personallyKnownToNotary =
      !sectionFormValues.notarizeCloserOverride && sectionFormValues.personallyKnownToNotary;

    // activationTimezone always defaulted to expirationTimezone
    let expirationTimezone = sectionFormValues.expirationTimezone;
    let activationTimezone = sectionFormValues.expirationTimezone;

    let activationDate = normalizeSigningScheduleDateTime({
      date: sectionFormValues.activationDate,
      timezone: activationTimezone,
      hour: ACTIVATION_TIME.HOURS,
      minutes: ACTIVATION_TIME.MINUTES,
    });

    let expirationDate = normalizeSigningScheduleDateTime({
      date: sectionFormValues.expirationDate,
      timezone: expirationTimezone,
      hour: EXPIRATION_TIME.HOURS,
      minutes: EXPIRATION_TIME.MINUTES,
    });

    // biz + real v3 always sets activation, expiry to value of notary meeting date
    if (signingScheduleType === SigningScheduleTypes.DATE) {
      expirationDate = normalizeSigningScheduleDateTime({
        date: sectionFormValues.notaryMeetingDate,
        timezone: sectionFormValues.notaryMeetingTimezone,
        hour: EXPIRATION_TIME.HOURS,
        minutes: EXPIRATION_TIME.MINUTES,
      });

      activationDate = normalizeSigningScheduleDateTime({
        date: sectionFormValues.notaryMeetingDate,
        timezone: sectionFormValues.notaryMeetingTimezone,
        hour: ACTIVATION_TIME.HOURS,
        minutes: ACTIVATION_TIME.MINUTES,
      });

      expirationTimezone = sectionFormValues.notaryMeetingTimezone;
      activationTimezone = expirationTimezone;
    }

    // always set activation time to current date if only expiry value provided
    if (expirationDate && !activationDate) {
      activationDate = normalizeSigningScheduleDateTime({
        date: new Date(),
        timezone: sectionFormValues.expirationTimezone,
        hour: ACTIVATION_TIME.HOURS,
        minutes: ACTIVATION_TIME.MINUTES,
      });
    }

    return {
      signerMessage: sectionFormValues.signerMessage,
      signingScheduleType,
      activationDate,
      expirationDate,
      expirationTimezone,
      activationTimezone,
      closerAssigneeId,
      notarizeCloserOverride: sectionFormValues.notarizeCloserOverride,
      // notary meeting date used in notary meeting time
      notaryMeetingTime: parsedNotaryMeetingTime
        ? normalizeSigningScheduleDateTime({
            date: sectionFormValues.notaryMeetingDate,
            timezone: sectionFormValues.notaryMeetingTimezone,
            hour: getHours(parsedNotaryMeetingTime),
            minutes: getMinutes(parsedNotaryMeetingTime),
          })
        : null,
      notaryMeetingTimezone: sectionFormValues.notaryMeetingTimezone,
      personallyKnownToNotary,
      credibleWitnesses: sectionFormValues.credibleWitnesses,
    };
  },
} satisfies SectionContract<
  SigningDetailsFormValues,
  SigningDetailsSubmitData,
  SigningDetailsQuery,
  SigningDetailsOrg,
  SigningDetailsUser,
  typeof CONFIGS
>;
