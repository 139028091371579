import classNames from "classnames";
import { useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { isDate, isValid, isBefore, isAfter } from "date-fns";
import type { InjectedFormProps } from "redux-form";

import { format, fromZonedTime } from "common/core/format/date";
import { parse } from "common/core/parse/date";
import Button from "common/core/button";
import { DeprecatedSectionHeader } from "common/form/sub_form/section/header";
import { DeprecatedDatePickerField } from "common/form/fields/datepicker";
import { DeprecatedSelectField } from "common/form/fields/select";
import { DeprecatedTimezoneField } from "common/form/fields/timezone";
import AlertMessage from "common/core/alert_message";
import FormGroup from "common/form/group";
import { DeprecatedMultipartColumn } from "common/form/inputs/multipart/column";
import { DeprecatedFormRow } from "common/form/elements/row";
import { deprecatedSubForm } from "common/form/enhancers/sub_form";
import { validatePresence, validateIf, validateFutureDate } from "validators/form";
import { composeValidators, getFormErrors } from "util/form";
import { SIGNING_SCHEDULE_TYPES } from "constants/transaction";
import compose from "util/compose";
import FormGroupErrors from "common/form/group_errors";
import { DeprecatedMultipartRow } from "common/form/inputs/multipart/row";
import createValidation from "validators/util";
import { invalidPastDate } from "errors/form";

import type { FormValues } from "./signing_time_rescheduler";
import { timezoneWithOffset } from "../utils";

const messages = defineMessages({
  setTimezone: {
    id: "d1f64819-27ea-4e75-9f9e-ba13d8d1d2d3",
    defaultMessage: "Set timezone",
  },
  closingDateHeader: {
    id: "5c580dc4-f32a-45ce-9b42-395df1134190",
    defaultMessage: "The closing will occur on:",
  },
  notaryMeetingHeader: {
    id: "0f9aef59-c1ac-4aeb-8b47-fbeef82afc87",
    defaultMessage: "What time is the notary meeting?",
  },
  tipHeader: {
    id: "14731a4c-7340-408b-8008-3f9b0f8e68f5",
    defaultMessage: "Schedule a Closing",
  },
  tip: {
    id: "98eca0dd-6f6f-488b-80c2-eea4575a4c5f",
    defaultMessage:
      "You'll want to choose this option if there is only one day that your signers can join a meeting with a notary to close on their property. They won't be able to join a meeting prior to the date that you select and if they miss it, they will no longer have access to this transaction.",
  },
  errorMessage: {
    id: "74c32d2e-8ded-49d4-9413-874eb0c327b6",
    defaultMessage: "Please provide a date when the scheduled closing will occur",
  },
});

type Props = {
  usersOrgCreatedTransaction?: boolean;
  withFormSectionHeaders?: boolean;
  transaction: {
    expiry?: string | null;
    activationTime?: string | null;
    activationTimezone?: string | null;
    expiryTimezone?: string | null;
  };
};

type AcceptedDate = Date | number | string | null;

type InnerProps = InjectedFormProps<FormValues, Props> & Props & { formValues: FormValues };

type FormError = { type: string };

export const validateScheduledClosing = (values: FormValues, props: Props) => {
  const {
    usersOrgCreatedTransaction,
    transaction: { activationTime },
  } = props;
  const { activationTimezone, activationDate } = values;
  return composeValidators(
    validateIf({
      condition: () => values.signingScheduleType === SIGNING_SCHEDULE_TYPES.DATE,
      validation: validatePresence({
        field: "expirationDate",
        label: "A closing date",
      }),
    }),
    validateIf({
      condition: () => Boolean(activationDate && !usersOrgCreatedTransaction),
      validation: createValidation(
        ({ value }) =>
          isValid(value) &&
          !isBefore(fromZonedTime(value as string, activationTimezone!), new Date(activationTime!)),
        invalidPastDate,
      )({
        field: "activationDate",
        label: "Activation",
        minDate: format({
          value: activationTime!,
          formatStyle: "LL/dd/yy z",
        }),
      }),
    }),
    validateFutureDate({ field: "expirationDate", label: "Expiration" }),
  );
};

const generateTimeOptions = () => {
  const times = [];
  let tt = 0;
  const ap = ["AM", "PM"];

  for (let i = 0; tt < 24 * 60; i++) {
    const hh = Math.floor(tt / 60);
    const mm = tt % 60;
    times[i] = `${`${hh === 12 || hh === 0 ? 12 : hh % 12}`.slice(-2)}:${`0${mm}`.slice(-2)} ${
      ap[Math.floor(hh / 12)]
    }`;
    tt += 15;
  }

  return times.map((time) => ({
    value: parse(time, "h:mm a"),
    label: time,
  }));
};

const renderTimeValue = (timeValue: AcceptedDate & { label: string }) => {
  if (isDate(timeValue)) {
    return format({ value: timeValue, formatStyle: "h:mm a" });
  }

  return timeValue.label;
};

function SingleDateForm({
  usersOrgCreatedTransaction,
  withFormSectionHeaders,
  change,
  transaction,
  formValues,
}: InnerProps) {
  const intl = useIntl();
  const [showExpirationTimezone, setShowExpirationTimezone] = useState(false);

  /**
   * Toggle the state controlling whether or not the timezone controls are showing.
   */
  const handleTimezoneLinkClick = () => {
    return setShowExpirationTimezone(!showExpirationTimezone);
  };

  const renderExpirationTimezone = () => {
    const { expirationTimezone } = formValues;

    return (
      <>
        {showExpirationTimezone ? (
          <DeprecatedTimezoneField
            name="expirationTimezone"
            placeholder="Expiration Timezone"
            data-automation-id="expiration-timezone"
            useStyledInput
            placeholderAsLabel
            onChange={() => handleTimezoneLinkClick()}
          />
        ) : (
          <div className="TransactionActivationExpirationSection--timezone-button-wrapper">
            <Button
              onClick={() => handleTimezoneLinkClick()}
              buttonColor="action"
              variant="tertiary"
              buttonSize="condensed"
              automationId="expiration-timezone-button"
            >
              {timezoneWithOffset(expirationTimezone!) || intl.formatMessage(messages.setTimezone)}
            </Button>
          </div>
        )}
        <FormGroupErrors
          errorClassName={showExpirationTimezone ? "" : "SigningWindowError--rightAligned"}
          fields={["expirationTimezone"]}
        />
      </>
    );
  };

  const renderTimeSelector = (isFixedWidth?: boolean) => {
    return (
      <DeprecatedSelectField
        id="notaryMeetingTime"
        name="notaryMeetingTime"
        className={classNames({
          "TransactionActivationExpirationSection--Fields--NotaryMeetingTimeFixedWidth":
            isFixedWidth,
        })}
        placeholder={
          <FormattedMessage
            id="28eea012-9449-4fee-bb7e-d4a17af02523"
            defaultMessage="Select time"
          />
        }
        useStyledInput
        placeholderAsLabel
        items={generateTimeOptions()}
        valueRenderer={renderTimeValue}
        onChange={(time: Date) => change("notaryMeetingTime", time)}
        automationId="notary-meeting-time-select"
      />
    );
  };

  const renderDateField = () => {
    return (
      <>
        <DeprecatedDatePickerField
          minDate={new Date()}
          id="expirationDate"
          name="expirationDate"
          onChange={(date: Date) => change("activationDate", date)}
          displayRequiredAsterisk
          placeholderAsLabel
          placeholder="MM/DD/YYYY"
        />
        <FormGroupErrors fields={["expirationDate", "activationDate"]} />
      </>
    );
  };

  const renderRedrawWarning = () => {
    const { expiry } = transaction;
    const { expirationDate, expirationTimezone } = formValues;

    if (!isValid(expirationDate)) {
      return null;
    }

    // We would want to warn title agents/agencies if they're:
    // 1. viewing a transaction sent by a lender
    // 2. changing the expiration date to a later date than what the lender set initially
    if (
      !usersOrgCreatedTransaction &&
      isAfter(fromZonedTime(expirationDate!, expirationTimezone!), new Date(expiry!))
    ) {
      return (
        <AlertMessage
          kind="warning"
          className="TransactionActivationExpirationSection--redraw-warning"
        >
          <FormattedMessage
            id="fa86c296-54d7-4593-a843-31e9a3018c33"
            defaultMessage="Moving the closing to a date after {proposedClosingDate}, including updates to the timezone, may require the lender to redraw the documents."
            values={{
              proposedClosingDate: format({
                value: expiry!,
                formatStyle: "LL/dd/yy z",
              }),
            }}
          />
        </AlertMessage>
      );
    }
  };

  if (withFormSectionHeaders) {
    return (
      <>
        <DeprecatedSectionHeader title={intl.formatMessage(messages.closingDateHeader)} />
        {renderRedrawWarning()}
        <FormGroup fields={["expirationDate"]}>{renderDateField()}</FormGroup>
        {renderExpirationTimezone()}
        <DeprecatedSectionHeader title={intl.formatMessage(messages.notaryMeetingHeader)} />
        <FormGroup disableFormRowStyle fields={["notaryMeetingTime"]}>
          {renderTimeSelector(true)}
        </FormGroup>
      </>
    );
  }

  return (
    <div data-automation-id="scheduled-closing-form-group">
      {renderRedrawWarning()}
      <FormGroup fields={["notaryMeetingTime", "expirationDate"]} disableFormRowStyle>
        <DeprecatedMultipartRow>
          <DeprecatedMultipartColumn width={8}>{renderDateField()}</DeprecatedMultipartColumn>
          <DeprecatedMultipartColumn width={4}>{renderTimeSelector()}</DeprecatedMultipartColumn>
        </DeprecatedMultipartRow>
      </FormGroup>
      <DeprecatedFormRow>{renderExpirationTimezone()}</DeprecatedFormRow>
    </div>
  );
}

export const SingleDateReduxForm = compose(
  deprecatedSubForm<Props>({
    getValuesFor: [
      "activationTimezone",
      "expirationTimezone",
      "notaryMeetingTimezone",
      "expirationDate",
      "activationDate",
    ],
  }),
  getFormErrors<
    FormValues,
    InjectedFormProps<FormValues, Props> & Props & { formValues: FormValues },
    FormError
  >("EditTransaction"),
)(SingleDateForm);
