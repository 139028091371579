import type { ReactNode, InputHTMLAttributes } from "react";
import { FormattedMessage } from "react-intl";
import type { DropzoneState } from "react-dropzone";
import classnames from "classnames";

import Icon from "common/core/icon";
import Button, { type ButtonPropsOmit } from "common/core/button";

import Styles from "./index.module.scss";
import useBrandedStyle from "../brand/org_brand_style";

type Props = {
  label: ReactNode;
  dropzoneState: DropzoneState;
  disabled: boolean;
  subheader?: ReactNode;
  condensed?: boolean;
  onClickCb?: () => void;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
};
type UploadButtonProps = ButtonPropsOmit<"onClick"> & { dropzoneState: DropzoneState };

const getActiveStateClass = (dropzoneState: DropzoneState, disabled: boolean) => {
  const { isDragActive, isFocused } = dropzoneState;

  if (disabled) {
    return Styles.disabled;
  }
  if (isDragActive) {
    return Styles.dragActive;
  }
  if (isFocused) {
    return Styles.dragFocused;
  }
};

export function Dropzone({
  label,
  dropzoneState,
  disabled,
  subheader,
  condensed = false,
  onClickCb,
  inputProps,
}: Props) {
  const { getInputProps, getRootProps } = dropzoneState;

  const className = classnames(Styles.dropzone, getActiveStateClass(dropzoneState, disabled), {
    [Styles.condensed]: condensed,
  });
  //  shouldn't affect biz portal usages because the branding context won't be initialized
  const style = useBrandedStyle();

  return (
    <div
      {...getRootProps({
        className,
        style,
        ...(onClickCb && { onClick: onClickCb }),
      })}
      data-automation-id="dropzone"
    >
      <input
        data-automation-id="uploader-input"
        aria-labelledby="dropzone-header"
        {...getInputProps()}
        {...inputProps}
      />
      <div className={Styles.uploadIcon}>
        <Icon className="icon" name="upload" />
      </div>
      <div>
        <div id="dropzone-header" className={Styles.header}>
          {label}
        </div>
        <div className={Styles.subheader}>
          {subheader ? (
            subheader
          ) : (
            <FormattedMessage
              id="57817dcd-0c9d-4f4a-979b-e51f8e2b5b18"
              defaultMessage="Drop files here or click to browse."
            />
          )}
        </div>
      </div>
    </div>
  );
}

/** BIZ-7204 Temporary for retail-upload-copy-expt to update copy and action button
 * If either variant wins, there needs to be a discussion about making this
 * change consistent for all variations.
 * I decided it was better to create a temporary shell instead of adding a bunch of conditionals
 * to the existing Dropzone. If both variants lose, can just rip this component out.
 */
export function DropzoneExperiment({
  label,
  dropzoneState,
  disabled,
  subheader,
  condensed = false,
  onClickCb,
  inputProps,
}: Props) {
  const { getInputProps, getRootProps } = dropzoneState;

  const className = classnames(Styles.dropzone, getActiveStateClass(dropzoneState, disabled), {
    [Styles.condensed]: condensed,
  });
  //  shouldn't affect biz portal usages because the branding context won't be initialized
  const style = useBrandedStyle();

  return (
    <div
      {...getRootProps({
        className,
        style,
        ...(onClickCb && { onClick: onClickCb }),
      })}
      data-automation-id="dropzone"
    >
      <input
        data-automation-id="uploader-input"
        aria-labelledby="dropzone-header"
        {...getInputProps()}
        {...inputProps}
      />
      <div>
        <div id="dropzone-header" className={Styles.headerExpt}>
          {label}
        </div>
        <div className={Styles.subheaderExpt}>{subheader}</div>
        <div className={Styles.addADocumentWrapper}>
          <span>
            <FormattedMessage
              id="f39e1ea3-a369-4547-a174-76130bdfa2c6"
              defaultMessage="Add a document"
            />
          </span>
        </div>
      </div>
    </div>
  );
}

export function DropzoneImagePreview({
  image,
  showBoundingBox,
}: {
  image: {
    src: string;
    alt: string;
  };
  showBoundingBox: boolean;
}) {
  const { alt, src } = image;
  return (
    <div
      className={classnames(Styles.imagePreview, {
        [Styles.imagePreviewClear]: !showBoundingBox,
      })}
    >
      <img
        data-automation-id="dropzone-preview"
        className={classnames(Styles.previewImage, {
          [Styles.boundingBox]: showBoundingBox,
        })}
        src={src}
        alt={alt}
        onLoad={() => {
          URL.revokeObjectURL(src);
        }}
      />
    </div>
  );
}

export function UploadButton(props: UploadButtonProps) {
  const { dropzoneState, ...buttonProps } = props;

  return (
    <div
      {...dropzoneState.getRootProps({
        className: Styles.uploadButton,
      })}
    >
      <Button {...buttonProps} onClick={() => {}} />
      <input data-automation-id="uploader-input" {...dropzoneState.getInputProps()} />
    </div>
  );
}
