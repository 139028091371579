import { useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { subDays, isAfter, endOfDay } from "date-fns";
import type { InjectedFormProps } from "redux-form";

import Button from "common/core/button";
import { DeprecatedSectionHeader } from "common/form/sub_form/section/header";
import { DeprecatedDatePickerField } from "common/form/fields/datepicker";
import { DeprecatedTimezoneField } from "common/form/fields/timezone";
import { DeprecatedMultipartRow } from "common/form/inputs/multipart/row";
import { DeprecatedMultipartColumn } from "common/form/inputs/multipart/column";
import { DeprecatedFormRow } from "common/form/elements/row";
import { deprecatedSubForm } from "common/form/enhancers/sub_form";
import { SIGNING_SCHEDULE_TYPES } from "constants/transaction";
import { emptyValue } from "errors/form";
import compose from "util/compose";
import FormGroupErrors from "common/form/group_errors";
import FormGroup from "common/form/group";

import type { FormValues } from "./signing_time_rescheduler";
import { timezoneWithOffset } from "../utils";

const messages = defineMessages({
  fromText: {
    id: "d5761bfc-141a-4272-85bb-7e135be9663e",
    defaultMessage: "From",
  },
  toText: {
    id: "2e38ad91-8027-470a-a499-e5d39d743833",
    defaultMessage: "To",
  },
  setTimezone: {
    id: "5334d5dd-f774-4749-aa2f-e7e557672be1",
    defaultMessage: "Set timezone",
  },
  dateWindowHeader: {
    id: "7f7c234e-f3ae-4740-9b80-657e29eddcfe",
    defaultMessage: "The closing package is available to sign:",
  },
  tipHeader: {
    id: "d5c37049-5bdc-49fa-99a2-3fe9ae58820e",
    defaultMessage: "Flexible Window",
  },
  tip: {
    id: "9388b0bd-08d0-41fc-bbb5-cc1ac7b6e072",
    defaultMessage:
      "Your signer will be able to go through the online closing process at any time that is convenient for them within a window of your choosing.",
  },
  errorMessage: {
    id: "a2ac406f-8ccf-4dce-8855-667bd2c16ef3",
    defaultMessage: "Please select a date range where the signing is active",
  },
});

const TIME_TYPES = {
  ACTIVATION: "activation",
  EXPIRATION: "expiration",
};

type Props = {
  formName: string;
  withFormSectionHeaders?: boolean;
};

type InnerProps = InjectedFormProps<FormValues, Props> & Props & { formValues: FormValues };

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const validateSigningWindow = (values: FormValues, _props: Props) => {
  const { signingScheduleType, expirationDate, activationDate } = values;
  if (signingScheduleType === SIGNING_SCHEDULE_TYPES.WINDOW && !activationDate && !expirationDate) {
    return {
      activationDate: emptyValue({
        value: activationDate,
        label: "A date window",
      }),
    };
  } else if (signingScheduleType === SIGNING_SCHEDULE_TYPES.WINDOW && !expirationDate) {
    return {
      expirationDate: emptyValue({
        value: expirationDate,
        label: "An expiration date",
      }),
    };
  } else if (
    signingScheduleType === SIGNING_SCHEDULE_TYPES.WINDOW &&
    expirationDate &&
    isAfter(new Date(), endOfDay(expirationDate))
  ) {
    return {
      expirationDate: emptyValue({
        value: expirationDate,
        label: "An expiration date in the future",
      }),
    };
  }
  return {};
};

function SigningWindowForm({ formValues, change, withFormSectionHeaders }: InnerProps) {
  const intl = useIntl();
  const [showActivationTimezone, setShowActivationTimezone] = useState(false);
  const [showExpirationTimezone, setShowExpirationTimezone] = useState(false);

  const handleTimezoneLinkClick = (type: string, value?: string) => {
    const { activationTimezone, expirationTimezone } = formValues;

    switch (type) {
      case TIME_TYPES.ACTIVATION:
        // if we are setting a new value for activationTimezone, and expirationTimezone is not set.
        // then assume the user wants to set expirationTimezone too.
        if (value && !expirationTimezone) {
          change("expirationTimezone", value);
        }
        return setShowActivationTimezone(!showActivationTimezone);
      case TIME_TYPES.EXPIRATION:
        // if we are setting a new value for expirationTimezone, and activationTimezone is not set.
        // then assume the user wants to set activationTimezone too.
        if (value && !activationTimezone) {
          change("activationTimezone", value);
        }
        return setShowExpirationTimezone(!showExpirationTimezone);
      default:
        break;
    }
  };

  const renderExpirationTimezone = () => {
    const { expirationTimezone } = formValues;

    return (
      <>
        {showExpirationTimezone ? (
          <DeprecatedTimezoneField
            name="expirationTimezone"
            placeholder="Expiration Timezone"
            data-automation-id="expiration-timezone"
            useStyledInput
            placeholderAsLabel
            onChange={(timeZone: string) =>
              handleTimezoneLinkClick(TIME_TYPES.EXPIRATION, timeZone)
            }
          />
        ) : (
          <div className="TransactionActivationExpirationSection--timezone-button-wrapper">
            <Button
              variant="tertiary"
              buttonSize="condensed"
              buttonColor="action"
              onClick={() => handleTimezoneLinkClick(TIME_TYPES.EXPIRATION)}
              automationId="expiration-timezone-button"
            >
              {timezoneWithOffset(expirationTimezone!) || intl.formatMessage(messages.setTimezone)}
            </Button>
          </div>
        )}
        <FormGroupErrors
          errorClassName={showExpirationTimezone ? "" : "SigningWindowError--rightAligned"}
          fields={["expirationTimezone"]}
        />
      </>
    );
  };

  const renderActivation = (yesterday: Date) => {
    const { expirationDate } = formValues;

    return (
      <DeprecatedDatePickerField
        id="activationDate"
        name="activationDate"
        placeholder={intl.formatMessage(messages.fromText)}
        placeholderAsLabel
        useStyledInput
        displayRequiredAsterisk
        minDate={yesterday}
        maxDate={expirationDate}
      />
    );
  };

  const renderExpiration = (yesterday: Date) => {
    const { activationDate } = formValues;

    return (
      <DeprecatedDatePickerField
        id="expirationDate"
        name="expirationDate"
        placeholder={intl.formatMessage(messages.toText)}
        placeholderAsLabel
        useStyledInput
        displayRequiredAsterisk
        minDate={activationDate || yesterday}
      />
    );
  };

  const yesterday = subDays(new Date(), 1);

  if (withFormSectionHeaders) {
    return (
      <>
        <DeprecatedSectionHeader title={intl.formatMessage(messages.dateWindowHeader)} />
        <FormGroup fields={["expirationDate", "activationDate"]}>
          <DeprecatedMultipartRow>
            <DeprecatedMultipartColumn width={4}>
              {renderActivation(yesterday)}
            </DeprecatedMultipartColumn>
            <DeprecatedMultipartColumn width={4}>
              {renderExpiration(yesterday)}
            </DeprecatedMultipartColumn>
          </DeprecatedMultipartRow>
          <FormGroupErrors fields={["expirationDate", "activationDate"]} />
        </FormGroup>
        {renderExpirationTimezone()}
      </>
    );
  }

  return (
    <div data-automation-id="signing-window-form-group">
      <FormGroup disableFormRowStyle fields={["expirationDate", "activationDate"]}>
        <DeprecatedMultipartRow>
          <DeprecatedMultipartColumn width={4}>
            {renderActivation(yesterday)}
          </DeprecatedMultipartColumn>
          <DeprecatedMultipartColumn width={4}>
            {renderExpiration(yesterday)}
          </DeprecatedMultipartColumn>
        </DeprecatedMultipartRow>
        <FormGroupErrors fields={["expirationDate", "activationDate"]} />
      </FormGroup>
      <DeprecatedFormRow>{renderExpirationTimezone()}</DeprecatedFormRow>
    </div>
  );
}

export const SigningWindowReduxForm = compose(
  deprecatedSubForm<Props>({
    getValuesFor: ["activationTimezone", "activationDate", "expirationDate", "expirationTimezone"],
  }),
)(SigningWindowForm);
