import { memo, useState, useEffect, useRef, type ReactNode } from "react";
import { createPortal } from "react-dom";
import { useIntl, defineMessages } from "react-intl";
import classnames from "classnames";

import { focusForIOs } from "util/html";
import { DesignationContent, DesignationIcon } from "common/pdf/designation";
import { usePDFContext } from "common/pdf/pspdfkit";
import type {
  KitModule,
  KitAnnotationMixin,
  UserUpdateDecorationEvent,
  KitAnnotation,
} from "common/pdf/pspdfkit/util";
import { AnnotationDesignationType, AnnotationSubtype } from "graphql_globals";
import { MAX_CHECKMARK_SIZE_PT, MIN_CHECKMARK_SIZE_PT } from "common/pdf/util";
import { constrainSize } from "util/number";
import { useDesignationAddToGroupToolSelect } from "common/pdf/interaction/prep";
import type { DocumentForTransactionDetailsPDF_designations_edges_node as GroupDrawableDesignation } from "common/details/bundle/pspdfkit/index_fragment.graphql";
import { useMobileScreenClass } from "common/core/responsive";
import Icon from "common/core/icon";
import { IconButton } from "common/core/button/icon_button";

import type { PspdfkitDesignation as DrawableDesignation } from "./index_fragment.graphql";
import { DesignationTooltip } from "../designation_tooltip";
import { DesignationInstructionModal } from "../designation_instruction_modal";

export type DesignationCallbacks = {
  onUpdate?: (evt: UserUpdateDecorationEvent) => void;
  onDelete?: () => void;
  onReassign?: () => void;
  onAddToGroup?: () => void;
  onAddConditional?: () => void | null;
  onSetOptional?: () => void | null;
  showDisabledTooltip?: boolean | null;
  color?: string | null;
  isHighlighted?: boolean;
  dashedBorder?: boolean;
  isPrimaryConditional?: boolean;
};
export type DesignationUpdateArgs = DesignationCallbacks & { designation: DrawableDesignation };
type CreateConfig = {
  module: KitModule;
  options: DesignationUpdateArgs;
  mixin: KitAnnotationMixin;
};
export type DesignationOnReassign<D extends DrawableDesignation> = (designation: D) => void;
type FulfilledAnnotation = { id: string; text?: string; subtype: null | AnnotationSubtype };
export type DesignationOnFulfill<D extends DrawableDesignation> = (
  designation: D,
) => Promise<FulfilledAnnotation | typeof UNFULFILLED>;
type TooltipCallback<D extends DrawableDesignation> = (designation: D) => void;
export type DesignationOnUpdate<D extends DrawableDesignation> = (
  designation: D,
  evt: UserUpdateDecorationEvent,
) => void;
type Props<D extends DrawableDesignation> = {
  designation: D | GroupDrawableDesignation;
  color?: string | null;
  isPreview?: boolean;
  isHighlighted?: boolean;
  dashedBorder?: boolean;
  isPrimaryConditional?: boolean;
  conditionalEditMode?: boolean;
  disabledMessage?: ReactNode;
  locked?: boolean;
} & (
  | /** You must supply no callbacks at all, only an onFulfill, or an onUpdate without onFulfill. */
  {
      /** If falsy, the designation will be "disabled" styling. Returns a promise of the Annotation to focus. */
      onFulfill?: DesignationOnFulfill<D>;
      onUpdate?: never;
      onDelete?: TooltipCallback<D>;
      onReassign?: never;
      onAddConditional?: never;
      onSetOptional?: never;
    }
  | {
      onFulfill?: never;
      /** If falsy, the designation will be "disabled" styling. */
      onUpdate: DesignationOnUpdate<D>;
      onDelete?: TooltipCallback<D>;
      onReassign?: DesignationOnReassign<D>;
      onAddConditional?: TooltipCallback<D>;
      onSetOptional?: TooltipCallback<D>;
    }
);

type DesignationCustomData = {
  designationType: AnnotationDesignationType;
};

/** Use to indicate that an onFulfill callback didn't actually make an annotation */
export const UNFULFILLED = "not-fulfilled";

const LABELS = defineMessages({
  deleteLabel: {
    id: "cb053833-4a7b-4b46-b9ca-f5b8b4412454",
    defaultMessage: "Delete annotation designation",
  },
  placementLabel: {
    id: "a1cc67b1-08c4-400f-a9fa-9e0641cc8b2e",
    defaultMessage: "Place annotation: ",
  },
  disabledPlacementLabel: {
    id: "8781b8fa-f761-4c94-9657-5cb418d6a525",
    defaultMessage: "Disabled annotation: ",
  },
  [AnnotationDesignationType.FREE_TEXT]: {
    id: "a1cc67b1-08c4-400f-a9fa-9e0641cc8b2e",
    defaultMessage: "Place text annotation: ",
  },
  [AnnotationDesignationType.SIGNATURE]: {
    id: "ac73e606-f78f-48a9-a967-014ef183ab46",
    defaultMessage: "Place signature annotation: ",
  },
  [AnnotationDesignationType.INITIALS]: {
    id: "29a99e11-5b9e-4262-8d70-25c413d00206",
    defaultMessage: "Place initials annotation: ",
  },
});

const MESSAGES = defineMessages({
  [AnnotationDesignationType.RADIO_CHECKMARK]: {
    id: "05325fc6-266d-4ffd-af46-66f65b0c963c",
    defaultMessage: "Unchecked radio button",
  },
  [AnnotationDesignationType.CHECKMARK]: {
    id: "2cee570e-9214-47a9-8d8f-52651c407343",
    defaultMessage: "Unchecked checkbox",
  },
  showFieldInstruction: {
    id: "948f4435-aed9-4017-92c0-5fa7bada4fbe",
    defaultMessage: "Click to show designation instruction",
  },
});

function isDesignationCustomData(
  customData: KitAnnotation["customData"],
): customData is DesignationCustomData {
  return Boolean(customData && "designationType" in customData);
}

const FOCUS_AFTER_FULFILL_DESIGNATION_TYPES = Object.freeze(
  new Set([
    AnnotationDesignationType.ADDRESS1,
    AnnotationDesignationType.ADDRESS2,
    AnnotationDesignationType.CITY,
    AnnotationDesignationType.STATE,
    AnnotationDesignationType.ZIP,
    AnnotationDesignationType.DOB,
    AnnotationSubtype.FREE_TEXT,
  ]),
);

function isFocusAfterDesignationFulfillAnnotation(annotation: FulfilledAnnotation): boolean {
  return FOCUS_AFTER_FULFILL_DESIGNATION_TYPES.has(annotation.subtype!) && !annotation.text;
}

export function constrainDesignationSize({ boundingBox, customData }: KitAnnotation) {
  if (
    isDesignationCustomData(customData) &&
    (customData.designationType === AnnotationDesignationType.CHECKMARK ||
      customData.designationType === AnnotationDesignationType.RADIO_CHECKMARK)
  ) {
    return constrainSize({
      width: boundingBox.width,
      height: boundingBox.height,
      maxWidth: MAX_CHECKMARK_SIZE_PT,
      maxHeight: MAX_CHECKMARK_SIZE_PT,
      minWidth: MIN_CHECKMARK_SIZE_PT,
      minHeight: MIN_CHECKMARK_SIZE_PT,
      square: true,
    });
  }
}

export function buildDesignationCallbackPresenceForTooltips(
  callbacks: DesignationCallbacks | undefined,
) {
  // All the callback keys that change the presence of a tooltip button:
  return {
    hasDelete: Boolean(callbacks?.onDelete),
    hasReassign: Boolean(callbacks?.onReassign),
    hasAddToGroup: Boolean(callbacks?.onAddToGroup),
    hasAddConditional: Boolean(callbacks?.onAddConditional),
    hasSetOptional: Boolean(callbacks?.onSetOptional),
  };
}

export function createKitAnnotationForDesignation({ module, mixin, options }: CreateConfig) {
  const { designation } = options;
  const customData: DesignationCustomData = {
    designationType: designation.type,
    ...buildDesignationCallbackPresenceForTooltips(options),
  };
  const args = {
    ...mixin,
    strokeWidth: 0.01,
    opacity: 1,
    customData,
  };
  if (designation.type === AnnotationDesignationType.RADIO_CHECKMARK) {
    return new module.Annotations.EllipseAnnotation(args);
  }
  return new module.Annotations.RectangleAnnotation(args);
}

export const NON_TEXT_DESIGNATION_TYPES = Object.freeze(
  new Set([
    AnnotationDesignationType.SIGNATURE,
    AnnotationDesignationType.INITIALS,
    AnnotationDesignationType.CHECKMARK,
    AnnotationDesignationType.RADIO_CHECKMARK,
    AnnotationDesignationType.WHITEBOX,
    AnnotationDesignationType.SEAL,
    AnnotationDesignationType.NOTARY_SIGNATURE,
  ]),
);
function adjustForTextDesignation<D extends DrawableDesignation>(designation: D) {
  if (NON_TEXT_DESIGNATION_TYPES.has(designation.type)) {
    return designation;
  }

  return {
    ...designation,
    location: {
      ...designation.location,
      point: {
        ...designation.location.point,
        y: designation.location.point.y - designation.size.height,
      },
    },
  };
}

function AnnotationDesignation<D extends DrawableDesignation>({
  designation,
  onUpdate,
  onFulfill,
  onDelete,
  onReassign,
  onAddConditional,
  onSetOptional,
  color,
  isPreview,
  isHighlighted,
  dashedBorder,
  isPrimaryConditional,
  disabledMessage,
  locked,
}: Props<D>) {
  const [node, setNode] = useState<null | HTMLDivElement>(null);
  const [showInstructionModal, setShowInstructionModal] = useState(false);
  const { setFocused, addDesignation } = usePDFContext();
  const updateRef = useRef<null | ((args: DesignationUpdateArgs) => void)>(null);
  const lockedRef = useRef(false);
  const intl = useIntl();
  const isMobile = useMobileScreenClass();
  const drawableDesigation = designation as D;
  const groupDrawableDesignation = designation as GroupDrawableDesignation;

  const onDeleteCb = onDelete && (() => onDelete(drawableDesigation));
  const onReassignCb = onReassign && (() => onReassign(drawableDesigation));
  const onAddConditionalCb = drawableDesigation.optional
    ? onAddConditional && (() => onAddConditional(drawableDesigation))
    : undefined;
  const onSetOptionalCb =
    !designation.optional && designation.type !== AnnotationDesignationType.CHECKMARK
      ? onSetOptional && (() => onSetOptional(drawableDesigation))
      : undefined;
  const onAddToGroupCb = useDesignationAddToGroupToolSelect(groupDrawableDesignation);

  useEffect(() => {
    // This effect is designed to only run once per unique Designation. `addDesignation` changes every time
    // we load a new PDF and we expect the type to stay static to a particular designation. Any of these
    // things changing will cause a "flicker" as the designation is removed and then added again as the
    // new type.
    if (addDesignation) {
      const handles = addDesignation({
        designation,
        isPreview,
        onNodeCreate: setNode,
        onDelete: onDeleteCb,
        onReassign: onReassignCb,
        onAddToGroup: onAddToGroupCb,
        onAddConditional: onAddConditionalCb,
        onSetOptional: onSetOptionalCb,
      });
      updateRef.current = handles.update;
      return handles.destroy;
    }
    updateRef.current = null;
  }, [addDesignation, designation.type]);

  useEffect(() => {
    updateRef.current?.({
      designation,
      onUpdate: onUpdate && ((evt) => onUpdate(drawableDesigation, evt)),
      onDelete: onDeleteCb,
      onReassign: onReassignCb,
      onAddToGroup: onAddToGroupCb,
      onAddConditional: onAddConditionalCb,
      onSetOptional: onSetOptionalCb,
      color,
      isHighlighted,
      dashedBorder,
      isPrimaryConditional,
    });
  });

  if (!node) {
    return null;
  }
  const enabled = Boolean((onFulfill || onUpdate) && !locked);

  const instruction = drawableDesigation.instruction;
  const showInstructionToolTip = !isMobile && enabled && instruction;
  const showInstructionDrawer = isMobile && enabled && instruction;
  const instructionToolTipId = `designation-tooltip-instruction-${designation.id}`;

  function onAnnotationClick() {
    if (!onFulfill || lockedRef.current || locked) {
      return;
    }
    lockedRef.current = true;
    const isAnnotationWithFocusableInput = designation.type === AnnotationDesignationType.FREE_TEXT;
    if (setFocused && isAnnotationWithFocusableInput) {
      focusForIOs();
    }
    onFulfill(adjustForTextDesignation<D>(drawableDesigation))
      .then((annotation: FulfilledAnnotation | null | string) => {
        // We widden this type with null just in case there's some lurking JavaScript somewhere that isn't fully
        // type safe and resolves with falsy value. Can definitely be removed once Relay PDF code is removed.
        if (
          setFocused &&
          annotation &&
          typeof annotation !== "string" &&
          isFocusAfterDesignationFulfillAnnotation(annotation)
        ) {
          setFocused(annotation.id);
        }
      })
      .finally(() => {
        lockedRef.current = false;
      });
  }

  const renderedDesignation = (
    <>
      {showInstructionDrawer && (
        <IconButton
          className="Instruction-Icon Mobile-Button"
          onClick={() => setShowInstructionModal(true)}
          name="info"
          style={{ color: color || undefined }}
          label={intl.formatMessage(MESSAGES.showFieldInstruction)}
        />
      )}
      <button
        type="button"
        className={classnames(
          "Notarize-Designation",
          locked && "Notarize-Designation-Locked",
          // This class adds pointer events but only if we have `onFulfill`. If an `onUpdate` is present
          // instead, we must use the PSPDFKit default of no pointer events to allow the click through to
          // the rect PDF Annotation behind for drag and drop.
          onFulfill && "Notarize-Clickable-Noneditable",
          (disabledMessage || instruction) && "Notarize-Designation-Target",
        )}
        style={{
          color: color || undefined,
          cursor: enabled ? "pointer" : undefined,
          // allow PSPDFKit to handle zooming and panning instead of the browser
          touchAction: "none",
        }}
        aria-disabled={!enabled}
        aria-describedby={
          showInstructionToolTip || showInstructionDrawer ? instructionToolTipId : undefined
        }
        onClick={() => {
          onAnnotationClick();
        }}
      >
        <DesignationIcon designation={designation} isDisabled={!color} />
        <div className="Notarize-Designation-Content">
          <span className="Notarize-SROnly">
            {enabled
              ? intl.formatMessage(
                  designation.type === AnnotationDesignationType.FREE_TEXT ||
                    designation.type === AnnotationDesignationType.SIGNATURE ||
                    designation.type === AnnotationDesignationType.INITIALS
                    ? LABELS[designation.type]
                    : LABELS.placementLabel,
                )
              : intl.formatMessage(LABELS.disabledPlacementLabel)}
          </span>
          {showInstructionToolTip && (
            <Icon className="Instruction-Icon" name="info" style={{ color: color || undefined }} />
          )}
          <DesignationContent designation={designation} />
          {/* Screen reader label for radio and checkbox annotations because they have no native label */}
          {(designation.type === AnnotationDesignationType.CHECKMARK ||
            designation.type === AnnotationDesignationType.RADIO_CHECKMARK) && (
            <span className="Notarize-SROnly">
              {intl.formatMessage(MESSAGES[designation.type])}
            </span>
          )}
        </div>
        {!enabled && !locked && disabledMessage && (
          <DesignationTooltip message={disabledMessage} designation={drawableDesigation} />
        )}
        {showInstructionToolTip && (
          <DesignationTooltip
            id={instructionToolTipId}
            message={drawableDesigation.instruction}
            designation={drawableDesigation}
            className="Notarize-Designation-Tooltip-Instruction"
          />
        )}
      </button>
      {showInstructionModal && (
        <DesignationInstructionModal
          id={instructionToolTipId}
          instruction={drawableDesigation.instruction}
          onUnderstand={() => {
            setShowInstructionModal(false);
          }}
        />
      )}
      {/*
       * It is possible to want both delete and fulfill on a designation (notary, in particular). Because of that,
       * we cannot use the standard tooltip, since clicking does a fulfillment. Also we don't want the designation
       * to be update-able, which would be required to be seletable.
       */}
      {onFulfill && onDelete && (
        <button
          type="button"
          className="Notarize-Designation-Delete-Control"
          aria-label={intl.formatMessage(LABELS.deleteLabel)}
          onClick={() => onDelete(drawableDesigation)}
          style={{ color: color || undefined }}
        />
      )}
    </>
  );
  return createPortal(renderedDesignation, node);
}

function UnfulfilledDesignation<D extends DrawableDesignation>(props: Props<D>) {
  if (props.designation.fulfilled || props.designation.inProgress) {
    return null;
  }

  return <AnnotationDesignation<D> {...props} />;
}

const MemoizedAnnotationDesignation = memo(UnfulfilledDesignation) as typeof AnnotationDesignation;
export { MemoizedAnnotationDesignation as AnnotationDesignation };
export type { DrawableDesignation };
