import "./index.scss";

import { memo } from "react";
import { FormattedMessage } from "react-intl";

import { b } from "util/html";
import Icon from "common/core/icon";

import type { ActivityLog } from "../activity_log_fragment.graphql";

type EncompassAttachment = {
  name: string;
  is_active: boolean;
  is_pdf_attachment: boolean;
  is_image_attachment: boolean;
  created_at: string;
  does_file_exists: boolean;
  aws_key: string;
};
type EncompassDocument = {
  name: string;
  is_closing_document: boolean;
  attachments: EncompassAttachment[];
};
export type EncompassDocumentsNewActivityLogFragment = ActivityLog & {
  properties: {
    push_folders: PushFolder[];
    documents: EncompassDocument[];
    selected_documents: EncompassAttachment[];
  };
};

type Props = {
  activityLog: EncompassDocumentsNewActivityLogFragment;
};

export const EncompassNewDocumentsLabel = memo(() => {
  return (
    <FormattedMessage
      id="0f0b690f-6b0e-4df5-aa5f-6d89bc01fefb"
      defaultMessage="[ENCOMPASS] Documents"
    />
  );
});

export const EncompassNewDocumentsSummary = memo(
  ({
    activityLog: {
      properties: { selected_documents: selectedDocuments },
    },
  }: Props) => {
    return (
      <FormattedMessage
        id="13a6d192-a5f9-4f7d-a3d9-4b122ef9173a"
        defaultMessage="# Pulled Documents: {num}"
        values={{ num: selectedDocuments.length }}
      />
    );
  },
);

type PushFolder = {
  system_name: string;
  strategy: string;
  folder_name: string;
};

function getIcon(value: boolean) {
  return value ? <Icon name="success" /> : <Icon name="x-filled" />;
}

function attachmentsList(attachments: EncompassAttachment[]) {
  return (
    <ul className="ActivityLogItemDetailType--Encompass--DocumentsSubList">
      {attachments.map((attachment, index) => (
        <li key={index}>
          <FormattedMessage
            id="c2cf92aa-71ae-43e2-b752-067102d96891"
            defaultMessage='"{name}", active: {active}, pdf: {pdf}, image: {image}, has file: {hasFile}, created: {createdAt}, S3 key: {key}'
            values={{
              name: attachment.name,
              active: getIcon(attachment.is_active),
              pdf: getIcon(attachment.is_pdf_attachment),
              image: getIcon(attachment.is_image_attachment),
              hasFile: getIcon(attachment.does_file_exists),
              createdAt: attachment.created_at,
              key: attachment.aws_key,
            }}
          />
        </li>
      ))}
    </ul>
  );
}

function documentsList(documents: EncompassDocument[]) {
  return (
    <ul className="ActivityLogItemDetailType--Encompass--DocumentsList">
      {documents.map((document, index) => (
        <li key={index}>
          <FormattedMessage
            id="d0d23fe6-a0b9-4b4d-b1db-bb31a8769792"
            defaultMessage='"{name}", closing type: {icon}'
            values={{
              name: document.name,
              icon: getIcon(document.is_closing_document),
            }}
          />
          {document.is_closing_document && attachmentsList(document.attachments)}
        </li>
      ))}
    </ul>
  );
}

export default memo(
  ({
    activityLog: {
      properties: { push_folders: pushFolders, documents, selected_documents: selectedDocuments },
    },
  }: Props) => {
    return (
      <div className="ActivityLogItemDetailType ActivityLogItemDetailType--Encompass">
        <div>
          {pushFolders.map((pushFolder, index) => (
            <div key={index}>
              <div className="ActivityLogItemDetailType--Encompass--Item">
                <FormattedMessage
                  id="194a7360-6cc5-4718-8c1d-ac64ff7aa36b"
                  defaultMessage="<b>System Name:</b> {systemName}"
                  values={{ b, systemName: pushFolder.system_name }}
                />
              </div>
              <div className="ActivityLogItemDetailType--Encompass--Item">
                <FormattedMessage
                  id="194a7360-6cc5-4718-8c1d-ac64ff7aa36c"
                  defaultMessage="<b>Strategy:</b> {strategy}"
                  values={{ b, strategy: pushFolder.strategy }}
                />
              </div>
              <div className="ActivityLogItemDetailType--Encompass--Item">
                <FormattedMessage
                  id="194a7360-6cc5-4718-8c1d-ac64ff7aa36d"
                  defaultMessage="<b>Folder name:</b> {folderName}"
                  values={{ b, folderName: pushFolder.folder_name }}
                />
              </div>
            </div>
          ))}
        </div>
        <div className="ActivityLogItemDetailType--Encompass--Item">
          <FormattedMessage
            id="ba4c0af4-cfaf-4a92-93c0-cd58fe0f8363"
            defaultMessage="<b>All documents:</b>"
            values={{ b }}
          />
        </div>
        {documentsList(documents)}
        <div className="ActivityLogItemDetailType--Encompass--Item">
          <FormattedMessage
            id="2ff17301-623a-466e-8265-998e92317edc"
            defaultMessage="<b>Pulled documents:</b>"
            values={{ b }}
          />
        </div>
        {attachmentsList(selectedDocuments)}
      </div>
    );
  },
);
