import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useRef, type MutableRefObject, type ReactNode, useState, useEffect } from "react";
import classnames from "classnames";

import Button from "common/core/button";
import { downloadImageAsPNG, downloadSvgImage } from "util/canvas_text";
import TooltipOverlay from "common/core/tooltip/overlay";
import { useCopy } from "util/clipboard";
import { handleButtonKeyDown } from "util/keyboard_navigation";
import { ControlledTabRow } from "common/core/tabs/tab_button_row";
import TabButton from "common/core/tabs/tab_button_row/tab_button";
import { Heading, Paragraph } from "common/core/typography";
import { Card } from "common/core/card";
import TipWell from "common/core/tip_well";
import AlertMessage from "common/core/alert_message";
import { Hr } from "common/core/horizontal_rule";
import WorkflowModal from "common/modals/workflow_modal";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_SUBTYPES } from "constants/notifications";
import { ButtonStyledLink } from "common/core/button/button_styled_link";
import LoadingIndicator from "common/core/loading_indicator";
import EditPayment from "common/transactions/form/sub_forms/payment";
import { ErrorMessage } from "common/core/form/error";

import Styles from "./index.module.scss";

const QR_CODE_FILENAME = "qr_code";

const MESSAGES = defineMessages({
  success: {
    id: "ce95f15e-a2f3-4b41-aaa7-4e2f25c39323",
    defaultMessage:
      "{name, select, null {{type, select, referral {Campaign} other {Easylink}}} other {{name}}} has been {activated, select, true {activated} other {deactivated}}.",
  },
  bulkSuccess: {
    id: "14030d41-b094-4e1b-b0a3-adc92037fd30",
    defaultMessage:
      "{name, select, null {{type, select, referral {Campaign} other {Easylink}}} other {{name}}} and {number} {number, plural, one {other} other {others}} have been {activated, select, true {activated} other {deactivated}}.",
  },
  error: {
    id: "5d8c39e8-f4bf-4a0d-8176-80a9e31db377",
    defaultMessage: "Sorry, something went wrong. Please try again.",
  },
  updatePaymentSuccess: {
    id: "cf1b3999-72ba-4c06-af64-be2856ff22d7",
    defaultMessage: "Account payment method added successfully.",
  },
});

export function CopyableTextArea({
  value,
  buttonText,
  ariaLabel,
  onMount,
}: {
  buttonText?: ReactNode;
  ariaLabel: string;
  value: string | undefined;
  onMount?: () => void;
}) {
  const ref = useRef<HTMLInputElement & HTMLTextAreaElement>(null);
  const copier = useCopy();
  const copy = () => copier.copy(ref);

  useEffect(() => {
    onMount?.();
  }, []);

  if (!value) {
    return <LoadingIndicator positionRelative />;
  }

  const multiLine = value.length > 100;
  const InputType = multiLine ? "textarea" : "input";
  return (
    <>
      <div className={Styles.copyTextWrapper}>
        {copier.recentlyCopied && (
          <TooltipOverlay
            className={Styles.toolTip}
            id="copied"
            trigger="click"
            placement="top"
            size="mini"
          >
            <FormattedMessage id="a2cd622c-4c48-458a-9f7e-001a28665b30" defaultMessage="Copied!" />
          </TooltipOverlay>
        )}
        <InputType
          onKeyDown={(e) => handleButtonKeyDown(e, copy)}
          onClick={copy}
          className={Styles.shareSnippetText}
          ref={ref}
          value={value}
          data-automation-id="share-url-text-area"
          aria-label={ariaLabel}
        />
      </div>
      {buttonText && (
        <Button
          onClick={copy}
          withIcon={{ name: "copy", placement: "left" }}
          automationId="easylink-copy-button"
          buttonColor="action"
          variant="secondary"
        >
          {buttonText}
        </Button>
      )}
    </>
  );
}

export function GenerateQrCodeContent({
  name,
  qrStream,
  onMount,
}: {
  name: string | null;
  qrStream: string | undefined;
  onMount?: () => void;
}) {
  const ref = useRef<HTMLImageElement>() as MutableRefObject<HTMLImageElement>;

  useEffect(() => {
    onMount?.();
  }, []);

  if (!qrStream) {
    return <LoadingIndicator positionRelative />;
  }

  const svgDataUrlSrc = `data:image/svg+xml;base64,${btoa(qrStream)}`;
  return (
    <div className={Styles.qrCodeContainer}>
      <img
        className={Styles.qrCode}
        src={svgDataUrlSrc}
        alt={`qr code for link ${name}`}
        ref={ref}
      />
      <div className={Styles.downloadButtonContainer}>
        <Button
          onClick={() => {
            downloadImageAsPNG(ref.current, QR_CODE_FILENAME, 300, 300);
          }}
          buttonColor="action"
          variant="secondary"
          withIcon={{ name: "download", placement: "left" }}
        >
          <FormattedMessage
            id="ecd5fc22-b56d-4b5a-adae-33df1c9b8ded"
            defaultMessage="Download .png"
          />
        </Button>

        <Button
          onClick={() => {
            downloadSvgImage(svgDataUrlSrc, QR_CODE_FILENAME);
          }}
          buttonColor="action"
          variant="secondary"
          withIcon={{ name: "download", placement: "left" }}
        >
          <FormattedMessage
            id="ecd5fc22-b56d-4b5a-adae-33df1c9b8ded"
            defaultMessage="Download .svg"
          />
        </Button>
      </div>
    </div>
  );
}

type TipWellProps =
  | {
      tipWellHeader: ReactNode;
      tipWellContent: ReactNode;
      tipWellSpaced?: never;
    }
  | {
      tipWellHeader?: never;
      tipWellContent?: never;
      tipWellSpaced?: boolean;
    };

type CardDescriptionProps =
  | {
      cardDescription: ReactNode;
      // matches Paragraph text color styles
      cardDescriptionTextStyle?: "danger" | "white" | "subtle";
    }
  | {
      cardDescription?: ReactNode;
      cardDescriptionTextStyle?: never;
    };

export type FormCardProps = {
  children: ReactNode;
  cardTitle: ReactNode;
  disabled?: boolean;
  extraSpacing?: boolean;
  className?: string;
  errorMessage?: ReactNode;
} & TipWellProps &
  CardDescriptionProps;

export function FormCard({
  children,
  cardTitle,
  cardDescription,
  cardDescriptionTextStyle,
  tipWellHeader,
  tipWellContent,
  tipWellSpaced,
  disabled,
  extraSpacing,
  className,
  errorMessage,
}: FormCardProps) {
  return (
    <div className={classnames(className, Styles.cardContainer)}>
      <div>
        <Card
          className={classnames(Styles.card, disabled && Styles.disabled)}
          error={Boolean(errorMessage)}
          fullWidth
          noMargin
        >
          <Heading
            level="h2"
            textStyle="headingTwo"
            className={classnames(Styles.cardTitle, extraSpacing && Styles.cardTitleSpacing)}
          >
            {cardTitle}
          </Heading>

          {cardDescription && (
            <Paragraph
              textColor={cardDescriptionTextStyle}
              className={classnames(!disabled && Styles.cardDescription)}
            >
              {cardDescription}
            </Paragraph>
          )}

          {children}
        </Card>

        {errorMessage && <ErrorMessage className={Styles.cardError} message={errorMessage} />}
      </div>

      {tipWellHeader && tipWellContent ? (
        <TipWell className={Styles.cardTipwell} heading={tipWellHeader}>
          {tipWellContent}
        </TipWell>
      ) : (
        tipWellSpaced && <div className={`${Styles.pseudoTipwell} ${Styles.cardTipwell}`} />
      )}
    </div>
  );
}

type SecondarySubmitProps =
  | {
      secondarySubmitText: ReactNode;
      secondaryHandleSubmit: () => void;
    }
  | {
      secondarySubmitText?: never;
      secondaryHandleSubmit?: never;
    };

function FormPageFooter(props: { errorMessage?: ReactNode; children: ReactNode }) {
  return (
    <footer className={Styles.footer}>
      {props.errorMessage && (
        <AlertMessage kind="danger" centerText>
          {props.errorMessage}
        </AlertMessage>
      )}
      <div className={Styles.footerButtons}>{props.children}</div>
    </footer>
  );
}

export function FormPageLayout({
  children,
  heading,
  title,
  loading,
  submitText,
  submitDisabled,
  secondarySubmitText,
  onSubmit,
  secondaryHandleSubmit,
  cancelText,
  onCancel,
  errorMessage,
}: {
  children: ReactNode;
  heading: ReactNode;
  title: ReactNode;
  loading?: boolean;
  submitText: ReactNode;
  submitDisabled?: boolean;
  onSubmit: () => void;
  cancelText: ReactNode;
  onCancel: () => void;
  errorMessage?: ReactNode;
} & SecondarySubmitProps) {
  const [buttonClicked, setButtonClicked] = useState<"primary" | "secondary">();
  return (
    <div className={Styles.root}>
      <div className={Styles.container}>
        <div className={Styles.heading}>{heading}</div>
        <Heading className={Styles.title} level="h1" textStyle="headingThree">
          {title}
        </Heading>
        <Hr />

        {children}
      </div>

      <FormPageFooter errorMessage={errorMessage}>
        <ButtonStyledLink variant="tertiary" buttonColor="dark" onClick={onCancel}>
          {cancelText}
        </ButtonStyledLink>

        {secondarySubmitText && (
          <Button
            variant="secondary"
            buttonColor="action"
            disabled={submitDisabled || loading}
            isLoading={buttonClicked === "secondary" && loading}
            onClick={() => {
              setButtonClicked("secondary");
              secondaryHandleSubmit();
            }}
          >
            {secondarySubmitText}
          </Button>
        )}

        <Button
          variant="primary"
          buttonColor="action"
          disabled={submitDisabled || loading}
          isLoading={buttonClicked === "primary" && loading}
          onClick={() => {
            setButtonClicked("primary");
            onSubmit();
          }}
        >
          {submitText}
        </Button>
      </FormPageFooter>
    </div>
  );
}

export function DashboardTabs({
  activeTab,
  tabs,
  onSelect,
  ...props
}: {
  activeTab: string | null;
  onSelect(tab: string): void;
  tabs: { tabLabel: ReactNode; count: number; value: string }[];
  "aria-label": string;
}) {
  const activeTabIndex = tabs.findIndex((tab) => tab.value === activeTab);
  return (
    <ControlledTabRow
      // Active tab state based on query param that may not be present at initial mount. If not present, default to first tab.
      activeTabIndex={activeTabIndex === -1 ? 0 : activeTabIndex}
      className={Styles.dashboardTabs}
      onSelect={(index) => onSelect(tabs[index].value)}
      {...props}
    >
      {tabs.map((tab, i) => (
        <TabButton
          automationId={`referrals-${tab.value}-tab`}
          key={i}
          title={
            <>
              {tab.tabLabel} <span>({tab.count})</span>
            </>
          }
        />
      ))}
    </ControlledTabRow>
  );
}

type DeactivateConfirmationModalProps = {
  showAlert: boolean;
  onCancel: () => void;
  onConfirm: () => Promise<unknown>;
  msg: string;
  errorMsg: string;
};

export function DeactivateConfirmationModal({
  onCancel,
  onConfirm,
  showAlert,
  errorMsg,
  msg,
}: DeactivateConfirmationModalProps) {
  return (
    <WorkflowModal
      closeBehavior={{ tag: "with-button", disableClickOutside: true, onClose: onCancel }}
      title={
        <FormattedMessage
          id="3d852bd0-8b35-477c-9d7b-19e787f8ac89"
          defaultMessage="Are you sure?"
        />
      }
      large
      footerSeparator={false}
      buttons={[
        <Button buttonColor="action" variant="tertiary" key="cancel" onClick={onCancel}>
          <FormattedMessage id="c6075cca-db32-4fb9-903c-7a90664f51b8" defaultMessage="Cancel" />
        </Button>,
        <Button
          role="button"
          key="deactivate"
          buttonColor="action"
          variant="primary"
          onClick={onConfirm}
        >
          <FormattedMessage id="29104a61-8284-481d-839b-c8900899d21d" defaultMessage="Deactivate" />
        </Button>,
      ]}
    >
      {msg}
      {showAlert && (
        <AlertMessage className={Styles.deactivateAlertBanner} kind="danger">
          {errorMsg}
        </AlertMessage>
      )}
    </WorkflowModal>
  );
}

type DeactivateModalProps = {
  idToDeactivate: string | null;
  onDeactivate: (id: string, active: boolean) => Promise<unknown>;
};

type DeactivateModalSharedProps = {
  setIdsToDeactivate: (items: string[] | null) => void;
  sendActivateOrDeactivateToast: (items: string[], isSuccess: boolean) => void;
  warningMsg: string;
};

export function DeactivateModal({
  setIdsToDeactivate,
  idToDeactivate,
  onDeactivate,
  sendActivateOrDeactivateToast,
  warningMsg,
}: DeactivateModalProps & DeactivateModalSharedProps) {
  const intl = useIntl();
  const [showAlert, setShowAlert] = useState(false);
  const onCancel = () => {
    setShowAlert(false);
    setIdsToDeactivate(null);
  };
  const onConfirm = async () => {
    try {
      await onDeactivate(idToDeactivate!, false);
      sendActivateOrDeactivateToast([idToDeactivate!], true);
      onCancel();
    } catch {
      setShowAlert(true);
    }
  };
  return (
    <DeactivateConfirmationModal
      onCancel={onCancel}
      onConfirm={onConfirm}
      showAlert={showAlert}
      msg={warningMsg}
      errorMsg={intl.formatMessage(MESSAGES.error)}
    />
  );
}

export function useActivateOrDeactivateToast({
  items,
  action,
  type,
}: {
  items: { id: string; name: string | null }[];
  action: "activate" | "deactivate";
  type: "referral" | "easylink";
}) {
  const intl = useIntl();

  function sendActivateOrDeactivateToast(itemIds: string[], isSuccess: boolean) {
    const [firstId, ...restIds] = itemIds;
    const firstItem = items.find((item) => item.id === firstId);
    const isBulk = restIds.length > 0;
    return isSuccess
      ? isBulk
        ? pushNotification({
            position: "topCenter",
            message: intl.formatMessage(MESSAGES.bulkSuccess, {
              name: firstItem?.name,
              activated: action === "activate",
              number: restIds.length,
              type,
            }),
          })
        : pushNotification({
            position: "topCenter",
            message: intl.formatMessage(MESSAGES.success, {
              name: firstItem?.name,
              activated: action === "activate",
              type,
            }),
          })
      : pushNotification({
          subtype: NOTIFICATION_SUBTYPES.ERROR,
          position: "topCenter",
          message: intl.formatMessage(MESSAGES.error),
        });
  }

  return sendActivateOrDeactivateToast;
}

export function AddPaymentMethodWarning(props: {
  render: (args: { addPayment: () => void }) => ReactNode;
}) {
  const intl = useIntl();
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  return (
    <>
      {props.render({ addPayment: () => setShowPaymentModal(true) })}

      {showPaymentModal && (
        <EditPayment
          collectOrgPaymentInfo
          onCancel={() => setShowPaymentModal(false)}
          onComplete={() => {
            pushNotification({
              position: "topCenter",
              message: intl.formatMessage(MESSAGES.updatePaymentSuccess),
            });
            setShowPaymentModal(false);
          }}
        />
      )}
    </>
  );
}

export function RadioGroupAddPaymentMethodWarning() {
  return (
    <AddPaymentMethodWarning
      render={({ addPayment }) => (
        <div className={Styles.addPaymentMethodWarning}>
          <FormattedMessage
            id="9dddb268-a3ec-42ac-aa4f-5045e22d53e8"
            defaultMessage={
              "<addPaymentLink>Add a payment method</addPaymentLink> to your account to select this option."
            }
            values={{
              addPaymentLink: (text) => (
                <Button
                  buttonSize="condensed"
                  buttonColor="action"
                  variant="tertiary"
                  // [DSP-332] Add inline prop to Button
                  className={Styles.addPaymentButton}
                  onClick={addPayment}
                >
                  {text}
                </Button>
              ),
            }}
          />
        </div>
      )}
    />
  );
}
