import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useEffect, useState, type ReactNode } from "react";
import { useSearchParams } from "react-router-dom";

import { useDispatch } from "redux/util";
import { forgotPassword } from "redux/actions/authentication";
import AppSubdomains from "constants/app_subdomains";
import {
  FormattedFieldError,
  FieldErrorMessage,
  isAriaInvalid,
  emailPatternValidation,
  defaultRequiredMessage,
} from "common/core/form/error";
import ContentDivider from "common/core/content_divider";
import { StyledTextInput } from "common/core/form/text";
import { useForm } from "common/core/form";
import Link from "common/core/link";
import { Paragraph, Heading } from "common/core/typography";
import {
  TITLE_PRICING_URL,
  BUSINESS_PRICING_URL,
  NOTARY_MARKETING_RESOURCE_URL,
  PROOF_LENDER_PRICING_URL,
} from "constants/marketing";
import { getAuthenticationTypes } from "common/account/login/util";
import type { AuthCode } from "common/account/google_signin_button";
import { redirectUrl } from "util/application_redirect";
import TosMessage from "common/account/login/tos_message";
import { useCreatePasswordRedesignEnabled } from "common/account/util";
import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";
import type Apps from "constants/applications";

import ProofCard from "../card";
import type { PasswordScreenType, MfaData } from "./password";
import { type SSOScreenType, ErrorMessage as SamlErrorMessage } from "./saml";
import Styles from "./index.module.scss";
import {
  FooterLink,
  Continue,
  LoginHeader,
  SignInWithGoogle,
  useIsGoogleSignInEnabled,
  type SignUpScreenType,
} from "./common";
import type { ResetPasswordScreenType } from "./reset_password";
import type { MfaScreenType } from "./mfa";
import type { CreatePasswordScreenType } from "./create_password";
import type { VerifyEmailScreenType } from "./verify_email";
import type { VerifyEmailCodeScreenType } from "./verify_email_code";

export type FormValues = {
  email: string;
  password: string;
};
export type EmailScreenType = {
  type: "email";
  email?: string;
};
export type OnAuthenticationFailed = (authInfo: {
  withEmail: boolean;
  withPassword: boolean;
  withGoogle: boolean;
  error: unknown;
}) => void;

type NextScreen =
  | SSOScreenType
  | PasswordScreenType
  | ResetPasswordScreenType
  | MfaScreenType
  | CreatePasswordScreenType
  | VerifyEmailScreenType
  | VerifyEmailCodeScreenType
  | SignUpScreenType;
type Props = {
  email?: string;
  onGoogleSignIn: (authCode: AuthCode) => void;
  onPasswordLogin: (
    values: FormValues & { passwordlessEnabled?: boolean },
  ) => Promise<MfaData | undefined>;
  onNextScreen: (screen: NextScreen) => void;
  hideFooter?: boolean;
  showCard?: boolean;
  allowCreatePassword?: boolean;
  passwordlessEnabled?: boolean;
  bundleId?: string;
  wrapper?: (children: ReactNode) => ReactNode;
  showTos: boolean;
  disableEmail?: boolean;
  onAuthenticationFailed?: OnAuthenticationFailed;
  signUpPortal?: (typeof Apps)[keyof typeof Apps] | null;
};

const MESSAGES = defineMessages({
  emailLabel: {
    id: "65624fc5-a0a4-42ea-b7b1-595415053328",
    defaultMessage: "Email address",
  },
  passwordLabel: {
    id: "befc012e-9046-460f-b2c9-f16515ed0f34",
    defaultMessage: "Password",
  },
  invalidError: {
    id: "690bf7c4-9970-448e-8688-d5f56f2ad81b",
    defaultMessage: "Email or password is invalid",
  },
});

const LABEL_MESSAGES = defineMessages({
  emailLabel: {
    id: "94fb6364-09a7-4ab1-8b51-a5f95c897580",
    defaultMessage: "Account email address",
  },
});

const EmailForm = ({
  email,
  onGoogleSignIn,
  onPasswordLogin,
  onNextScreen,
  allowCreatePassword,
  passwordlessEnabled,
  bundleId,
  wrapper,
  showTos,
  disableEmail = false,
  onAuthenticationFailed,
  signUpPortal,
}: Omit<Props, "hideFooter">) => {
  const intl = useIntl();
  const { useDocumentEntitler } = useA11y();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [samlError, setSamlError] = useState<string | null>(searchParams.get("samlError"));
  const [authTypeError, setAuthTypeError] = useState<boolean>(false);
  const isGoogleSignInEnabled = useIsGoogleSignInEnabled();
  const createPasswordRedesignEnabled = useCreatePasswordRedesignEnabled();

  const isOktaPluginLogin =
    (document.referrer.startsWith("https://notarize.okta.com") ||
      document.referrer.startsWith("https://proof.okta.com")) &&
    !searchParams.get("samlError");

  const { handleSubmit, register, formState, setFocus, setError } = useForm<FormValues>({
    mode: "all",
    defaultValues: {
      email,
      password: "",
    },
  });

  useDocumentEntitler({
    priority: "page",
    title: intl.formatMessage(useDocumentTitles().signInOrSignUp),
  });

  const { errors, isSubmitted } = formState;

  const onEmailSubmit = async (formValues: FormValues) => {
    if (isOktaPluginLogin) {
      try {
        const mfaData = await onPasswordLogin({
          email: formValues.email,
          password: formValues.password,
        });
        if (mfaData) {
          onNextScreen({
            type: "mfa",
            authOptions: mfaData.auth_options,
            email: formValues.email,
            password: formValues.password,
          });
        }
      } catch {
        setError("password", {
          message: intl.formatMessage(MESSAGES.invalidError),
        });
      }
      return;
    }
    try {
      const response = await getAuthenticationTypes({ email: formValues.email });
      if (response.authenticationTypes.includes("sso")) {
        setSamlError(null);
        onNextScreen({
          type: "sso",
          email: formValues.email,
          ssoProvider: response.ssoProvider!,
          passwordAuthEnabled: response.authenticationTypes.includes("password"),
        });
      } else if (response.authenticationTypes.includes("email") && createPasswordRedesignEnabled) {
        // user signed up but does not have a password set and verification code enabled
        onNextScreen({
          type: "verify_email_code",
          email: formValues.email,
        });
      } else if (response.authenticationTypes.includes("email") && wrapper) {
        // user signed up but does not have a password set
        onNextScreen({
          type: "verify_email",
          email: formValues.email,
          bundleId,
        });
      } else if (response.authenticationTypes.includes("email")) {
        dispatch(forgotPassword(formValues.email));
        onNextScreen({ type: "reset_password", email: formValues.email });
      } else if (
        !response.authenticationTypes.includes("password") &&
        allowCreatePassword &&
        !passwordlessEnabled
      ) {
        onNextScreen({
          type: "create_password",
          email: formValues.email,
          password: formValues.password,
        });
      } else if (!response.authenticationTypes.length && passwordlessEnabled) {
        await onPasswordLogin({
          email: formValues.email,
          password: "",
          passwordlessEnabled,
        });
      } else if (!response.authenticationTypes.length && Boolean(signUpPortal)) {
        onNextScreen({ type: "signup", email: formValues.email });
      } else {
        onNextScreen({ type: "password", email: formValues.email, password: formValues.password });
      }
    } catch (error) {
      setAuthTypeError(true);
      onAuthenticationFailed?.({
        withEmail: !!email,
        withPassword: false,
        withGoogle: false,
        error,
      });
    }
  };

  const invalid = isSubmitted && !!(errors.email || errors.password);

  useEffect(() => {
    setFocus("email");
  }, [setFocus]);

  return (
    <>
      <div className={Styles.formContainer}>
        <form
          onSubmit={handleSubmit(onEmailSubmit)}
          noValidate
          data-automation-id="authentication-form"
        >
          <div className={Styles.formContainer}>
            <StyledTextInput
              aria-invalid={isAriaInvalid(invalid)}
              label={intl.formatMessage(MESSAGES.emailLabel)}
              aria-label={intl.formatMessage(LABEL_MESSAGES.emailLabel)}
              data-automation-id="email-field"
              type="email"
              autoComplete="email"
              {...register("email", {
                required: defaultRequiredMessage(intl),
                pattern: emailPatternValidation(intl),
              })}
              {...(disableEmail && {
                disabled: true,
                "aria-invalid": false,
                autoComplete: undefined,
              })}
            />
            {/* Hidden for Autofill/Password Managers, but not Okta Plugin: RGB-2216, SE-3293
      -- It is **required** that the following input has attribute type="password"
      -- for the Okta Plugin to be able to enter the user's password
      */}
            {isOktaPluginLogin && (
              <div className={Styles.passwordSection}>
                <StyledTextInput
                  label={intl.formatMessage(MESSAGES.passwordLabel)}
                  aria-label={intl.formatMessage(MESSAGES.passwordLabel)}
                  autoComplete="current-password"
                  aria-invalid={isAriaInvalid(errors.password)}
                  type="password"
                  {...register("password")}
                />
              </div>
            )}
            {invalid && (
              <div className={Styles.formError}>
                {errors.email && <FormattedFieldError inputName="email" error={errors.email} />}
                {errors.password && (
                  <FormattedFieldError inputName="password" error={errors.password} />
                )}
              </div>
            )}
          </div>

          <Continue />
          {authTypeError && (
            <FieldErrorMessage
              className={Styles.formError}
              inputName="email"
              message="* Invalid email"
            />
          )}
        </form>
        {showTos && <TosMessage size="small" />}
      </div>
      {samlError && <SamlErrorMessage errorMsg={samlError} />}
      {isGoogleSignInEnabled && (
        <>
          <ContentDivider />
          <SignInWithGoogle onGoogleSignIn={onGoogleSignIn} />
        </>
      )}
    </>
  );
};

function EmailScreenCard({
  onGoogleSignIn,
  email,
  onPasswordLogin,
  onNextScreen,
  hideFooter = false,
  allowCreatePassword,
  passwordlessEnabled,
  onAuthenticationFailed,
  showTos,
  signUpPortal,
}: Props) {
  return (
    <ProofCard
      header={<LoginHeader liveRegion="polite" />}
      body={
        <EmailForm
          {...{
            onGoogleSignIn,
            email,
            onPasswordLogin,
            onNextScreen,
            allowCreatePassword,
            passwordlessEnabled,
            onAuthenticationFailed,
            showTos,
            signUpPortal,
          }}
        />
      }
      footer={
        !hideFooter && (
          <>
            <Heading level="h3" textStyle="subtitleSmall" className={Styles.footerSection}>
              <FormattedMessage
                id="33d63131-7270-4c10-bf35-798e5112d690"
                defaultMessage="Don't have an account? Get started below."
              />
            </Heading>
            <Paragraph className={Styles.footerSection}>
              <FormattedMessage
                id="5e5a6bd1-023d-4330-99d8-795ed799a8c3"
                defaultMessage="<link>Notarize a document</link> in 15 minutes. Anywhere, anytime."
                values={{
                  link: (txt) => (
                    <Link href={redirectUrl(AppSubdomains.customer, "/signup/upload")}>{txt}</Link>
                  ),
                }}
              />
            </Paragraph>
            <div className={Styles.footerSection}>
              <FormattedMessage
                id="828668a5-36ca-4b14-9c01-2f04e27b64fb"
                defaultMessage="Explore our business, real estate, and notary offerings."
              />
              <Paragraph className={Styles.footerSubSection}>
                <FooterLink className={Styles.footerPortalLink} href={BUSINESS_PRICING_URL}>
                  <FormattedMessage
                    id="1b6b4837-fc73-4bd9-bd8e-3ceab56c4782"
                    defaultMessage="Businesses"
                  />
                </FooterLink>
                <FooterLink className={Styles.footerPortalLink} href={TITLE_PRICING_URL}>
                  <FormattedMessage
                    id="1b6b4837-fc73-4bd9-bd8e-3ceab56c4782"
                    defaultMessage="Title agents"
                  />
                </FooterLink>
                <FooterLink className={Styles.footerPortalLink} href={PROOF_LENDER_PRICING_URL}>
                  <FormattedMessage
                    id="1b6b4837-fc73-4bd9-bd8e-3ceab56c4782"
                    defaultMessage="Mortgage lenders"
                  />
                </FooterLink>
                <FooterLink
                  className={Styles.footerPortalLink}
                  href={NOTARY_MARKETING_RESOURCE_URL}
                >
                  <FormattedMessage
                    id="1b6b4837-fc73-4bd9-bd8e-3ceab56c4782"
                    defaultMessage="Notaries"
                  />
                </FooterLink>
              </Paragraph>
            </div>
          </>
        )
      }
    />
  );
}

export default function EmailScreen(props: Props) {
  const { showCard, wrapper } = props;

  if (showCard) {
    return <EmailScreenCard {...props} />;
  }

  return <>{wrapper ? wrapper(<EmailForm {...props} />) : <EmailForm {...props} />}</>;
}
