import "./index.scss";

import { Component } from "react";
import { injectIntl, defineMessages } from "react-intl";
import PropTypes from "prop-types";

import compose from "util/compose";
import FormGroupErrors from "common/form/group_errors";
import FormGroup from "common/form/group";
import { DeprecatedMultipartRow } from "common/form/inputs/multipart/row";
import { DeprecatedMultipartColumn } from "common/form/inputs/multipart/column";
import { validatePresence } from "validators/form";
import { composeValidators } from "util/form";
import { deprecatedSubForm } from "common/form/enhancers/sub_form";
import { DeprecatedTextField } from "common/form/fields/text";

export const validationRules = () =>
  composeValidators(
    // presence
    validatePresence({ field: "firstName", label: "First Name" }),
    validatePresence({ field: "lastName", label: "Last Name" }),
  );

const messages = defineMessages({
  name: {
    id: "cf93e293-3c00-47ce-8d2c-4f92bde1282e",
    defaultMessage: "Name",
  },
  first: {
    id: "842380f2-5320-4a0f-a144-2b79405e0928",
    defaultMessage: "First",
  },
  middle: {
    id: "d2fc050c-631c-4c4f-9c06-a52890a42064",
    defaultMessage: "Middle",
  },
  last: {
    id: "3f282a72-0c2c-4af6-95b3-b82ddc29a047",
    defaultMessage: "Last",
  },
  ariaFirst: {
    id: "73f64827-af0f-4f6b-9c50-5e8c4369daa3",
    defaultMessage: "First Name",
  },
  ariaMiddle: {
    id: "1f8c545c-c798-493c-a7a8-50246abda723",
    defaultMessage: "Middle Name",
  },
  ariaLast: {
    id: "767e618f-d0e5-47ab-bf8d-2b396eefb1c8",
    defaultMessage: "Last Name",
  },
  fullNameInputWarningMessage: {
    id: "4e96baf3-f7ac-435a-8fb4-64dc33700b7a",
    defaultMessage: "Enter your legal name as it appears on your ID.",
  },
});

class FullNameSubForm extends Component {
  componentDidMount() {
    const { user } = this.props;

    if (user) {
      const { firstName, middleName, lastName } = user;

      this.props.initialize({
        firstName,
        middleName,
        lastName,
      });
    }
  }

  render() {
    const { intl, hideLabel } = this.props;

    return (
      <FormGroup fields={["firstName", "middleName", "lastName"]}>
        {!hideLabel && (
          <label aria-describedby="full-name-instructions">
            {intl.formatMessage(messages.name)}
          </label>
        )}

        <DeprecatedMultipartRow>
          <DeprecatedMultipartColumn width={5}>
            <DeprecatedTextField
              aria-label={intl.formatMessage(messages.ariaFirst)}
              id="firstName"
              name="firstName"
              type="text"
              placeholder={intl.formatMessage(messages.first)}
              placeholderAsLabel
              useStyledInput
              component="input"
              data-automation-id="first-name-field"
              // https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion
              autoComplete="nope"
            />
          </DeprecatedMultipartColumn>

          <DeprecatedMultipartColumn width={3}>
            <DeprecatedTextField
              aria-label={intl.formatMessage(messages.ariaMiddle)}
              id="middleName"
              name="middleName"
              type="text"
              placeholder={intl.formatMessage(messages.middle)}
              placeholderAsLabel
              useStyledInput
              component="input"
              data-automation-id="middle-name-field"
              // https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion
              autoComplete="nope"
            />
          </DeprecatedMultipartColumn>

          <DeprecatedMultipartColumn width={5}>
            <DeprecatedTextField
              aria-label={intl.formatMessage(messages.ariaLast)}
              id="lastName"
              name="lastName"
              type="text"
              placeholder={intl.formatMessage(messages.last)}
              placeholderAsLabel
              useStyledInput
              component="input"
              data-automation-id="last-name-field"
              // https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion
              autoComplete="nope"
            />
          </DeprecatedMultipartColumn>
        </DeprecatedMultipartRow>
        <div id="full-name-instructions" className="fullNameInputWarningMessage">
          {intl.formatMessage(messages.fullNameInputWarningMessage)}
        </div>

        <FormGroupErrors fields={["firstName", "middleName", "lastName"]} />
      </FormGroup>
    );
  }
}

FullNameSubForm.defaultProps = {
  hideLabel: false,
};

FullNameSubForm.propTypes = {
  user: PropTypes.object,
  hideLabel: PropTypes.bool,
};

/** @deprecated - please use components in common/core/form */
export const DeprecatedFullNameSubForm = compose(
  deprecatedSubForm({
    getValuesFor: ["firstName", "middleName", "lastName"],
  }),
  injectIntl,
)(FullNameSubForm);
