import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { defineMessages, useIntl } from "react-intl";

import { DetailsLayout, DetailsHeader } from "common/details/tokens";
import LoadingIndicator from "common/core/loading_indicator";
import { userFullName } from "util/user";
import { getParentPathByOffset } from "util/location";
import { useQuery } from "util/graphql";
import { useProofDefend } from "util/feature_detection";

import { Body } from "./body";
import { ContactInfo } from "./contact_info";
import IdentityCRMProfileQuery, {
  type IdentityCRMProfile_organization_Organization as Organization,
} from "./index.query.graphql";
import Styles from "./index.module.scss";

const MESSAGES = defineMessages({
  breadcrumbLabel: {
    id: "ca44ebb0-f80b-45a1-a7f4-133b74e193ff",
    defaultMessage: "Identities breadcrumbs",
  },
  identitiesBreadcrumbLabel: {
    id: "47553bc9-ac51-4c9f-93f2-1b8eef5ab271",
    defaultMessage: "Identities",
  },
  currentPageBreadcrumbLabel: {
    id: "a20371d8-60a8-4d68-80d5-fb6bd9945b69",
    defineMessage: "{name} identity profile",
  },
});

export function IdentityCRMProfile({ organizationId }: { organizationId: string }) {
  const intl = useIntl();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { userId } = useParams();
  const identitiesTablePath = getParentPathByOffset(pathname, 1);

  const { data, loading } = useQuery(IdentityCRMProfileQuery, {
    variables: { organizationId, userId: userId ?? "" },
  });

  const organization = data?.organization as Organization | null;
  const organizationCustomer = organization?.organizationCustomer;

  const proofDefend = useProofDefend(organization);

  useEffect(() => {
    if ((!loading && !organizationCustomer) || !userId) {
      navigate(identitiesTablePath);
    }
  }, [loading, organizationCustomer, userId, navigate]);

  if (loading || !organizationCustomer || !userId) {
    return <LoadingIndicator />;
  }

  const name = userFullName(organizationCustomer);

  return (
    <DetailsLayout.Container>
      <DetailsHeader.Container>
        <DetailsHeader.Info>
          <DetailsHeader.Breadcrumbs
            size="small"
            breadcrumbLabel={intl.formatMessage(MESSAGES.breadcrumbLabel)}
            breadcrumbItems={[
              {
                label: intl.formatMessage(MESSAGES.identitiesBreadcrumbLabel),
                url: identitiesTablePath,
              },
              {
                label: organizationCustomer.userId,
                url: pathname,
                optionalA11yLabel: intl.formatMessage(MESSAGES.currentPageBreadcrumbLabel, {
                  name,
                }),
              },
            ]}
            clipboardValue={organizationCustomer.userId}
          />
          <DetailsHeader.Heading>{name}</DetailsHeader.Heading>
          <ContactInfo organizationCustomer={organizationCustomer} />
        </DetailsHeader.Info>
      </DetailsHeader.Container>
      <DetailsLayout.Body>
        <DetailsLayout.Row className={Styles.container}>
          <section className={Styles.body}>
            <Body
              organizationId={organizationId}
              organizationCustomer={organizationCustomer}
              proofDefend={proofDefend}
            />
          </section>
        </DetailsLayout.Row>
      </DetailsLayout.Body>
    </DetailsLayout.Container>
  );
}
