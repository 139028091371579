import { FormattedMessage, FormattedNumber } from "react-intl";

import { FormattedDuration } from "common/core/format/date";
import { userFullName } from "util/user";
import RiskLevel from "common/dashboard/risk_level";

import type { IdentityCRMOrganizationCustomer as OrganizationCustomer } from "./organization_customer.fragment.graphql";

export const RecipientColumn = Object.freeze({
  Header: <FormattedMessage id="5f0277e9-959e-4cda-8aec-5fce4e991af1" defaultMessage="Recipient" />,
  render: (customer: OrganizationCustomer) => userFullName(customer, ""),
});

export const EmailColumn = Object.freeze({
  Header: <FormattedMessage id="a9de5fbd-8b41-4070-8ac3-fec25dc68ffa" defaultMessage="Email" />,
  render: (customer: OrganizationCustomer) => customer.email,
});

export const LastActiveColumn = Object.freeze({
  Header: (
    <FormattedMessage id="c5629538-d06e-457e-9173-843cd5acafae" defaultMessage="Last Active" />
  ),
  render: (customer: OrganizationCustomer) => (
    <FormattedDuration from={customer.lastActive} includeSeconds={false} />
  ),
});

export const TotalTransactionsColumn = Object.freeze({
  Header: (
    <FormattedMessage id="7c42cec2-14f4-4957-b4a1-0614bb3f2371" defaultMessage="Transactions" />
  ),
  render: (customer: OrganizationCustomer) => (
    <FormattedNumber value={customer.totalTransactions ?? 0} />
  ),
});

export const RiskColumn = Object.freeze({
  Header: <FormattedMessage id="78e7cd36-c972-41f2-8f92-31e3e60ed52f" defaultMessage="Risk" />,
  render: (customer: OrganizationCustomer) => (
    <RiskLevel level={customer.riskLevel} textLength="medium" />
  ),
});
