import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import getUnicodeFlagIcon from "country-flag-icons/unicode";

import { format } from "common/core/format/date";
import { Heading, Substyle } from "common/core/typography";
import Env from "config/environment";
import Icon from "common/core/icon";
import { useMatchScreenClass } from "common/core/responsive";
import Tooltip from "common/core/tooltip";
import { COUNTRIES } from "constants/countries";
import { DeviceType } from "graphql_globals";

import {
  type IdentityDetails_documentBundle_DocumentBundle_organizationTransaction_customerSigners_signerIdentities_identityAttributes_IdentityAttributeHomeAddress_homeAddressValue as HomeAddressValue,
  type IdentityDetails_documentBundle_DocumentBundle_organizationTransaction_customerSigners_signerIdentities_ipAddressLogs as IpAddressLogs,
} from "../index.query.graphql";
import { SensitiveValue } from "..";
import CommonStyles from "../index.module.scss";
import Styles from "./ip_address_section.module.scss";

const MESSAGES = defineMessages({
  mapAlt: {
    id: "55ce973c-8a0c-4b0d-ad73-327ca4701973",
    defaultMessage: "Map showing IP Addresses locations",
  },
});

type Props = {
  ips: IpAddressLogs[];
  homeAddress?: HomeAddressValue;
};

function IpDeviceIcon(ipInfo: IpAddressLogs) {
  const iconName = ipInfo.deviceType === DeviceType.MOBILE ? "device-phone" : "connect";
  return <Icon className={Styles.ipIcon} name={iconName} />;
}

function IpAddress(ipInfo: IpAddressLogs) {
  return <span>{ipInfo.ipAddress}</span>;
}

function CountryFlag(ipInfo: IpAddressLogs) {
  const countryCode = ipInfo.ipAddressLocation?.country;
  // The library doesn't do a string length check and crashes on shorter strings
  if (countryCode && countryCode.length >= 2) {
    return <span>{getUnicodeFlagIcon(countryCode)}</span>;
  }
  return null;
}

function IpCity(ipInfo: IpAddressLogs) {
  const { city, country, state } = ipInfo.ipAddressLocation ?? {};
  if (!city || !country) {
    return (
      <FormattedMessage
        id="a5f2a2e7-d4f6-4ec6-850e-eff954daf345"
        defaultMessage="Unknown location"
      />
    );
  }

  return (
    <span>
      {city}, {country.toUpperCase() === "US" ? state : countryCodeToCountryName(country || "")}
    </span>
  );
}

function FormatDate(ipInfo: IpAddressLogs) {
  return <span>{format({ value: ipInfo.recordedAt, formatStyle: "MM/dd/yy 'at' h:mmaa z" })}</span>;
}

const mapMarkerHomeUrl = "https://static.proof.com/maps/mapmarker_home.png";
const mapMarkerPhoneUrl = "https://static.proof.com/maps/mapmarker_phone.png";
const mapMarkerDesktopUrl = "https://static.proof.com/maps/mapmarker_desktop.png";

function createMarkerParam(location: string, iconUrl?: string) {
  const markerParams = [];

  if (iconUrl) {
    markerParams.push(`icon:${iconUrl}`);
  } else {
    markerParams.push("size:small", "color:0x0046FA");
  }

  // location must come last
  markerParams.push(location);

  return ["markers", markerParams.join("|")];
}

type MapUrlParams = {
  ips: IpAddressLogs[];
  width: number;
  height: number;
  homeAddress?: HomeAddressValue;
};

function createGoogleMapUrl({ ips, width, height, homeAddress }: MapUrlParams): string {
  const mapUrl = new URL("https://maps.googleapis.com/maps/api/staticmap");
  const mapParams = [
    ["key", Env.googleMapsApiKey],
    ["size", `${width}x${height}`],
  ];

  if (homeAddress && homeAddress.line1.length > 0) {
    mapParams.push(
      createMarkerParam(
        `${homeAddress.line1},${homeAddress.city},${homeAddress.state} ${homeAddress.postal}`,
        mapMarkerHomeUrl,
      ),
    );
  }

  // Maps supports at most 15 markers, and home is the first
  ips.slice(0, 14).forEach((ip) => {
    if (ip.coordinates) {
      const markerUrl =
        ip.deviceType === DeviceType.MOBILE ? mapMarkerPhoneUrl : mapMarkerDesktopUrl;
      mapParams.push(createMarkerParam(`${ip.coordinates.lat},${ip.coordinates.lng}`, markerUrl));
    }
  });

  mapParams.forEach(([key, value]) => mapUrl.searchParams.append(key, value));
  return mapUrl.toString();
}

function countryCodeToCountryName(code: string) {
  const upperCode = code.toUpperCase();
  return COUNTRIES.find((country) => country.value === upperCode)?.label;
}

function consolidateConsecutiveIdenticalIps(ips: IpAddressLogs[]): IpAddressLogs[] {
  return ips.filter((ip, index) => {
    return index === 0 || ip.ipAddress !== ips[index - 1].ipAddress;
  });
}

export default function IpAddressSection({ ips, homeAddress }: Props) {
  const isMedium = useMatchScreenClass("xs", "sm", "md");
  const intl = useIntl();
  const mapWidth = isMedium ? 340 : 540;

  const consolidatedIps = consolidateConsecutiveIdenticalIps(ips);

  return (
    <>
      <Heading className={CommonStyles.sectionHeader} textStyle="subtitleSmall" level="h3">
        <Icon name="location" />
        <FormattedMessage id="cffc5ab3-1493-4ccb-b511-34c14417929e" defaultMessage="IP Address" />
      </Heading>
      <div className={Styles.ipAddressContent}>
        <img
          className={Styles.ipAddressMap}
          alt={intl.formatMessage(MESSAGES.mapAlt)}
          aria-hidden
          src={createGoogleMapUrl({
            ips: consolidatedIps,
            homeAddress,
            width: mapWidth,
            height: 244,
          })}
        />
        <div className={Styles.ipListWrapper}>
          <Substyle className={Styles.ipAddressSubheader} size="small" textColor="subtle">
            <FormattedMessage
              id="aae9eb10-fd4c-40f1-bbd7-e0d6755f86b9"
              defaultMessage="IP Address"
            />
            <Tooltip target={<Icon className={Styles.ipIcon} name="info" />}>
              <FormattedMessage
                id="9dbbd6d7-17c0-4443-9cef-fe4c189efd7e"
                defaultMessage="IP Address helps prevent fraud by understanding signer's location or VPN use"
              />
            </Tooltip>
          </Substyle>
          <ul className={Styles.ipList}>
            {homeAddress && (
              <li>
                <div className={Styles.ipListMainLine}>
                  <Icon className={Styles.ipIcon} name="address" />
                  <FormattedMessage
                    id="85113cf1-9f5c-4bc5-b800-94315e4bfc9a"
                    defaultMessage="Home address"
                  />
                </div>
                <SensitiveValue
                  value={
                    <>
                      <div className={Styles.ipListHomeAddressLine}>
                        {homeAddress.line1}
                        {homeAddress.line2 ? `, ${homeAddress.line2}` : ""}
                      </div>
                      <div className={Styles.ipListHomeAddressLine}>
                        {homeAddress.city}, {homeAddress.state} {homeAddress.postal}
                      </div>
                    </>
                  }
                  obfuscatedValue={"****"}
                  automationId="identity-address-hidden"
                />
              </li>
            )}
            {consolidatedIps.map((ipInfo) => (
              <li key={`${ipInfo.ipAddress} ${ipInfo.recordedAt}`}>
                <div className={Styles.ipListMainLine}>
                  <IpDeviceIcon {...ipInfo} />
                  <CountryFlag {...ipInfo} />
                  <IpCity {...ipInfo} />
                </div>
                <div className={Styles.ipListSecondaryLine}>
                  <IpAddress {...ipInfo} />
                </div>
                <div className={Styles.ipListSecondaryLine}>
                  <FormatDate {...ipInfo} />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}
