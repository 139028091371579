import { useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { parseISO } from "date-fns";

import LoadingIndicator from "common/core/loading_indicator";
import { Heading } from "common/core/typography";
import { Card } from "common/core/card";
import { ButtonStyledLink } from "common/core/button/button_styled_link";
import Link from "common/core/link";
import { format } from "common/core/format/date";
import TablePagination, { usePagination } from "common/core/table/pagination";
import { renderTransactionTypeWithVariant } from "common/details/summary";
import { useQuery } from "util/graphql";
import { maxPageNumber } from "util/pagination";
import { useFeatureFlag } from "common/feature_gating";
import { useSignTransactionsEnabled } from "util/feature_detection";
import { PS1583_ATTESTATION } from "constants/feature_gates";
import RiskLevel from "common/dashboard/risk_level";
import { TransactionStatus } from "common/dashboard/columns";
import APP from "constants/applications";
import { CURRENT_PORTAL } from "constants/app_subdomains";

import TransactionHistoryQuery, {
  type TransactionHistory_organization_Organization as Organization,
  type TransactionHistory_organization_Organization_organizationCustomer_personalDocumentBundles_edges_node as DocumentBundle,
} from "./index.query.graphql";
import Styles from "./index.module.scss";

const PAGE_SIZE = 5;

type Props = {
  organizationId: string;
  userId: string;
  proofDefend: boolean;
};

function TransactionHistoryItem({
  documentBundle: {
    createdAt,
    organizationTransaction: {
      id,
      transactionVariant,
      transactionType,
      isMortgage,
      requiresNsaMeeting,
      signerIdentities,
      status,
      detailedStatus,
    },
  },
  organizationId,
  userId,
  proofDefend,
}: {
  documentBundle: DocumentBundle;
  userId: string;
  organizationId: string;
  proofDefend: boolean;
}) {
  const intl = useIntl();
  const ps1583AttestationEnabled = useFeatureFlag(PS1583_ATTESTATION);
  const signTransactionsEnabled = useSignTransactionsEnabled();
  const isKeystone = CURRENT_PORTAL === APP.ADMIN;

  const pathname = isKeystone
    ? `/companies/${organizationId}/transactions/${id}/identity`
    : `/transaction/records/${id}/identity`;
  const signerIdentity = signerIdentities.find((si) => si.author!.id === userId);

  return (
    <Card fullWidth noMargin>
      <div className={Styles.item}>
        <Link black to={pathname}>
          <Heading textStyle="subtitle" level="h3">
            <span className={Styles.transactionId}>{id}</span> &middot;{" "}
            <span>
              {renderTransactionTypeWithVariant(
                transactionType,
                transactionVariant,
                isMortgage,
                requiresNsaMeeting,
                intl,
                ps1583AttestationEnabled,
                signTransactionsEnabled,
              )}
            </span>
          </Heading>
        </Link>
        {proofDefend && (
          <RiskLevel
            className={Styles.nowrap}
            level={signerIdentity?.aggregateRiskLevel ?? null}
            textLength="medium"
          />
        )}
        <span className={Styles.description}>
          {format({
            value: parseISO(createdAt!),
            formatStyle: "EEE MMM. dd yyyy, h:mma",
          })}
        </span>
        {!proofDefend && (
          <TransactionStatus
            status={status}
            detailedStatus={detailedStatus}
            className={Styles.nowrap}
          />
        )}
        <ButtonStyledLink
          variant="secondary"
          buttonColor="action"
          withIcon={{
            name: "new-window",
            placement: "right",
          }}
          to={pathname}
        >
          <FormattedMessage id="44424830-395f-46ef-baf3-91e1b163b3e6" defaultMessage="View" />
        </ButtonStyledLink>
      </div>
    </Card>
  );
}

export function TransactionHistory({ organizationId, userId, proofDefend }: Props) {
  const [pageIndex, setPageIndex] = useState(0);
  const handlePageChange = useCallback((newPageIndex: number) => {
    setPageIndex(newPageIndex);
  }, []);

  const { data, previousData, loading } = useQuery(TransactionHistoryQuery, {
    variables: { organizationId, userId, pageSize: PAGE_SIZE, offset: PAGE_SIZE * pageIndex },
  });

  const organization = (
    data ? data.organization! : loading && previousData ? previousData.organization! : null
  ) as Organization | null;
  const organizationCustomer = organization?.organizationCustomer;

  const items = useMemo(
    () => organizationCustomer?.personalDocumentBundles?.edges.map(({ node }) => node) ?? [],
    [organization],
  );

  const totalCount = organizationCustomer?.personalDocumentBundles?.totalCount ?? 0;
  const pageCount = maxPageNumber(totalCount, PAGE_SIZE);

  const pagination = usePagination({
    disabled: loading,
    pageIndex,
    pageCount,
    pageSize: PAGE_SIZE,
    items,
    onPageChange: handlePageChange,
  });

  if (!organizationCustomer) {
    return (
      <div className={Styles.loadingContainer}>
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <Heading textStyle="subtitle" level="h2">
          <FormattedMessage
            id="fced0193-9d44-406b-b80a-6fce47196f6e"
            defaultMessage="Transaction history"
          />
        </Heading>
        <TablePagination {...pagination} totalCount={totalCount} />
      </div>

      {items.map((documentBundle) => (
        <TransactionHistoryItem
          key={documentBundle.id}
          organizationId={organizationId}
          documentBundle={documentBundle}
          userId={userId}
          proofDefend={proofDefend}
        />
      ))}
    </div>
  );
}
