import { parsePhoneNumberWithError as parsePhoneNumber } from "libphonenumber-js";

import Icon from "common/core/icon";
import FormattedAddress from "common/core/format/address";

import type { ContactInfo as OrganizationCustomer } from "./contact_info.fragment.graphql";
import Styles from "./contact_info.module.scss";

export function ContactInfo({
  organizationCustomer,
}: {
  organizationCustomer: OrganizationCustomer;
}) {
  const { address, email, phoneNumber } = organizationCustomer;

  return (
    <div className={Styles.container}>
      {email && (
        <div>
          <Icon name="email" />
          <span>{email}</span>
        </div>
      )}
      {phoneNumber && (
        <div>
          <Icon name="phone-filled" />
          <span>{parsePhoneNumber(phoneNumber).formatInternational()}</span>
        </div>
      )}
      {address && (
        <div>
          <Icon name="address" />
          <FormattedAddress address={address} inline />
        </div>
      )}
    </div>
  );
}
